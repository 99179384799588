import React from 'react'

import logo from '../../../images/logo.png'
import whatsapp from '../../../images/whatsapp.png'
import telegram from '../../../images/telegram.png'

const Footer: React.FC = () => {
    return (
        <div className="flex flxe-col w-full h-fit items-start justify-center bg-[#000000]">
            <div className="flex flex-col w-[1700px] py-[50px] h-full items-strat text-white gap-10">
                <div className="flex flex-row w-full h-fit items-strat justify-center text-white gap-10">
                    <div className="flex flex-col w-fit items-end justify-start gap-10">
                        <div className="flex flex-row w-full items-center justify-end gap-2">
                            <img
                                width={40}
                                height={40}
                                src={logo}
                                alt="Логотип"
                            />
                            <span className="w-full text-white uppercase leading-none text-left font-bold text-[20px]">
                                ТЕХНОЛОГИИ
                                <br />
                                ЗАКУПОК
                            </span>
                        </div>
                        <p className="text-white uppercase leading-none text-left font-bold text-[20px]">
                            ДЛЯ ТЕХ КТО ЦЕНИТ <br />{' '}
                            <span className="text-[#1051F5]">ВРЕМЯ</span> И
                            ХОЧЕТ <br />
                            <span className="text-[#1051F5]">РАБОТАТЬ</span>
                            <br />
                            <span className="text-[#1051F5]">ЭФФЕКТИВНО</span>
                        </p>
                    </div>
                    <div className="flex flex-col w-[284px] items-start justify-start gap-5">
                        <div className="text-[20px] text-white font-bold text-left">
                            О нас
                        </div>
                        <div className="text-[16px] text-left">
                            <p className="text-[#8A8A8A]">Компания:</p>
                            <p className="text-white">
                                ООО «Технологии Закупок»
                            </p>
                        </div>
                        <div className="text-[16px] text-left">
                            <p className="text-[#8A8A8A]">Адрес:</p>
                            <p className="text-white">
                                677000, Республика Саха (Якутия), г. Якутск,
                                пр-т Ленина, 1, оф. 511
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-[284px] items-start justify-start gap-5">
                        <div className="text-[20px] text-white font-bold text-left">
                            Свяжитесь с нами
                        </div>
                        <div className="text-[16px] text-left">
                            <p className="text-[#8A8A8A]">E-mail:</p>
                            <p className="text-white">info@plan223.ru</p>
                        </div>
                        <div className="text-[16px] text-left">
                            <p className="text-[#8A8A8A]">Телефон:</p>
                            <p className="text-white">8 (914) 223-44-01</p>
                        </div>
                        <div className="text-[16px] text-left">
                            <p className="text-[#8A8A8A]">
                                По всем вопросам напишите нам
                            </p>
                        </div>
                        <div className="text-[16px] text-left space-y-3">
                            <p className="text-[#8A8A8A]">WhatsApp:</p>
                            <div className="flex flex-row gap-5">
                                <p>
                                    <img
                                        width={40}
                                        height={40}
                                        src={whatsapp}
                                        alt="Логотип"
                                    />
                                </p>
                                <p>QR-code</p>
                            </div>
                            <p className="text-[#8A8A8A]">Telegram:</p>
                            <div className="flex flex-row gap-5">
                                <p>
                                    <img
                                        width={40}
                                        height={40}
                                        src={telegram}
                                        alt="Логотип"
                                    />
                                </p>
                                <p>QR-code</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[284px] items-start justify-start gap-5">
                        <div className="text-[20px] text-white font-bold text-left">
                            Техническая поддержка
                        </div>
                        <div className="text-[16px] text-left">
                            <p className="text-[#8A8A8A]">E-mail:</p>
                            <p className="text-white">support@plan223.ru</p>
                        </div>
                        <div className="text-[16px] text-left">
                            <p className="text-[#8A8A8A]">Телефон:</p>
                            <p className="text-white">8 (914) 223-44-07</p>
                        </div>
                        <div className="text-[16px] text-left">
                            <p className="text-[#8A8A8A]">
                                Для оперативной технической поддержки напишите
                                нам
                            </p>
                        </div>
                        <div className="text-[16px] text-left space-y-3">
                            <p className="text-[#8A8A8A]">WhatsApp:</p>
                            <div className="flex flex-row gap-5">
                                <p>
                                    <img
                                        width={40}
                                        height={40}
                                        src={whatsapp}
                                        alt="Логотип"
                                    />
                                </p>
                                <p>QR-code</p>
                            </div>
                            <p className="text-[#8A8A8A]">Telegram:</p>
                            <div className="flex flex-row gap-5">
                                <p>
                                    <img
                                        width={40}
                                        height={40}
                                        src={telegram}
                                        alt="Логотип"
                                    />
                                </p>
                                <p>QR-code</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-[284px] items-start justify-start gap-5">
                        <div className="text-[20px] text-white font-bold text-left">
                            Присоединяйтесь к нам
                        </div>
                        <div className="text-[16px] text-left space-y-3">
                            <p className="text-[#8A8A8A]">
                                Информационный канал
                            </p>
                            <div className="flex flex-row gap-5">
                                <p>
                                    <img
                                        width={40}
                                        height={40}
                                        src={telegram}
                                        alt="Логотип"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full h-[60px] items-center justify-center text-[12.8px] text-[#8A8A8A]">
                    <p>
                        ООО «Технологии Закупок» использует файлы cookie для
                        корректной работы сайта, персонализации пользователей и
                        других целей, предусмотренных{' '}
                        <a
                            className="text-sky-600 hover:text-sky-400"
                            href="#/info/agreement"
                            target="_blank"
                        >
                            Политикой конфиденциальности
                        </a>
                    </p>
                    <p>©2021-2025 ООО «Технологии Закупок»</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
