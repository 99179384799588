import React, { useState, useEffect, FormEvent } from 'react'
import { useSearchParams, useNavigate, Link } from 'react-router-dom'

const ResetForm: React.FC = () => {
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [isValid, setValid] = useState(false)

    useEffect(() => {
        setValid((password.length && confirmPassword.length) > 3)
    }, [password, confirmPassword])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!token) {
            setError('Ссылка недействительна.')
            return
        }
        if (password !== confirmPassword) {
            setError('Пароли не совпадают.')
            return
        }

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/user/reset_password?token=${token}`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        new_password: password,
                        new_password_two: confirmPassword,
                    }),
                }
            )

            if (response.ok) {
                setSuccess(true)
            } else {
                const data = await response.json()
                setError(
                    data.detail + '. Ошибка сброса пароля.' ||
                        'Ошибка сброса пароля. '
                )
            }
        } catch (err) {
            setError('Произошла ошибка. Попробуйте снова.')
        }
    }

    if (success) {
        return (
            <>
                <h5 className="flex flex-row mb-4 text-[18px] text-center text-white items-center justify-start gap-3">
                    <svg
                        className="w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="times-circle"
                    >
                        <path
                            fill="#187A33"
                            d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                        ></path>
                    </svg>
                    Пароль успешно изменен
                </h5>
                <form method="post">
                    <div className="flex flex-col w-full gap-3 items-center justify-end">
                        <div className="flex flex-row w-full gap-2">
                            <label className="text-[14px] text-[#ffffff]">
                                Ваш пароль был успешно изменен. Пожалуйста,
                                используйте ваш новый пароль для входа в
                                систему.
                            </label>
                        </div>
                        <div className="flex flex-row w-full items-center justify-end gap-2">
                            <button className="w-full h-7 min-w-[120px] rounded-[10px] shadow-md text-[12.8px] text-white disabled:text-[#8A8A8A] bg-[#187A33] disabled:bg-[#494849] hover:bg-[#1D9A40] items-center justify-start">
                                <Link to={`${process.env.REACT_APP_API_URL}`}>
                                    Закрыть
                                </Link>
                            </button>
                        </div>
                    </div>
                </form>
            </>
        )
    }

    return (
        <>
            <h5 className="mb-4 text-[18px] text-center text-white">
                Восстановление пароля
            </h5>
            <form
                method="post"
                onSubmit={handleSubmit}
                className="flex flex-col gap-3 items-center justify-center"
            >
                <div className="flex flex-col w-full">
                    <label className="mb-1 text-[12.8px] text-[#8A8A8A]">
                        Новый пароль
                    </label>
                    <input
                        className="w-full h-7 px-2 rounded-md border-0 text-[14px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff]"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="flex flex-col w-full mb-3">
                    <label className="mb-1 text-[12.8px] text-[#8A8A8A]">
                        Подтвердите пароль
                    </label>
                    <input
                        className="w-full h-7 px-2 rounded-md border-0 text-[14px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff]"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="flex flex-row w-full items-center justify-end gap-2">
                    <button
                        className="w-full h-7 min-w-[120px] rounded-[10px] shadow-md text-[12.8px] text-white disabled:text-[#8A8A8A] bg-[#187A33] disabled:bg-[#494849] hover:bg-[#1D9A40] items-center justify-start"
                        disabled={!isValid}
                    >
                        Восстановить пароль
                    </button>
                </div>
            </form>
            {error && (
                <p className="mb-0 mt-2 text-sm text-red-600" role="alert">
                    {error}
                </p>
            )}
        </>
    )
}

export default ResetForm
