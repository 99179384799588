import React, { useState, useEffect } from 'react'
import './index.css'
import { PlanPosition, PlanPositionStage } from '../../types'
import { Structure } from '../../../../Org/Structure/types'

type Props = {
    keyindex: number
    stage: string
    planPosition: PlanPosition
    structData: Structure[]
    selected: boolean
    onClick: (pos_guid: string) => void
    onDoubleClick: (pos_guid: string) => void
    onRightClick: (guid: string) => void
}

const STAGE_COLOR = new Map([
    ['Редактирование', '#3C69FE'],
    ['Подготовка к размещению', '#563AF5'],
    ['Отправка в ЕИС', '#3C18BC'],
    ['План закупки в ЕИС', '#272593'],
])

const STATUS_COLOR = new Map([
    ['Черновик', '#4E9AFF'],
    ['Готово', '#049664'],
    ['Подготовка', '#3C69FE'],
    ['Готово к отправке', '#563AF5'],
    ['Отправлено в ЕИС', '#049664'],
    ['Размещена', '#3C18BC'],
    ['Аннулирована', '#272593'],
])

const POSITION_STATUS = new Map([
    ['P', 'Размещена'],
    ['A', 'Аннулирована'],
    ['N', 'Новая'],
    ['C', 'Изменение'],
])

const POSITION_STATUS_COLOR = new Map([
    ['N', '#3C69FE'],
    ['C', '#563AF5'],
    ['A', 'transparent'],
    ['P', '#3C18BC'],
])

const POSITION_DRAFT_STATUS = new Map([
    ['P', 'Размещение'],
    ['A', 'Аннулирование позиции'],
    ['N', 'Новая позиция'],
    ['C', 'Изменение позиции'],
])

const POSITION_DRAFT_STATUS_COLOR = new Map([
    ['N', '#8F8E94'],
    ['C', '#737279'],
    ['A', '#515055'],
    ['P', '#2A282B'],
])

const MONTHS = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
]

const CURRENCY = new Map([
    ['643', '₽'],
    ['156', '¥'],
    ['978', '€'],
    ['840', '$'],
])

const GWS = new Map([
    ['G', 'Товар'],
    ['W', 'Работа'],
    ['S', 'Услуга'],
])

const PlanPositionsTableRow: React.FC<Props> = ({
    keyindex,
    stage,
    planPosition,
    structData,
    selected,
    onClick,
    onDoubleClick,
    onRightClick,
}) => {
    const getNameStruct = (struct_guid: string) => {
        const nameSctruct = structData.find((s) => s.guid === struct_guid)
        return nameSctruct?.struct_short_name || '-'
    }

    return (
        <>
            {stage != 'План закупки в ЕИС' ? (
                <div
                    className={
                        selected
                            ? 'plan-position-table-row-selected'
                            : 'plan-position-table-row'
                    }
                    onDoubleClick={() => onDoubleClick(planPosition.guid)}
                    onClick={() => onClick(planPosition.guid)}
                    onContextMenu={(e) => {
                        e.preventDefault() // Отключает стандартное контекстное меню
                        onRightClick(planPosition.guid)
                    }}
                >
                    <div className="flex h-[70px] w-8 items-center justify-center text-center">
                        <input
                            type="checkbox"
                            checked={selected}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => {
                                onClick(planPosition.guid)
                            }}
                        />
                    </div>
                    <div className="flex h-[70px] w-12 items-center justify-center text-center text-[#8A8A8A]">
                        {keyindex}
                    </div>
                    <div className="flex h-[70px] w-12 items-center justify-center text-center text-[#8A8A8A]">
                        {planPosition.ordinal_number}
                    </div>
                    <div
                        className={`flex h-[70px] w-24 p-1 items-center justify-center text-center rounded`}
                        style={{
                            backgroundColor: planPosition.position_stage
                                ? STAGE_COLOR.get(planPosition.position_stage)
                                : 'transparent',
                        }}
                    >
                        {planPosition.position_stage}
                    </div>
                    <div
                        className="flex h-[70px] w-24 p-1 items-center justify-center text-center rounded"
                        style={{
                            backgroundColor: planPosition.position_status
                                ? STATUS_COLOR.get(planPosition.position_status)
                                : 'transparent',
                        }}
                    >
                        {planPosition.position_status}
                    </div>
                    <div
                        className="flex h-[70px] flex-1 items-center justify-start text-left min-w-44 px-2 "
                        title={planPosition.contract_subject}
                    >
                        <p className="fourrow">
                            {planPosition.contract_subject
                                ? planPosition.contract_subject
                                : '-'}
                        </p>
                    </div>
                    <div className="flex h-[70px] w-28 items-center justify-end text-right px-2 ">
                        {Number(
                            planPosition.maximum_contract_price
                        ).toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                        })}{' '}
                        {planPosition.currency_digital_code
                            ? CURRENCY.get(
                                  String(planPosition.currency_digital_code)
                              )
                            : ''}
                    </div>
                    <div className="flex h-[70px] w-24 items-center justify-end text-right px-2 ">
                        {planPosition.purchase_planned_date === null ? (
                            planPosition.purchase_period_month ? (
                                <>
                                    {MONTHS[
                                        Number(
                                            planPosition.purchase_period_month
                                        ) - 1
                                    ] + ' '}
                                    {planPosition.purchase_period_year}
                                </>
                            ) : (
                                '-'
                            )
                        ) : (
                            planPosition.purchase_planned_date
                                ?.split('-')
                                .reverse()
                                .join('-')
                                .replace(/[\-]/g, '.')
                        )}
                    </div>
                    <div className="flex h-[70px] w-24 items-center justify-end text-right px-2 ">
                        {planPosition.contract_end_date ? (
                            <>
                                {MONTHS[
                                    Number(
                                        planPosition.contract_end_date?.slice(
                                            5,
                                            7
                                        )
                                    ) - 1
                                ] + ' '}
                                {planPosition.contract_end_date?.slice(0, 4)}
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                    <div className="flex h-[70px] w-44 items-center justify-center text-center">
                        <p className="fourrow">
                            {planPosition.purchase_method_name
                                ? planPosition.purchase_method_name
                                : '-'}
                        </p>
                    </div>
                    <div
                        className={
                            'flex h-[70px] w-24 items-center justify-center text-center rounded'
                        }
                        style={{
                            backgroundColor: planPosition.status
                                ? POSITION_DRAFT_STATUS_COLOR.get(
                                      planPosition.status
                                  )
                                : 'transparent',
                        }}
                    >
                        {POSITION_DRAFT_STATUS.get(String(planPosition.status))}
                    </div>
                    <div className="flex h-[70px] w-20 items-center justify-center text-center">
                        {planPosition.type_object_purchase_position
                            ? GWS.get(
                                  String(
                                      planPosition.type_object_purchase_position
                                  )
                              )
                            : '-'}
                    </div>
                    <div
                        className="flex h-[70px] w-20 items-center justify-center text-center"
                        title={`${planPosition.struct_name}`}
                    >
                        {planPosition.struct_guid !== undefined &&
                        planPosition.struct_guid !== null &&
                        planPosition.struct_guid !== ''
                            ? getNameStruct(planPosition.struct_guid)
                            : '-'}
                    </div>
                    <div className="flex h-[70px] w-20 items-center justify-center text-center text-[#8A8A8A]">
                        {planPosition.position_version}
                    </div>
                    <div className="flex h-[70px] w-28 items-center justify-center text-center text-[#8A8A8A]">
                        Тут будет
                        <br />
                        "Номер в системе"
                    </div>
                    <div className="flex h-[70px] w-20 items-center justify-center text-center text-[#8A8A8A]">
                        {planPosition.sys_updated_at
                            ?.slice(0, 10)
                            .split('-')
                            .reverse()
                            .join('-')
                            .replace(/[\-]/g, '.')}
                        <br />
                        {planPosition.sys_updated_at?.slice(11, 16)}
                    </div>
                </div>
            ) : (
                <div
                    className={
                        selected
                            ? 'plan-position-table-row-selected'
                            : 'plan-position-table-row'
                    }
                    onDoubleClick={() => onDoubleClick(planPosition.guid)}
                    onClick={() => onClick(planPosition.guid)}
                    onContextMenu={(e) => {
                        e.preventDefault() // Отключает стандартное контекстное меню
                        onRightClick(planPosition.guid)
                    }}
                >
                    <div className="flex h-[70px] w-8 items-center justify-center text-center">
                        <input
                            type="checkbox"
                            checked={selected}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => {
                                onClick(planPosition.guid)
                            }}
                        />
                    </div>
                    <div className="flex h-[70px] w-12 items-center justify-center text-center text-[#8A8A8A]">
                        {keyindex}
                    </div>
                    <div className="flex h-[70px] w-12 items-center justify-center text-center text-[#8A8A8A]">
                        {planPosition.ordinal_number}
                    </div>
                    <div
                        className={`flex h-[70px] w-24 items-center justify-center text-center rounded`}
                        style={{
                            backgroundColor: planPosition.position_stage
                                ? STAGE_COLOR.get(planPosition.position_stage)
                                : 'transparent',
                        }}
                    >
                        {planPosition.position_stage}
                    </div>
                    <div
                        className="flex h-[70px] w-24 items-center justify-center text-center rounded"
                        style={{
                            backgroundColor: planPosition.position_status
                                ? POSITION_STATUS_COLOR.get(planPosition.status)
                                : 'transparent',
                        }}
                    >
                        {POSITION_STATUS.get(String(planPosition.status))}
                    </div>
                    <div
                        className="flex h-[70px] flex-1 items-center justify-start text-left min-w-44 px-2 "
                        title={planPosition.contract_subject}
                    >
                        <p className="fourrow">
                            {planPosition.contract_subject
                                ? planPosition.contract_subject
                                : '-'}
                        </p>
                    </div>
                    <div className="flex h-[70px] w-28 items-center justify-end text-right px-2 ">
                        {Number(
                            planPosition.maximum_contract_price
                        ).toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                        })}{' '}
                        {planPosition.currency_digital_code
                            ? CURRENCY.get(
                                  String(planPosition.currency_digital_code)
                              )
                            : ''}
                    </div>
                    <div className="flex h-[70px] w-24 items-center justify-end text-right px-2 ">
                        {planPosition.purchase_planned_date === null ? (
                            planPosition.purchase_period_month ? (
                                <>
                                    {MONTHS[
                                        Number(
                                            planPosition.purchase_period_month
                                        ) - 1
                                    ] + ' '}
                                    {planPosition.purchase_period_year}
                                </>
                            ) : (
                                '-'
                            )
                        ) : (
                            planPosition.purchase_planned_date
                                ?.split('-')
                                .reverse()
                                .join('-')
                                .replace(/[\-]/g, '.')
                        )}
                    </div>
                    <div className="flex h-[70px] w-24 items-center justify-end text-right px-2 ">
                        {planPosition.contract_end_date ? (
                            <>
                                {MONTHS[
                                    Number(
                                        planPosition.contract_end_date?.slice(
                                            5,
                                            7
                                        )
                                    ) - 1
                                ] + ' '}
                                {planPosition.contract_end_date?.slice(0, 4)}
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                    <div className="flex h-[70px] w-44 items-center justify-center text-center">
                        <p className="fourrow">
                            {planPosition.purchase_method_name
                                ? planPosition.purchase_method_name
                                : '-'}
                        </p>
                    </div>
                    <div className="flex h-[70px] w-20 items-center justify-center text-center">
                        {planPosition.type_object_purchase_position
                            ? GWS.get(
                                  String(
                                      planPosition.type_object_purchase_position
                                  )
                              )
                            : '-'}
                    </div>
                    <div
                        className="flex h-[70px] w-20 items-center justify-center text-center"
                        title={`${planPosition.struct_name}`}
                    >
                        {planPosition.struct_guid !== undefined &&
                        planPosition.struct_guid !== null &&
                        planPosition.struct_guid !== ''
                            ? getNameStruct(planPosition.struct_guid)
                            : '-'}
                    </div>
                    <div className="flex h-[70px] w-20 items-center justify-center text-center text-[#8A8A8A]">
                        {planPosition.position_version}
                    </div>
                    <div className="flex h-[70px] w-28 items-center justify-center text-center text-[#8A8A8A]">
                        Тут будет
                        <br />
                        "Номер в системе"
                    </div>
                    <div className="flex h-[70px] w-20 items-center justify-center text-center text-[#8A8A8A]">
                        {planPosition.sys_updated_at
                            ?.slice(0, 10)
                            .split('-')
                            .reverse()
                            .join('-')
                            .replace(/[\-]/g, '.')}
                        <br />
                        {planPosition.sys_updated_at?.slice(11, 16)}
                    </div>
                </div>
            )}
        </>
    )
}

export default PlanPositionsTableRow
