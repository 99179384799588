import React, { useState, useEffect } from 'react'
import './style.css'

interface PositionsStageCountProps {
    plan_guid: string
}

const PositionsStageCount: React.FC<PositionsStageCountProps> = ({
    plan_guid,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [editStageCount, setEditStageCount] = useState<number>(0)
    const [readytoeisStageCount, setReadytoeisStageCount] = useState<number>(0)
    const [toeisStageCount, setToeisStageCount] = useState<number>(0)
    const [posesCount, setPosesCount] = useState<number>(0)

    useEffect(() => {
        if (plan_guid !== 'Нет данных') {
            fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_positions_draft_count_stage/?plan_guid=${plan_guid}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`Ошибка: ${response.statusText}`)
                    }
                    return response.json()
                })
                .then((data) => {
                    setEditStageCount(data.edit_stage_count || 0)
                    setReadytoeisStageCount(data.readytoeis_stage_count || 0)
                    setToeisStageCount(data.toeis_stage_count || 0)
                    setPosesCount(data.poses_count || 0)
                })
                .catch((error) => {
                    console.error('Ошибка при выполнении запроса:', error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [plan_guid])

    return (
        <>
            <div className="flex flex-row w-[1710px] h-full z-0 relative">
                <svg className="flex absolute w-full h-full">
                    <line
                        x1="214"
                        y1="48"
                        x2="1503"
                        y2="48"
                        stroke="#464447"
                        strokeWidth="1"
                        strokeDasharray="5 5"
                    />
                </svg>
                <div className="flex w-full h-full z-20 absolute text-white text-[12.8px]">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        <div className="flex w-full h-full justify-center items-end">
                            Редактирование
                        </div>
                        <div className="flex w-full h-full justify-center items-center">
                            <svg width="24" height="24">
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="8"
                                    fill="none"
                                    stroke="#4E9AFF55"
                                    strokeWidth="5"
                                />
                                <circle cx="12" cy="12" r="6" fill="#4E9AFF" />
                            </svg>
                        </div>
                        <div className="flex w-full h-full justify-center items-start">
                            {plan_guid == 'Нет данных' ? (
                                '-'
                            ) : isLoading ? (
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                            ) : (
                                editStageCount
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-center">
                        <div className="flex w-full h-full justify-center items-end">
                            Подготовка к размещению
                        </div>
                        <div className="flex w-full h-full justify-center items-center">
                            <svg width="24" height="24">
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="8"
                                    fill="none"
                                    stroke="#563AF555"
                                    strokeWidth="5"
                                />
                                <circle cx="12" cy="12" r="6" fill="#563AF5" />
                            </svg>
                        </div>
                        <div className="flex w-full h-full justify-center items-start">
                            {plan_guid == 'Нет данных' ? (
                                '-'
                            ) : isLoading ? (
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                            ) : (
                                readytoeisStageCount
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-center">
                        <div className="flex w-full h-full justify-center items-end">
                            Отправка в ЕИС
                        </div>
                        <div className="flex w-full h-full justify-center items-center">
                            <svg width="24" height="24">
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="8"
                                    fill="none"
                                    stroke="#3C18BC55"
                                    strokeWidth="5"
                                />
                                <circle cx="12" cy="12" r="6" fill="#3C18BC" />
                            </svg>
                        </div>
                        <div className="flex w-full h-full justify-center items-start">
                            {plan_guid == 'Нет данных' ? (
                                '-'
                            ) : isLoading ? (
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                            ) : (
                                toeisStageCount
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-center">
                        <div className="flex w-full h-full justify-center items-end">
                            План закупки в ЕИС
                        </div>
                        <div className="flex w-full h-full justify-center items-center">
                            <svg width="24" height="24">
                                <circle
                                    cx="12"
                                    cy="12"
                                    r="8"
                                    fill="none"
                                    stroke="#27259355"
                                    strokeWidth="5"
                                />
                                <circle cx="12" cy="12" r="6" fill="#272593" />
                            </svg>
                        </div>
                        <div className="flex w-full h-full justify-center items-start">
                            {plan_guid == 'Нет данных' ? (
                                '-'
                            ) : isLoading ? (
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                            ) : (
                                posesCount
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PositionsStageCount
