import React, { useState, useEffect } from 'react'
import NumberFormat from './NumberFormat'
import './style.css'

type DataPoint = {
    label: string
    value: number
}

interface PlanChartProps {
    plan_guid: string
}

const ColumnChart: React.FC<PlanChartProps> = ({ plan_guid }) => {
    const [data, setData] = useState<DataPoint[]>([])
    const [hoveredPoint, setHoveredPoint] = useState<DataPoint | null>(null)
    const [tooltipPosition, setTooltipPosition] = useState<{
        x: number
        y: number
    } | null>(null)
    const [infoTooltip, setInfoTooltip] = useState<{
        x: number
        y: number
    } | null>(null)
    const [animatedIndexes, setAnimatedIndexes] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const makeNumberK = (num: number) => {
        const numDigits = Math.abs(num).toString().length
        return 1 * Math.pow(10, numDigits - 1)
    }

    const width = 800
    const height = 340
    const offsetXLeft = 80
    const offsetXRight = 10
    const barWidth = 30
    const padding = 40
    const paddingY = 40
    const paddingYname = 40

    useEffect(() => {
        if (plan_guid === 'Нет данных') {
            return
        }

        fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/plan_positions_summ_by_month/?plan_guid=${plan_guid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`)
                }
                return response.json()
            })
            .then((result) => {
                const scaledResult = result.map((d: DataPoint) => ({
                    label: d.label,
                    value: d.value / 1000, // Scale down by 1000 as per original code
                }))
                setData(scaledResult)
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [plan_guid])

    const k = makeNumberK(Math.ceil(Math.max(...data.map((d) => d.value))) - 1)

    const maxDataValue =
        Math.ceil(Math.max(...data.map((d) => d.value)) / k) * k > 0
            ? Math.ceil(Math.max(...data.map((d) => d.value)) / k) * k
            : 10

    const scaleX = (index: number) =>
        paddingY +
        offsetXLeft +
        (index * (width - 2 * paddingY - offsetXLeft - offsetXRight)) /
            (data.length - 1)
    const scaleY = (value: number) =>
        height - padding - (value * (height - 2 * padding)) / maxDataValue

    useEffect(() => {
        data.forEach((_, i) => {
            setTimeout(() => {
                setAnimatedIndexes((prev) => [...prev, i])
            }, i * 100)
        })
    }, [data])

    return (
        <>
            <div className="flex flex-col w-full h-full relative">
                {/* Title and Info Button */}
                <div className="flex flex-row text-white text-[16px]">
                    <div className="flex flex-1">
                        Сумма планируемых закупок (тыс. руб.)
                    </div>
                    <div
                        onMouseEnter={(e) =>
                            setInfoTooltip({ x: e.clientX, y: e.clientY })
                        }
                        onMouseLeave={() => setInfoTooltip(null)}
                        style={{ cursor: 'pointer' }}
                    >
                        <button className="w-5 h-5">
                            <svg
                                id="info-circle"
                                data-name="Layer 1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="#FFF"
                                    d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
                {plan_guid == 'Нет данных' ? (
                    <div className="flex text-white text-[16px] w-full h-full items-center justify-center">
                        Нет данных
                    </div>
                ) : (
                    <>
                        {isLoading ? (
                            <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                                <span>Загрузка данных</span>
                            </div>
                        ) : (
                            <>
                                <div className="flex flex-row relative">
                                    <div className="flex items-center">
                                        <svg
                                            width={width}
                                            height={height}
                                            className="flex w-full h-full"
                                            viewBox={`0 0 ${width} ${height}`}
                                            onMouseLeave={() => {
                                                setHoveredPoint(null)
                                                setTooltipPosition(null)
                                            }}
                                        >
                                            {/* Подписи и сетка для оси X */}
                                            {data.map((point, i) => (
                                                <g key={`x-axis-${i}`}>
                                                    {/* Сетка*/}
                                                    <line
                                                        x1={scaleX(i)}
                                                        y1={height - padding}
                                                        x2={scaleX(i)}
                                                        y2={padding}
                                                        stroke="gray"
                                                        strokeOpacity="0.2"
                                                    />
                                                    {/* Подписи */}
                                                    <line
                                                        x1={scaleX(i)}
                                                        y1={
                                                            height - padding - 5
                                                        }
                                                        x2={scaleX(i)}
                                                        y2={
                                                            height - padding + 5
                                                        } // длина черточки
                                                        stroke="#464447"
                                                        strokeWidth={2}
                                                    />
                                                    <text
                                                        x={scaleX(i)}
                                                        y={
                                                            height -
                                                            padding +
                                                            20
                                                        }
                                                        textAnchor="middle"
                                                        fontSize={10}
                                                        fill="#FFF"
                                                    >
                                                        {point.label}
                                                    </text>
                                                </g>
                                            ))}

                                            {/* Подписи с сетка для оси Y */}
                                            {[
                                                ...Array(
                                                    Math.ceil(
                                                        maxDataValue /
                                                            (maxDataValue / 5)
                                                    ) + 1
                                                ),
                                            ].map((_, i) => (
                                                <g key={`y-axis-${i}`}>
                                                    {/* Сетка */}
                                                    <line
                                                        x1={
                                                            padding +
                                                            paddingYname
                                                        }
                                                        y1={scaleY(
                                                            (maxDataValue / 5) *
                                                                i
                                                        )}
                                                        x2={
                                                            width -
                                                            padding +
                                                            paddingYname
                                                        }
                                                        y2={scaleY(
                                                            (maxDataValue / 5) *
                                                                i
                                                        )}
                                                        stroke="gray"
                                                        strokeOpacity="0.2"
                                                    />
                                                    {/* Подписи */}
                                                    <line
                                                        x1={
                                                            padding -
                                                            5 +
                                                            paddingYname
                                                        }
                                                        y1={scaleY(
                                                            (maxDataValue / 5) *
                                                                i
                                                        )}
                                                        x2={
                                                            padding +
                                                            5 +
                                                            paddingYname
                                                        }
                                                        y2={scaleY(
                                                            (maxDataValue / 5) *
                                                                i
                                                        )}
                                                        stroke="#464447"
                                                        strokeWidth={2}
                                                    />
                                                    <text
                                                        x={
                                                            padding +
                                                            paddingYname -
                                                            10
                                                        }
                                                        y={
                                                            scaleY(
                                                                (maxDataValue /
                                                                    5) *
                                                                    i
                                                            ) + 4
                                                        }
                                                        textAnchor="end"
                                                        fontSize={10}
                                                        fill="#FFF"
                                                    >
                                                        <NumberFormat
                                                            value={
                                                                (maxDataValue /
                                                                    5) *
                                                                i
                                                            }
                                                        />{' '}
                                                        ₽
                                                    </text>
                                                </g>
                                            ))}

                                            {/* Столбцы графика */}
                                            {data.map((point, i) => (
                                                <rect
                                                    key={`bar-${i}`}
                                                    className="transform 0.2s ease"
                                                    x={
                                                        hoveredPoint?.label ===
                                                        point.label
                                                            ? scaleX(i) -
                                                              (barWidth + 5) / 2
                                                            : scaleX(i) -
                                                              barWidth / 2
                                                    }
                                                    y={
                                                        animatedIndexes.includes(
                                                            i
                                                        )
                                                            ? scaleY(
                                                                  point.value
                                                              )
                                                            : height - padding
                                                    }
                                                    width={
                                                        hoveredPoint?.label ===
                                                        point.label
                                                            ? barWidth + 5
                                                            : barWidth
                                                    }
                                                    height={
                                                        animatedIndexes.includes(
                                                            i
                                                        )
                                                            ? height -
                                                              padding -
                                                              scaleY(
                                                                  point.value
                                                              )
                                                            : 0
                                                    }
                                                    fill={
                                                        hoveredPoint?.label ===
                                                        point.label
                                                            ? '#8a6ff9'
                                                            : '#563af5'
                                                    }
                                                    onMouseEnter={() =>
                                                        setHoveredPoint(point)
                                                    }
                                                    onMouseMove={(e) =>
                                                        setTooltipPosition({
                                                            x: e.clientX,
                                                            y: e.clientY,
                                                        })
                                                    }
                                                    onMouseLeave={() =>
                                                        setHoveredPoint(null)
                                                    }
                                                    style={{
                                                        transition:
                                                            'all 0.3s ease-out',
                                                        transitionDelay:
                                                            'all 0.5s ease',
                                                    }}
                                                />
                                            ))}

                                            {/* Оси и подписи */}
                                            {/* Ось Y */}
                                            <line
                                                x1={padding + paddingYname}
                                                y1={height - padding}
                                                x2={padding + paddingYname}
                                                y2={padding}
                                                stroke="#464447"
                                                strokeWidth={2}
                                            />
                                            {/* Ось X */}
                                            <line
                                                x1={padding + paddingYname}
                                                y1={height - padding}
                                                x2={width - padding}
                                                y2={height - padding}
                                                stroke="#464447"
                                                strokeWidth={2}
                                            />
                                        </svg>
                                        {/* Tooltip */}
                                        {hoveredPoint && tooltipPosition && (
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    left:
                                                        tooltipPosition.x - 50,
                                                    top: tooltipPosition.y - 50,
                                                    backgroundColor:
                                                        '#464447CC',
                                                    padding: '3px',
                                                    borderRadius: '5px',
                                                    pointerEvents: 'none',
                                                    whiteSpace: 'nowrap',
                                                    color: '#FFF',
                                                    fontSize: 14,
                                                }}
                                            >
                                                <NumberFormat
                                                    value={
                                                        hoveredPoint.value *
                                                        1000
                                                    }
                                                />{' '}
                                                ₽
                                            </div>
                                        )}
                                        {/* Info Tooltip */}
                                        {infoTooltip && (
                                            <div
                                                className="flex flex-col w-[500px] p-2"
                                                style={{
                                                    position: 'fixed',
                                                    left: infoTooltip.x - 520,
                                                    top: infoTooltip.y - 10,
                                                    backgroundColor: '#464447',
                                                    border: '1px solid gray',
                                                    borderRadius: '5px',
                                                    color: '#FFF',
                                                    fontSize: 12.8,
                                                }}
                                            >
                                                Это график количества закупок по
                                                месяцам. Здесь вы можете видеть
                                                график.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default ColumnChart
