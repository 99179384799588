import React, { useState, useEffect } from 'react'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { PlanPosition, PlanPositionStage } from './types'
import { Plan } from '../types'
import { Role } from '../../../Hub/Org/Roles/types'
import { useAuth } from '../../../../hooks/auth'
import { Structure } from '../../Org/Structure/types'

import './style.css'

import '../../../../components/Toolbar/Icon.css'
import PlanPositionsHeader from './components/Header'
import PlanPositionsTabs from './components/Tabs'
import PlanPositionsTableRow from './components/TableRow'
import LoadingIcon from '../../../../components/LoadingIcon'
import TableStageToolbarButtons from './components/Buttons/TableStageToolbarButtons'
import TableStageToolbarDownButtons from './components/Buttons/TableStageToolbarDownButton'

const CURRENCY = new Map([
    ['643', '₽'],
    ['156', '¥'],
    ['978', '€'],
    ['840', '$'],
])

interface ToEisSendInfo {
    modification_description: string
    additional_info: string
    approve_date: string
}

interface TakePlanFromEis {
    plan_guid: string
    registration_number: string
}

type ApiResponse = {
    message: Plan[]
}

const getCurrentDate = (): string => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // добавляем 1, так как месяцы начинаются с 0
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

const getStructureList = async (): Promise<Structure[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organization_struct',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const getRoleList = async (): Promise<Role[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organizations/roles/',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then(async (response) => {
            if (response.ok) {
                const data = await response.json()
                return data.result // Достаём массив из result
            } else {
                throw new Error('Ошибка запроса на роли')
            }
        })
        .catch((e) => {
            console.error('Ошибка:', e)
            return [] // Возвращаем пустой массив в случае ошибки
        })
}

const PlanPositionListPage: React.FC = () => {
    const navigate = useNavigate()
    const { plan_guid: routePlanGuid } = useParams()
    const [planGuidNav, setPlanGuidNav] = useState(routePlanGuid)

    const { user } = useAuth()

    const [structureList, setStructureList] = useState<Structure[]>([])

    const [isUserRoleGuid, setUserRoleGuid] = useState('')
    const [isUserRoleName, setUserRoleName] = useState('')

    const [searchParams] = useSearchParams()
    const initialStage = searchParams.get('stage') as PlanPositionStage

    const [stage, setStage] = useState<PlanPositionStage>(
        initialStage || PlanPositionStage.EDIT
    )

    const [editStage, setEditStage] = useState(null)
    const [readyStage, setReadyStage] = useState(null)
    const [sentStage, setSentStage] = useState(null)

    const [countPlan, setCountPlan] = useState('Нет данных')
    const [sumPlan, setSumPlan] = useState('Нет данных')
    const [sumMSPPlanPercent, setSumMSPPlanPercent] = useState(0)
    const [sumMSPPlan, setSumMSPPlan] = useState(0)

    const [isLoadingPlan, setLoadingPlan] = useState<boolean>(true)
    const [plan, setPlan] = useState<Plan[]>([])
    const [isSendPlan, setSendPlan] = useState<boolean>(false)

    const [isLoading, setLoading] = useState<boolean>(true)
    const [items, setItems] = useState<PlanPosition[]>([])
    const [page, setPage] = useState(1) // -1 - no more data

    const [selectedItems, setSelectedItems] = useState<string[]>([])
    const [selectAll, setSelectAll] = useState<boolean>(false)

    const [changePlanPositionPopup, setChangePlanPositionPopup] =
        useState<boolean>(false)
    const [isCreating, setCreating] = useState<boolean>(false)
    const [changeItems, setChangeItems] = useState<PlanPosition[]>([])
    const [changeStatusItems, setChangeStatusItems] = useState('')

    const [deletePlanPositionPopup, setDeletePlanPositionPopup] =
        useState<boolean>(false)
    const [isDeleting, setDeleting] = useState<boolean>(false)
    const [deleteItems, setDeleteItems] = useState<PlanPosition[]>([])

    const [copyPlanPositionPopup, setCopyPlanPositionPopup] =
        useState<boolean>(false)
    const [isCopying, setCopying] = useState<boolean>(false)
    const [copyItems, setCopyItems] = useState<PlanPosition[]>([])

    const [copyPlanPositionDraftPopup, setCopyPlanPositionDraftPopup] =
        useState<boolean>(false)

    const [annulatePlanPositionPopup, setAnnulatePlanPositionPopup] =
        useState<boolean>(false)
    const [isAnnulating, setAnnulating] = useState<boolean>(false)
    const [annulateItems, setAnnulateItems] = useState<PlanPosition[]>([])
    const [canAnnulate, setCanAnnulate] = useState('')
    const [annulateReason, setAnnulateReason] = useState('REJECT')

    const [statusChangePlanPositionPopup, setStatusChangePlanPositionPopup] =
        useState<boolean>(false)
    const [isStatusChanging, setStatusChanging] = useState<boolean>(false)
    const [statusChangeItems, setStatusChangeItems] = useState<PlanPosition[]>(
        []
    )
    const [statusChangeFlag, setStatusChangeFlag] = useState('')

    const [stageChangePlanPositionPopup, setStageChangePlanPositionPopup] =
        useState<boolean>(false)
    const [isStageChanging, setStageChanging] = useState<boolean>(false)
    const [stageChangeItems, setStageChangeItems] = useState<PlanPosition[]>([])
    const [stageChangeFlag, setStageChangeFlag] = useState('')

    const [toEisSendPopup, setToEisSendPopup] = useState<boolean>(false)
    const [isToEisSending, setToEisSending] = useState<boolean>(false)
    const [toEisSendInfo, setToEisSendInfo] = useState<ToEisSendInfo>({
        modification_description: '',
        additional_info: '',
        approve_date: getCurrentDate(), // текущая дата
    })

    const [updateFromEisPopup, setUpdateFromEisPopup] = useState<boolean>(false)
    const [isUpdatingFromEis, setUpdatingFromEis] = useState<boolean>(false)
    const [popupMessage, setPopupMessage] = useState('')
    const [forceUpdateUrl, setForceUpdateUrl] = useState<string | null>(null)

    const [takeFromEisPopup, setTakeFromEisPopup] = useState<boolean>(false)
    const [isTakingFromEis, setTakingFromEis] = useState<boolean>(false)
    const [takePlanFromEisInfo, setTakePlanFromEisInfo] =
        useState<TakePlanFromEis>({
            plan_guid: '',
            registration_number: '',
        })

    const [menuVisible, setMenuVisible] = useState(false)
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 })

    const [isPrinting, setPrinting] = useState(false) // Есть ли еще данные для загрузки

    const [importPlanPositionPopup, setImportPlanPositionPopup] =
        useState<boolean>(false)
    const [isImporting, setImporting] = useState<boolean>(false)
    const [selectedFile, setSelectedFile] = useState<File | null>(null) // Храним выбранный файл
    const [uploadMessage, setUploadMessage] = useState<string | null>(null) // Сообщение о результате загрузки

    const [isExporting, setExporting] = useState<boolean>(false)

    const loadPlan = () => {
        setLoadingPlan(true)

        fetch(process.env.REACT_APP_API_URL + '/api/backend/v1/plans', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Failed to fetch plan')
                }
            })
            .then((response: ApiResponse) => response.message)
            .catch((e) => {
                console.error(e)
                return []
            })
            .then((response) => {
                setPlan(
                    response.filter((plans: Plan) => plans.guid === planGuidNav)
                )

                const planData = response.filter(
                    (plans: Plan) => plans.guid === planGuidNav
                )
                planData.map((item) => setSendPlan(item.is_sent))
            })
            .finally(() => {
                setLoadingPlan(false)
            })
    }

    const loadPlanPositions = (page_num = 1) => {
        setLoading(true)

        const pageSize = 100
        const postfix =
            stage === PlanPositionStage.PURCHASE_PLAN_IN_EIS ? '' : '_draft'

        fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/plan_positions' +
                postfix +
                '/?plan_guid=' +
                encodeURIComponent(planGuidNav as string) +
                `&page=${page_num}&size=${pageSize}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(
                        'Failed to fetch plan positions (page: ' +
                            page_num +
                            ')'
                    )
                }
            })
            .catch((e) => {
                console.error(e)
                return []
            })
            .then((data) => {
                if (page_num == 1) {
                    setItems(
                        data.filter(
                            (item: PlanPosition) =>
                                item.position_stage === stage
                        )
                    )
                } else {
                    setItems((prev) => [
                        ...prev,
                        ...data.filter(
                            (item: PlanPosition) =>
                                item.position_stage === stage
                        ),
                    ])
                }
                setPage(data.length < pageSize ? -1 : page_num + 1)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchAllPositions = async (): Promise<PlanPosition[]> => {
        const pageSize = 100 // Размер страницы
        const postfix =
            stage === PlanPositionStage.PURCHASE_PLAN_IN_EIS ? '' : '_draft'
        let allItems: PlanPosition[] = []
        let currentPage = 1

        while (true) {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/plan_positions${postfix}/?plan_guid=${encodeURIComponent(
                            planGuidNav as string
                        )}&page=${currentPage}&size=${pageSize}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )

                if (!response.ok) {
                    throw new Error(
                        `Failed to fetch plan positions (page: ${currentPage})`
                    )
                }

                const data: PlanPosition[] = await response.json()

                // Фильтруем данные по stage
                const filteredData = data.filter(
                    (item: PlanPosition) => item.position_stage === stage
                )

                // Добавляем данные в массив
                allItems = [...allItems, ...filteredData]

                // Если получено меньше позиций, чем размер страницы — завершаем
                if (filteredData.length < pageSize) {
                    break
                }

                currentPage++
            } catch (error) {
                console.error(error)
                break
            }
        }

        return allItems
    }

    const loadPositionDraftCountStage = (plan_guid: string | '') => {
        setLoading(true)

        fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/plan_positions_draft_count_stage?plan_guid=${plan_guid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Failed to fetch count')
                }
            })
            .then((data) => {
                // Обновляем состояния
                setEditStage(data.edit_count || 0)
                setReadyStage(data.ready_count || 0)
                setSentStage(data.sent_count || 0)
            })
            .catch((e) => {
                console.error(e)
                // Устанавливаем значения по умолчанию на случай ошибки
                setEditStage(null)
                setReadyStage(null)
                setSentStage(null)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const loadCountPlan = async (guidPlan?: string) => {
        setLoading(true)

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_positions_count_without_draft/?plan_guid=${guidPlan}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                setCountPlan('Нет данных')
                setLoading(false)
                throw new Error(`Error: ${response.statusText}`)
            }

            const poses_count = await response.json()

            setCountPlan(poses_count.poses_count)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const loadSumPlan = async (guidPlan?: string) => {
        setLoading(true)

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_positions_summ_piechartplansumm/?plan_guid=${guidPlan}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                setSumPlan('Нет данных')
                setLoading(false)
                throw new Error(`Error: ${response.statusText}`)
            }

            const poses_summ = await response.json()

            setSumPlan(poses_summ.poses_summ)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const loadSumMSPPlan = async (guidPlan?: string) => {
        setLoading(true)

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_positions_count_piechartplansummsmb/?plan_guid=${guidPlan}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                setSumMSPPlan(0)
                setSumMSPPlanPercent(0)
                setLoading(false)
                throw new Error(`Error: ${response.statusText}`)
            }

            const poses_sum = await response.json()

            // Проверяем, что все необходимые поля существуют
            if (
                typeof poses_sum.poses_sum_smb === 'undefined' ||
                typeof poses_sum.poses_sum_not_smb === 'undefined'
            ) {
                setSumMSPPlan(0)
                setSumMSPPlanPercent(0)
                throw new Error('Response does not contain required fields')
            }

            // Проверяем деление на ноль
            if (poses_sum.poses_sum_smb === 0) {
                setSumMSPPlan(0)
                throw new Error('Division by zero error')
            }

            // Расчёт процента
            const calc =
                (poses_sum.poses_sum_smb * 100) /
                (poses_sum.poses_sum_smb + poses_sum.poses_sum_not_smb)

            setSumMSPPlan(poses_sum.poses_sum_smb)
            setSumMSPPlanPercent(Number(calc.toFixed(2)))
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const deletePosition = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft/?plan_guid=${planGuid}&guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to delete position. Status: ${response.status}`
                )
            }

            console.log('Position deleted successfully')
        } catch (error) {
            console.error('Error while deleting position:', error)
            setDeleting(false)
        } finally {
        }
    }

    const changePosition = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft_from/?plan_guid=${planGuid}&from_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create new position from. Status: ${response.status}`
                )
            }

            if (response.ok) {
                const responseData = await response.json()

                // Обработка успешного ответа
                const newGuid = responseData.plan_pos?.guid

                if (newGuid) {
                    navigate(
                        `/hub/plans/${planGuidNav}/positions/draft/${newGuid}`
                    )
                    setChangePlanPositionPopup(false)
                }
            }

            console.log('Position created successfully')
        } catch (error) {
            console.error('Error while creating position:', error)
            setCreating(false)
        } finally {
        }
    }

    const copyPosition = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft_new_from/?plan_guid=${planGuid}&from_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create new position from. Status: ${response.status}`
                )
            }

            console.log('Position created successfully')
        } catch (error) {
            console.error('Error while creating position:', error)
            setCopying(false)
        } finally {
        }
    }

    const copyPositionDraft = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft_from_draft/?plan_guid=${planGuid}&from_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create new position from. Status: ${response.status}`
                )
            }

            console.log('Position created successfully')
        } catch (error) {
            console.error('Error while creating position:', error)
            setCopying(false)
        } finally {
        }
    }

    const annulatePosition = async (
        planGuid: string,
        guid: string,
        cancellationReason: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft_annulate/?plan_guid=${planGuid}&guid=${guid}&cancellation_reason=${cancellationReason}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to annulate position from. Status: ${response.status}`
                )
            }

            console.log('Position annulate successfully')
        } catch (error) {
            console.error('Error while annulating position:', error)
            setAnnulating(false)
        } finally {
        }
    }

    const statusChangePosition = async (
        planGuid: string,
        guid: string,
        statusPos: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_positions_draft_change_status/?plan_guid=${planGuid}&pos_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ position_status: statusPos }),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to change status position from. Status: ${response.status}`
                )
            }

            console.log('Position status change successfully')
        } catch (error) {
            console.error('Error while changing status position:', error)
            setStatusChanging(false)
        } finally {
        }
    }

    const stageChangePosition = async (
        planGuid: string,
        guid: string,
        stagePos: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_positions_draft_change_stage/?plan_guid=${planGuid}&pos_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ position_stage: stagePos }),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to change stage position from. Status: ${response.status}`
                )
            }

            console.log('Position stage change successfully')
        } catch (error) {
            console.error('Error while changing stage position:', error)
            setStageChanging(false)
        } finally {
        }
    }

    const toEisSendPlan = async (
        planGuid: string,
        send_info: ToEisSendInfo
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_to_eis?plan_guid=${planGuid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(send_info),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to send plan to EIS from. Status: ${response.status}`
                )
            }

            console.log('Position send plan to EIS successfully')
        } catch (error) {
            console.error('Error while send plan to EIS:', error)
            setCopying(false)
        } finally {
        }
    }

    const updatePlanFromEis = async (reg_number: number): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL + `/api/backend/v1/plan_from_eis`
        setUpdatingFromEis(true)

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ reg_number: reg_number }),
            })

            if (response.ok) {
                const responseData = await response.json()

                // Обработка ошибки одинаковых весрий
                if (
                    responseData.details &&
                    responseData.details.includes('version')
                ) {
                    setPopupMessage(
                        'Версия плана в базе и версия плана в ЕИС одинакова. Хотите продолжить обновление?'
                    )
                    setForceUpdateUrl(
                        process.env.REACT_APP_API_URL +
                            `/api/backend/v1/plan_from_eis?force_new_plan=true&force_update=true`
                    )
                    setUpdateFromEisPopup(true)
                    return
                }

                // Обработка ошибки несовпадения данных
                if (
                    responseData.details &&
                    responseData.details.includes("'is_success': False")
                ) {
                    setPopupMessage(
                        'Данные из ЕИС отличаются от данных в базе. Хотите продолжить обновление?'
                    )
                    setForceUpdateUrl(
                        process.env.REACT_APP_API_URL +
                            `/api/backend/v1/plan_from_eis?force_update=true`
                    )
                    setUpdateFromEisPopup(true)
                    return
                }

                // Обработка c отличием ИНН
                if (
                    responseData.details &&
                    responseData.details.includes('ИНН')
                ) {
                    setPopupMessage(
                        'ИНН плана закупки не соответствует ИНН вашей организации'
                    )
                    return
                }

                // Обработка c отличием ИНН
                if (
                    responseData.details &&
                    responseData.details.includes('multiple plan')
                ) {
                    setPopupMessage(
                        'Невозможно добавить план, так как он уже имеется в базе'
                    )
                    return
                }

                // Обработка успешного ответа
                const newGuid = responseData.plan?.new_guid

                if (newGuid) {
                    setPlanGuidNav(newGuid) // Обновите состояние
                    navigate(`/hub/plans/${newGuid}/positions`)
                    setTakeFromEisPopup(false)
                }
            }

            if (!response.ok) {
                let errorData
                try {
                    errorData = await response.json()
                } catch (e) {
                    console.error('Failed to parse JSON from response:', e)
                    throw new Error(
                        `Unexpected error: Unable to parse JSON. Status: ${response.status}`
                    )
                }
                // Если ошибка не подходит под указанные типы
                throw new Error(
                    `Failed to update plan from EIS. Status: ${response.status}, Message: ${errorData.message}`
                )
            }
        } catch (error) {
            console.error('Error while updating plan from EIS:', error)
            setPopupMessage('Ошибка связывания плана. Проверте номер плана')
        } finally {
            setUpdatingFromEis(false) // Отключаем анимацию загрузки
            setTakingFromEis(false) // Отключаем анимацию загрузки
        }
    }

    const handleForceUpdate = async (reg_number: number) => {
        if (!forceUpdateUrl) return // Если URL отсутствует, ничего не делаем

        setUpdatingFromEis(true)
        try {
            const response = await fetch(forceUpdateUrl, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ reg_number: reg_number }), // Используйте правильное значение
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to force update plan. Status: ${response.status}`
                )
            }

            const data = await response.json()
            const newGuid = data.plan?.new_guid

            if (newGuid) {
                setPlanGuidNav(newGuid)
                navigate(`/hub/plans/${newGuid}/positions`)
            }
        } catch (error) {
            console.error('Error while forcing update plan:', error)
        } finally {
            setUpdatingFromEis(false) // Закрыть Popup
            setUpdateFromEisPopup(false)
        }
    }

    const takePlanFromEis = async (takeInfo: TakePlanFromEis) => {
        setTakingFromEis(true) // Включаем анимацию загрузки
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plans/update_registration_number`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(takeInfo), // Добавлено тело запроса
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при добавлении плана')
            }
            updatePlanFromEis(Number(takeInfo.registration_number))
        } catch (error) {
            console.error('Ошибка при добавлении плана:', error)
        } finally {
            loadPlan()
        }
    }

    useEffect(() => {
        // Синхронизируйте локальное состояние с маршрутом
        loadPlan()
        setTakePlanFromEisInfo((prev) => ({
            ...prev,
            ['plan_guid']: planGuidNav ? planGuidNav : '',
        }))

        const fetchData = async () => {
            const roles = await getRoleList()
            if (Array.isArray(roles)) {
                const matchingRole = roles.find(
                    (role) => role.guid.toLowerCase() === user?.object.role_id
                )
                setUserRoleName(matchingRole?.name || '')
                setUserRoleGuid(matchingRole?.guid || '')
            } else {
                console.error('Роли не являются массивом:', roles)
            }

            const structs = await getStructureList()
            if (structs) {
                setStructureList(structs) // Обновляем список структур
            }
        }
        fetchData()
    }, [])

    // initial load & on tab change
    useEffect(() => {
        setPage(1)
        loadPlanPositions()
        setSelectedItems([])
        navigate(`/hub/plans/${planGuidNav}/positions?stage=${stage}`)
    }, [stage])

    useEffect(() => {
        // Синхронизируйте локальное состояние с маршрутом
        setPlanGuidNav(routePlanGuid)
    }, [routePlanGuid])

    useEffect(() => {
        // Синхронизируйте локальное состояние с маршрутом
        loadPlanPositions()
        loadPositionDraftCountStage(planGuidNav ? planGuidNav : '')
        loadCountPlan(planGuidNav)
        loadSumPlan(planGuidNav)
        loadSumMSPPlan(planGuidNav)
    }, [planGuidNav])

    // Функция для обработки изменения состояния отдельного checkbox
    const handleCheckboxChange = (pos_guid: string) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(pos_guid)) {
                return prevSelectedItems.filter((itemId) => itemId !== pos_guid)
            } else {
                return [...prevSelectedItems, pos_guid]
            }
        })
    }

    // Функция для обработки изменения состояния отдельного checkbox при нажатии правой кнопкой
    const handleRightClickCheckboxChange = (pos_guid: string) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(pos_guid)) {
                return prevSelectedItems // Если уже выбран, ничего не меняем
            }
            return [...prevSelectedItems, pos_guid] // Добавляем в выбранные
        })
    }

    // useEffect для обновления changeItems и changeStatusItems
    useEffect(() => {
        if (selectedItems.length === 1) {
            const singleSelectedItem = items.find(
                (item) => item.guid === selectedItems[0]
            )
            if (singleSelectedItem) {
                setChangeItems([singleSelectedItem]) // Устанавливаем единственный элемент
                setChangeStatusItems(singleSelectedItem.status) // Устанавливаем статус
            }
        } else {
            setChangeItems([]) // Сбрасываем changeItems при выборе нескольких позиций
            setChangeStatusItems('') // Сбрасываем статус
        }

        // Проверяем наличие хотя бы одного элемента со статусом "A"
        const hasStatusA = items.some(
            (item) => selectedItems.includes(item.guid) && item.status === 'A'
        )
        setCanAnnulate(hasStatusA ? 'n' : 'y')
    }, [selectedItems, items]) // Следим за изменениями selectedItems и items

    // Функция для обработки изменения состояния "Select All"
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll)
        if (!selectAll) {
            // Выбираем только те элементы, которые соответствуют текущей стадии
            const filteredItems = items
                .filter((item) => item.position_stage === stage)
                .map((item) => item.guid)

            setSelectedItems(filteredItems)
        } else {
            // Сбрасываем выбор всех элементов
            setSelectedItems([])
        }
    }

    const handleChangePlanPositionPopup = () => {
        if (changePlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setChangeItems(filteredData)
        }
        setChangePlanPositionPopup(!changePlanPositionPopup)
    }

    const handleChangeClick = async () => {
        setCreating(true)

        let remainingChangeItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await changePosition(String(planGuidNav), item) // Отправляем запрос на удаление.
            // Уменьшаем массив, убирая удалённый элемент.
            remainingChangeItems = remainingChangeItems.filter(
                (i) => i !== item
            )
            const filteredChangeItems = changeItems.filter((changeItem) =>
                remainingChangeItems.includes(changeItem.guid)
            )
            setChangeItems(filteredChangeItems)
        }
        setSelectedItems([])
        setSelectAll(false)
        setCreating(false)
        setChangePlanPositionPopup(false)
        loadPositionDraftCountStage(planGuidNav ? planGuidNav : '')
    }

    const handleDeletePlanPositionPopup = () => {
        if (deletePlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setDeleteItems(filteredData)
        }
        setDeletePlanPositionPopup(!deletePlanPositionPopup)
    }

    const handleDeleteClick = async () => {
        setDeleting(true)

        let remainingDeleteItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await deletePosition(String(planGuidNav), item) // Отправляем запрос на удаление.
            // Уменьшаем массив, убирая удалённый элемент.
            remainingDeleteItems = remainingDeleteItems.filter(
                (i) => i !== item
            )
            const filteredDeleteItems = deleteItems.filter((delItem) =>
                remainingDeleteItems.includes(delItem.guid)
            )
            setDeleteItems(filteredDeleteItems)
        }
        setSelectedItems([])
        loadPlanPositions()
        setSelectAll(false)
        setDeleting(false)
        setDeletePlanPositionPopup(false)
        loadPositionDraftCountStage(planGuidNav ? planGuidNav : '')
    }

    const handleCopyPlanPositionPopup = () => {
        if (copyPlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setCopyItems(filteredData)
        }
        setCopyPlanPositionPopup(!copyPlanPositionPopup)
    }

    const handleCopyClick = async () => {
        setCopying(true)

        let remainingCopyItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await copyPosition(String(planGuidNav), item) // Отправляем запрос на копирование.
            remainingCopyItems = remainingCopyItems.filter((i) => i !== item)

            const filteredCopyItems = copyItems.filter((copyItem) =>
                remainingCopyItems.includes(copyItem.guid)
            )
            setCopyItems(filteredCopyItems)
        }
        setSelectedItems([])
        setSelectAll(false)
        setCopying(false)
        setCopyPlanPositionPopup(false)
        setStage(PlanPositionStage.EDIT)
        loadPositionDraftCountStage(planGuidNav ? planGuidNav : '')
    }

    const handleAnnulatePlanPositionPopup = () => {
        if (annulatePlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setAnnulateItems(filteredData)
        }
        setAnnulatePlanPositionPopup(!annulatePlanPositionPopup)
    }

    const handleAnnulateClick = async (cancellationReason: string) => {
        setAnnulating(true)

        let remainingAnnulateItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await annulatePosition(
                String(planGuidNav),
                item,
                cancellationReason
            ) // Отправляем запрос на копирование.
            remainingAnnulateItems = remainingAnnulateItems.filter(
                (i) => i !== item
            )

            const filteredAnnulateItems = annulateItems.filter((annulateItem) =>
                remainingAnnulateItems.includes(annulateItem.guid)
            )
            setAnnulateItems(filteredAnnulateItems)
        }
        setSelectedItems([])
        setSelectAll(false)
        setAnnulating(false)
        setAnnulatePlanPositionPopup(false)
        setStage(PlanPositionStage.EDIT)
        loadPositionDraftCountStage(planGuidNav ? planGuidNav : '')
    }

    const handleAnnulateReasonChange = async (value: string) => {
        setAnnulateReason(value)
    }

    const handleCopyPlanPositionDraftPopup = () => {
        if (copyPlanPositionDraftPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setCopyItems(filteredData)
        }
        setCopyPlanPositionDraftPopup(!copyPlanPositionDraftPopup)
    }

    const handleCopyDraftClick = async () => {
        setCopying(true)

        let remainingCopyItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await copyPositionDraft(String(planGuidNav), item) // Отправляем запрос на копирование.
            remainingCopyItems = remainingCopyItems.filter((i) => i !== item)

            const filteredCopyItems = copyItems.filter((copyItem) =>
                remainingCopyItems.includes(copyItem.guid)
            )
            setCopyItems(filteredCopyItems)
        }
        setSelectedItems([])
        setSelectAll(false)
        setCopying(false)
        setCopyPlanPositionDraftPopup(false)
        if (stage == 'Редактирование') {
            loadPlanPositions()
        } else setStage(PlanPositionStage.EDIT)
        loadPositionDraftCountStage(planGuidNav ? planGuidNav : '')
    }

    const handleStatusChangePlanPositionPopup = (statusPos: string) => {
        if (statusChangePlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setStatusChangeItems(filteredData)
        }
        setStatusChangePlanPositionPopup(!statusChangePlanPositionPopup)
        setStatusChangeFlag(statusPos)
    }

    const handleStatusChangeClick = async () => {
        setStatusChanging(true)

        let remainingStatusChangeItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await statusChangePosition(
                String(planGuidNav),
                item,
                statusChangeFlag
            ) // Отправляем запрос на удаление.
            remainingStatusChangeItems = remainingStatusChangeItems.filter(
                (i) => i !== item
            )

            const filteredStatusChangeItems = statusChangeItems.filter(
                (statusChange) =>
                    remainingStatusChangeItems.includes(statusChange.guid)
            )
            setStatusChangeItems(filteredStatusChangeItems)
        }
        setSelectedItems([])
        loadPlanPositions()
        setSelectAll(false)
        setStatusChanging(false)
        setStatusChangeFlag('')
        setStatusChangePlanPositionPopup(false)
        loadPositionDraftCountStage(planGuidNav ? planGuidNav : '')
    }

    const handleStageChangePlanPositionPopup = (stagePos: string) => {
        if (stageChangePlanPositionPopup == false) {
            const filteredData = items.filter((item) =>
                selectedItems.includes(item.guid)
            )
            setStageChangeItems(filteredData)
        }
        setStageChangePlanPositionPopup(!stageChangePlanPositionPopup)
        setStageChangeFlag(stagePos)
        if (stage == 'Редактирование') {
            if (stagePos == 'Подготовка к размещению') {
                setStatusChangeFlag('Подготовка')
            }
        } else if (stage == 'Подготовка к размещению') {
            if (stagePos == 'Отправка в ЕИС') {
                setStatusChangeFlag('Готово к отправке')
            } else if (stagePos == 'Редактирование') {
                setStatusChangeFlag('Черновик')
            }
        } else if (stage == 'Отправка в ЕИС') {
            if (stagePos == 'Подготовка к размещению') {
                setStatusChangeFlag('Подготовка')
            }
        }
    }

    const handleStageChangeClick = async () => {
        setStageChanging(true)

        let remainingStageChangeItems = [...selectedItems] // Копируем массив, чтобы избежать изменения оригинала.

        for (const item of selectedItems) {
            await statusChangePosition(
                String(planGuidNav),
                item,
                statusChangeFlag
            ) // Отправляем запрос на удаление.
            await stageChangePosition(
                String(planGuidNav),
                item,
                stageChangeFlag
            ) // Отправляем запрос на удаление.
            remainingStageChangeItems = remainingStageChangeItems.filter(
                (i) => i !== item
            )

            const filteredStageChangeItems = stageChangeItems.filter(
                (stageChange) =>
                    remainingStageChangeItems.includes(stageChange.guid)
            )
            setStageChangeItems(filteredStageChangeItems)
        }
        setSelectedItems([])
        loadPlanPositions()
        setSelectAll(false)
        setStageChanging(false)
        setStageChangeFlag('')
        setStatusChangeFlag('')
        setStageChangePlanPositionPopup(false)
        loadPositionDraftCountStage(planGuidNav ? planGuidNav : '')
    }

    const handleToEisSendPopup = () => {
        setToEisSendPopup(!toEisSendPopup)
    }

    const handleToEisSendClick = async () => {
        setToEisSending(true)
        await toEisSendPlan(String(planGuidNav), toEisSendInfo)
        setToEisSending(false)
        loadPlanPositions()
        setToEisSendPopup(false)
        loadPositionDraftCountStage(planGuidNav ? planGuidNav : '')
    }

    const handleInputChange = (
        fieldName: keyof ToEisSendInfo,
        value: string
    ) => {
        setToEisSendInfo((prev) => ({
            ...prev,
            [fieldName]: value,
        }))
    }

    const handleUpdateFromEisPopup = () => {
        setUpdateFromEisPopup(!updateFromEisPopup)
        setPopupMessage('')
    }

    const handleTakeFromEisPopup = () => {
        setTakeFromEisPopup(!takeFromEisPopup)
        setPopupMessage('')
    }

    const handleTakeFromEisClick = async () => {
        await takePlanFromEis(takePlanFromEisInfo)
    }

    const handleInputPlanNumberChange = (
        fieldName: keyof TakePlanFromEis,
        value: string
    ) => {
        setTakePlanFromEisInfo((prev) => ({
            ...prev,
            [fieldName]: value,
        }))
    }

    const initialValue = 0
    const sum = items.reduce(
        (accumulator, currentValue) =>
            accumulator + Number(currentValue.maximum_contract_price),
        initialValue
    )

    // Закрыть меню при клике вне его
    useEffect(() => {
        const handleOutsideClick = () => setMenuVisible(false)
        window.addEventListener('click', handleOutsideClick)

        return () => window.removeEventListener('click', handleOutsideClick)
    }, [])

    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault() // Отключаем стандартное контекстное меню
        setMenuPosition({ x: e.pageX, y: e.pageY }) // Устанавливаем позицию меню
        setMenuVisible(true) // Показываем меню
    }

    const handlePrint = async () => {
        setPrinting(true)

        // Загружаем все позиции
        const allPositions = await fetchAllPositions()

        // Устанавливаем загруженные позиции в состояние
        setItems(allPositions)

        setPrinting(false)

        // Открываем окно печати
        window.print()
    }

    const handleImportPlanPositionPopup = () => {
        setImportPlanPositionPopup(!importPlanPositionPopup)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null
        setSelectedFile(file)
    }

    const handleFileUpload = async () => {
        if (!selectedFile) {
            setUploadMessage('Пожалуйста, выберите файл.')
            return
        }

        const formData = new FormData()
        formData.append('file', selectedFile) // Имя должно совпадать с ожидаемым на сервере

        setUploadMessage(null) // Очистка предыдущего сообщения
        setImporting(true)

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/import_positions_draft_from_file?plan_guid=${planGuidNav}`,
                {
                    method: 'POST',
                    credentials: 'include',
                    body: formData,
                    headers: {
                        Accept: 'application/json', // Заголовок Accept для ответа в JSON
                    },
                }
            )

            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(
                    errorData.detail?.[0]?.msg || 'Ошибка загрузки файла'
                )
            }

            const result = await response.json()
            setUploadMessage('Данные успешно импортированы.')
        } catch (error) {
            console.error(error)
            setUploadMessage((error as Error).message)
        } finally {
            setImporting(false)
            setImportPlanPositionPopup(!importPlanPositionPopup)
            loadPlanPositions()
            setSelectedFile(null)
        }
    }

    const handleExport = async () => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/export_positions_to_file?plan_guid=${planGuidNav}`

        setExporting(true) // Устанавливаем состояние загрузки
        try {
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`)
            }

            // Получение имени файла из заголовков
            const disposition = response.headers.get('Content-Disposition')
            const filename =
                disposition?.match(/filename="?(.+)"?/)?.[1] ||
                'exported_file.xlsx'

            // Чтение файла и создание ссылки для скачивания
            const blob = await response.blob()
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
            window.URL.revokeObjectURL(link.href) // Очистка URL
        } catch (error) {
            console.error('Error exporting file:', error)
            alert('Ошибка экспорта файла. Проверьте логи.')
        } finally {
            setExporting(false) // Завершаем состояние загрузки
        }
    }

    return (
        <div className="h-full flex flex-col bg-[#181818]">
            <div className="bg-[#111111]">
                <PlanPositionsHeader
                    positionCount={countPlan}
                    sum={sumPlan}
                    mspSum={sumMSPPlan}
                    percentMspSum={sumMSPPlanPercent}
                    plan={plan.length > 0 ? plan[0] : undefined}
                    loadPlan={!isLoadingPlan}
                />
                <PlanPositionsTabs
                    stage={stage}
                    edit={editStage}
                    ready={readyStage}
                    sent={sentStage}
                    onTabChange={(newStage) => {
                        setStage(newStage)
                    }}
                    onTabClick={(selectAllFlag) => setSelectAll(selectAllFlag)}
                />
            </div>

            {/* // body */}
            <div className="plan-positions flex p-5 h-[calc(100vh_-_96px_-_28px)]">
                {/* table */}
                <div
                    id="printable-content"
                    className="flex flex-col w-full bg-[#1D1C1E] rounded"
                >
                    {/* toolbar */}
                    <div className="flex flex-row gap-2 text-white p-2 h-10 bg-[#333033] rounded-t">
                        <div className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                            <input
                                type="checkbox"
                                className="p-2"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                            />
                        </div>

                        <TableStageToolbarButtons
                            role={isUserRoleName}
                            stage={stage}
                            isSelect={selectedItems.length > 0}
                            isSendPlan={isSendPlan}
                            isUpdatingFromEis={isUpdatingFromEis}
                            actions={{
                                add: () =>
                                    navigate(
                                        `/hub/plans/${planGuidNav}/positions/new`
                                    ),
                                ready_to_sent: () =>
                                    handleStageChangePlanPositionPopup(
                                        'Подготовка к размещению'
                                    ),
                                return_to_edit: () =>
                                    handleStageChangePlanPositionPopup(
                                        'Редактирование'
                                    ),
                                ready_to_eis: () =>
                                    handleStageChangePlanPositionPopup(
                                        'Отправка в ЕИС'
                                    ),
                                return_to_ready: () =>
                                    handleStageChangePlanPositionPopup(
                                        'Подготовка к размещению'
                                    ),
                                sent_to_eis: handleToEisSendPopup,
                                update: () =>
                                    updatePlanFromEis(
                                        plan[0]?.registration_number
                                    ),
                                export_plan: handleExport,
                                copy: handleCopyPlanPositionPopup,
                                copy_draft: handleCopyPlanPositionDraftPopup,
                                delete: handleDeletePlanPositionPopup,
                                import_from_file: handleImportPlanPositionPopup,
                                print: handlePrint,
                            }}
                            // status = {}
                            // multiSelect = {selectedItems.length > 0}
                        />
                    </div>
                    {/* name */}
                    {stage != 'План закупки в ЕИС' ? (
                        <div className="flex flex-row gap-1 h-24 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                            <div className="grid w-8 place-items-center text-center"></div>
                            <div className="grid w-12 place-items-center text-center">
                                №
                            </div>
                            <div className="grid w-12 place-items-center text-center">
                                Номер позиции в ЕИС
                            </div>
                            <div className="grid w-24 place-items-center text-center">
                                Стадия
                            </div>
                            <div className="grid w-24 place-items-center text-center">
                                Статус
                            </div>
                            <div className="flex-1 grid place-items-center text-center min-w-44">
                                Предмет договора
                            </div>
                            <div className="grid w-28 place-items-center text-center">
                                Начальная (максимальная) цена договора
                            </div>
                            <div className="grid w-24 place-items-center text-center">
                                Дата (период) размещения извещения
                            </div>
                            <div className="grid w-24 place-items-center text-center">
                                Срок исполнения договора
                            </div>
                            <div className="grid w-44 place-items-center text-center">
                                Способ закупки
                            </div>
                            <div className="grid w-24 place-items-center text-center">
                                Статус в плане закупки
                            </div>
                            <div className="grid w-20 place-items-center text-center">
                                Тип объекта закупки
                            </div>
                            <div className="grid w-20 place-items-center text-center">
                                Подразделение
                            </div>
                            <div className="grid w-20 place-items-center text-center">
                                Редакция
                            </div>
                            <div className="grid w-28 place-items-center text-center">
                                Номер в системе
                            </div>
                            <div className="grid w-20 place-items-center text-center pr-5">
                                Дата последнего изменения
                            </div>
                            {/* <div className="grid w-12 place-items-center text-center">
                            ДД
                        </div> */}
                        </div>
                    ) : (
                        <div className="flex flex-row gap-1 h-24 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                            <div className="grid w-8 place-items-center text-center"></div>
                            <div className="grid w-12 place-items-center text-center">
                                №
                            </div>
                            <div className="grid w-12 place-items-center text-center">
                                Номер позиции в ЕИС
                            </div>
                            <div className="grid w-24 place-items-center text-center">
                                Стадия
                            </div>
                            <div className="grid w-24 place-items-center text-center">
                                Статус
                            </div>
                            <div className="flex-1 grid place-items-center text-center min-w-44">
                                Предмет договора
                            </div>
                            <div className="grid w-28 place-items-center text-center">
                                Начальная (максимальная) цена договора
                            </div>
                            <div className="grid w-24 place-items-center text-center">
                                Дата (период) размещения извещения
                            </div>
                            <div className="grid w-24 place-items-center text-center">
                                Срок исполнения договора
                            </div>
                            <div className="grid w-44 place-items-center text-center">
                                Способ закупки
                            </div>
                            <div className="grid w-20 place-items-center text-center">
                                Тип объекта закупки
                            </div>
                            <div className="grid w-20 place-items-center text-center">
                                Подразделение
                            </div>
                            <div className="grid w-20 place-items-center text-center">
                                Редакция
                            </div>
                            <div className="grid w-28 place-items-center text-center">
                                Номер в системе
                            </div>
                            <div className="grid w-20 place-items-center text-center pr-5">
                                Дата последнего изменения
                            </div>
                            <div className="grid w-2 place-items-center text-center"></div>
                        </div>
                    )}

                    {/* data */}
                    <div
                        className="flex flex-col overflow-y-auto gap-1 my-1 h-dvh"
                        onScroll={(e) => {
                            const { scrollTop, scrollHeight, clientHeight } =
                                e.currentTarget

                            if (
                                page !== -1 && // no more data
                                !isLoading &&
                                scrollHeight - scrollTop - clientHeight < 100
                            ) {
                                loadPlanPositions(page)
                            }
                        }}
                        onContextMenu={handleContextMenu} // Обрабатываем правый клик
                    >
                        {/* Вывод позиций */}
                        <>
                            {isLoading && page === 1 ? (
                                <div className="flex w-full h-full items-center justify-center">
                                    <LoadingIcon />
                                </div>
                            ) : !items.length ? (
                                <div className="flex w-full h-12 items-center justify-center text-[#8A8A8A] text-[11px]">
                                    Нет данных
                                </div>
                            ) : (
                                items.map((item, index) => (
                                    <PlanPositionsTableRow
                                        key={index}
                                        stage={stage}
                                        keyindex={index + 1}
                                        planPosition={item}
                                        structData={structureList}
                                        selected={selectedItems.includes(
                                            item.guid
                                        )}
                                        onClick={(guid) => {
                                            handleCheckboxChange(guid)
                                        }}
                                        onDoubleClick={(pos_guid) => {
                                            const action =
                                                stage ===
                                                PlanPositionStage.PURCHASE_PLAN_IN_EIS
                                                    ? 'edit'
                                                    : 'draft'

                                            navigate(
                                                `/hub/plans/${planGuidNav}/positions/${action}/${pos_guid}`
                                            )
                                        }}
                                        onRightClick={(guid) => {
                                            handleRightClickCheckboxChange(guid)
                                        }}
                                    />
                                ))
                            )}
                            {isLoading && page > 1 && items.length > 0 && (
                                <div className="flex w-full items-center justify-center">
                                    <LoadingIcon />
                                </div>
                            )}
                        </>
                    </div>

                    {/* buttons */}
                    <div className="flex flex-row gap-3 text-white p-2 px-[22px] bg-[#333033] rounded-b h-10">
                        <TableStageToolbarDownButtons
                            role={isUserRoleName}
                            stage={stage}
                            isSelect={selectedItems.length > 0}
                            isSendPlan={isSendPlan}
                            isUpdatingFromEis={isUpdatingFromEis}
                            actions={{
                                add: () =>
                                    navigate(
                                        `/hub/plans/${planGuidNav}/positions/new`
                                    ),
                                ready_to_sent: () =>
                                    handleStageChangePlanPositionPopup(
                                        'Подготовка к размещению'
                                    ),
                                return_to_edit: () =>
                                    handleStageChangePlanPositionPopup(
                                        'Редактирование'
                                    ),
                                ready_to_eis: () =>
                                    handleStageChangePlanPositionPopup(
                                        'Отправка в ЕИС'
                                    ),
                                return_to_ready: () =>
                                    handleStageChangePlanPositionPopup(
                                        'Подготовка к размещению'
                                    ),
                                sent_to_eis: handleToEisSendPopup,
                                update: () =>
                                    updatePlanFromEis(
                                        plan[0]?.registration_number
                                    ),
                                copy: handleCopyPlanPositionPopup,
                                copy_draft: handleCopyPlanPositionDraftPopup,
                                delete: handleDeletePlanPositionPopup,
                                import_from_file: handleImportPlanPositionPopup,
                                print: handlePrint,
                            }}
                            // status = {}
                            // multiSelect = {selectedItems.length > 0}
                        />
                    </div>
                </div>
            </div>
            {deletePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleDeletePlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#C15C5C"
                                    d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Удаление позиции плана закупки
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[14px] text-white">
                                Вы уверены, что хотите удалить данные позиции
                                плана закупки? Это действие является
                                необратимым. Продолжить?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            №
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            НМЦД
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {deleteItems.map((item, index) => (
                                                <div
                                                    key={item.guid}
                                                    className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                >
                                                    <div className="flex flex-row w-full">
                                                        <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                            <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                {index + 1}
                                                            </div>
                                                            <div className="flex w-10 items-center justify-center">
                                                                {item.ordinal_number
                                                                    ? item.ordinal_number
                                                                    : '-'}
                                                            </div>
                                                            <div className="flex  flex-1 items-center justify-left p-1">
                                                                {item.contract_subject ? (
                                                                    <span className="fourrow">
                                                                        {
                                                                            item.contract_subject
                                                                        }
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </div>
                                                            <div className="flex w-24 items-center justify-end">
                                                                {Number(
                                                                    item.maximum_contract_price
                                                                ).toLocaleString(
                                                                    'ru-RU',
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                    }
                                                                )}{' '}
                                                                {item.currency_digital_code
                                                                    ? CURRENCY.get(
                                                                          String(
                                                                              item.currency_digital_code
                                                                          )
                                                                      )
                                                                    : ''}
                                                            </div>
                                                            <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end gap-2">
                            <button
                                className={`flex px-4 py-1 h-7 min-w-[120px] rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#701F1F]  hover:bg-[#9e2c2c]`}
                                onClick={handleDeleteClick} // Вызвать handleDelete
                                disabled={isDeleting ? true : false}
                            >
                                {isDeleting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Удалить'
                                )}
                            </button>
                            {isDeleting ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleDeletePlanPositionPopup}
                                >
                                    Отмена
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {changePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleChangePlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[500px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#FFBF00"
                                    d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Изменение позиции плана закупки
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[14px] text-white">
                                Позиция плана закупки размещена в ЕИС. Вы
                                уверены, что хотите внести изменения?
                            </label>
                            {/* {changeItems.map((item, index) => (
                                <label
                                    key={index}
                                    className="text-[12.8px] text-white"
                                >
                                    {`№ ${item.ordinal_number} - ${item.contract_subject}`}
                                </label>
                            ))} */}
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isCreating ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleChangePlanPositionPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 h-7 min-w-[120px] rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]`}
                                onClick={handleChangeClick}
                                disabled={isCopying ? true : false}
                            >
                                {isCreating ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Изменить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {importPlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleImportPlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Импорт позиций из файла</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[14px] text-white">
                                Выберите файл для импорта
                            </label>
                            {/* Форма загрузки файла */}
                            <div className="flex flex-row w-1/2 bg-[#262427] rounded-lg items-center">
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleFileChange}
                                    className="w-full text-sm text-gray-500 file:cursor-pointer file:h-7 file:mr-4 file:py-1 file:px-5 file:rounded-[10px] file:border-0 file:text-[12.8px] file:bg-[#187A33] file:text-white hover:file:bg-[#16A34A] file:font-normal"
                                />
                                {/* <button
                                    onClick={handleFileUpload}
                                    className="flex h-7 bg-[#187A33] w-[180px] rounded-lg text-[12.8px] justify-center items-center"
                                    disabled={isLoading}
                                >
                                    Импортировать
                                </button> */}
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isImporting ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleImportPlanPositionPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 h-7 min-w-[120px] rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#16A34A]`}
                                onClick={handleFileUpload}
                                disabled={isImporting ? true : false}
                            >
                                {isImporting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Импортировать'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {copyPlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleCopyPlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#FFBF00"
                                    d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Копирование позиции плана закупки
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[14px] text-white">
                                Вы уверены, что хотите копировать следующие
                                позиции плана закупки? Продолжить?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            №
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            НМЦД
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {copyItems.map((item, index) => (
                                                <div
                                                    key={item.guid}
                                                    className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                >
                                                    <div className="flex flex-row w-full">
                                                        <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                            <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                {index + 1}
                                                            </div>
                                                            <div className="flex w-10 items-center justify-center">
                                                                {item.ordinal_number
                                                                    ? item.ordinal_number
                                                                    : '-'}
                                                            </div>
                                                            <div className="flex  flex-1 items-center justify-left p-1">
                                                                {item.contract_subject ? (
                                                                    <span className="fourrow">
                                                                        {
                                                                            item.contract_subject
                                                                        }
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </div>
                                                            <div className="flex w-24 items-center justify-end">
                                                                {Number(
                                                                    item.maximum_contract_price
                                                                ).toLocaleString(
                                                                    'ru-RU',
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                    }
                                                                )}{' '}
                                                                {item.currency_digital_code
                                                                    ? CURRENCY.get(
                                                                          String(
                                                                              item.currency_digital_code
                                                                          )
                                                                      )
                                                                    : ''}
                                                            </div>
                                                            <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isCopying ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleCopyPlanPositionPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 h-7 min-w-[120px] rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]`}
                                onClick={handleCopyClick} // Вызвать handleCopy
                                disabled={isCopying ? true : false}
                            >
                                {isCopying ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Копировать'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {annulatePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleAnnulatePlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#C15C5C"
                                    d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Аннулирование позиции плана закупки
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[14px] text-white">
                                Позиции плана закупки размещены в ЕИС. Вы
                                уверены, что хотите аннулировать данные позиции
                                плана закупки? Это действие является
                                необратимым. Продолжить?
                            </label>
                            <div className="flex flex-col">
                                <label className="flex mb-1 text-sm text-[#8A8A8A]">
                                    Обоснование аннулирования позиций
                                </label>
                                <select
                                    className="flex p-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                    value={annulateReason}
                                    onChange={(e) =>
                                        handleAnnulateReasonChange(
                                            e.target.value
                                        )
                                    }
                                >
                                    <option value="REJECT">
                                        Отказ от проведения закупки
                                    </option>
                                    <option value="INVALID">
                                        Признание торгов недействительными
                                    </option>
                                    <option value="NOT_PERFORMED">
                                        Торги не состоялись
                                    </option>
                                </select>
                            </div>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            №
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            НМЦД
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {annulateItems.map(
                                                (item, index) => (
                                                    <div
                                                        key={item.guid}
                                                        className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                    >
                                                        <div className="flex flex-row w-full">
                                                            <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                                <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                    {index + 1}
                                                                </div>
                                                                <div className="flex w-10 items-center justify-center">
                                                                    {item.ordinal_number
                                                                        ? item.ordinal_number
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex  flex-1 items-center justify-left p-1">
                                                                    {item.contract_subject ? (
                                                                        <span className="fourrow">
                                                                            {
                                                                                item.contract_subject
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </div>
                                                                <div className="flex w-24 items-center justify-end">
                                                                    {Number(
                                                                        item.maximum_contract_price
                                                                    ).toLocaleString(
                                                                        'ru-RU',
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                        }
                                                                    )}{' '}
                                                                    {item.currency_digital_code
                                                                        ? CURRENCY.get(
                                                                              String(
                                                                                  item.currency_digital_code
                                                                              )
                                                                          )
                                                                        : ''}
                                                                </div>
                                                                <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end gap-2">
                            <button
                                className={`flex px-4 py-1 h-7 min-w-[120px] rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#701F1F] hover:bg-[#9e2c2c]`}
                                onClick={() =>
                                    handleAnnulateClick(annulateReason)
                                } // Вызвать handle
                                disabled={isAnnulating ? true : false}
                            >
                                {isAnnulating ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Аннулировать'
                                )}
                            </button>
                            {isAnnulating ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleAnnulatePlanPositionPopup}
                                >
                                    Отмена
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {copyPlanPositionDraftPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleCopyPlanPositionDraftPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#FFBF00"
                                    d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Копирование позиции плана закупки
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[14px] text-white">
                                Вы уверены, что хотите копировать следующие
                                позиции плана закупки? Продолжить?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            №
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            НМЦД
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {copyItems.map((item, index) => (
                                                <div
                                                    key={item.guid}
                                                    className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                >
                                                    <div className="flex flex-row w-full">
                                                        <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                            <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                {index + 1}
                                                            </div>
                                                            <div className="flex w-10 items-center justify-center">
                                                                {item.ordinal_number
                                                                    ? item.ordinal_number
                                                                    : '-'}
                                                            </div>
                                                            <div className="flex  flex-1 items-center justify-left p-1">
                                                                {item.contract_subject ? (
                                                                    <span className="fourrow">
                                                                        {
                                                                            item.contract_subject
                                                                        }
                                                                    </span>
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </div>
                                                            <div className="flex w-24 items-center justify-end">
                                                                {Number(
                                                                    item.maximum_contract_price
                                                                ).toLocaleString(
                                                                    'ru-RU',
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                    }
                                                                )}{' '}
                                                                {item.currency_digital_code
                                                                    ? CURRENCY.get(
                                                                          String(
                                                                              item.currency_digital_code
                                                                          )
                                                                      )
                                                                    : ''}
                                                            </div>
                                                            <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isCopying ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleCopyPlanPositionDraftPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 h-7 min-w-[120px] rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]`}
                                onClick={handleCopyDraftClick} // Вызвать handleCopy
                                disabled={isCopying ? true : false}
                            >
                                {isCopying ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Копировать'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {statusChangePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => handleStatusChangePlanPositionPopup('')}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#FFBF00"
                                    d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Подготовка к размещению
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[14px] text-white">
                                Вы уверены, что хотите направить следующие
                                позиции плана закупки на стадию «
                                {statusChangeFlag}»?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            №
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            НМЦД
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {statusChangeItems.map(
                                                (item, index) => (
                                                    <div
                                                        key={item.guid}
                                                        className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                    >
                                                        <div className="flex flex-row w-full">
                                                            <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                                <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                    {index + 1}
                                                                </div>
                                                                <div className="flex w-10 items-center justify-center">
                                                                    {item.ordinal_number
                                                                        ? item.ordinal_number
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex  flex-1 items-center justify-left p-1">
                                                                    {item.contract_subject ? (
                                                                        <span className="fourrow">
                                                                            {
                                                                                item.contract_subject
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </div>
                                                                <div className="flex w-24 items-center justify-end">
                                                                    {Number(
                                                                        item.maximum_contract_price
                                                                    ).toLocaleString(
                                                                        'ru-RU',
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                        }
                                                                    )}{' '}
                                                                    {item.currency_digital_code
                                                                        ? CURRENCY.get(
                                                                              String(
                                                                                  item.currency_digital_code
                                                                              )
                                                                          )
                                                                        : ''}
                                                                </div>
                                                                <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isStatusChanging ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={() =>
                                        handleStatusChangePlanPositionPopup('')
                                    }
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]`}
                                onClick={handleStatusChangeClick} // Вызвать handleStatusChange
                                disabled={isStatusChanging ? true : false}
                            >
                                {isStatusChanging ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Изменить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {stageChangePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => handleStageChangePlanPositionPopup('')}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#FFBF00"
                                    d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Изменение стадии
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[14px] text-white">
                                Вы уверены, что хотите направить следующие
                                позиции плана закупки на стадию «
                                {stageChangeFlag}»?
                            </label>
                            <div className="flex flex-row w-full space-x-5 pt-2">
                                <div className="flex flex-col w-full">
                                    {/* name */}
                                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                        <div className="flex w-10 items-center justify-center text-center">
                                            №
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            № в ЕИС
                                        </div>
                                        <div className="flex flex-1 items-center justify-center text-center">
                                            Предмет договора
                                        </div>
                                        <div className="flex w-28 items-center justify-center text-center">
                                            НМЦД
                                        </div>
                                        <div className="flex w-6 items-center justify-center"></div>
                                    </div>
                                    {/* data */}
                                    <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                        <div className="flex flex-col w-full space-y-1">
                                            {stageChangeItems.map(
                                                (item, index) => (
                                                    <div
                                                        key={item.guid}
                                                        className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                    >
                                                        <div className="flex flex-row w-full">
                                                            <div className="flex flex-row flex-1 gap-1 text-white text-[11px]">
                                                                <div className="flex w-10 items-center justify-center text-[#8A8A8A]">
                                                                    {index + 1}
                                                                </div>
                                                                <div className="flex w-10 items-center justify-center">
                                                                    {item.ordinal_number
                                                                        ? item.ordinal_number
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex  flex-1 items-center justify-left p-1">
                                                                    {item.contract_subject ? (
                                                                        <span className="fourrow">
                                                                            {
                                                                                item.contract_subject
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </div>
                                                                <div className="flex w-24 items-center justify-end">
                                                                    {Number(
                                                                        item.maximum_contract_price
                                                                    ).toLocaleString(
                                                                        'ru-RU',
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                        }
                                                                    )}{' '}
                                                                    {item.currency_digital_code
                                                                        ? CURRENCY.get(
                                                                              String(
                                                                                  item.currency_digital_code
                                                                              )
                                                                          )
                                                                        : ''}
                                                                </div>
                                                                <div className="flex w-6 items-center justify-center text-[#8A8A8A] "></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isStageChanging ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={() =>
                                        handleStageChangePlanPositionPopup('')
                                    }
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 h-7 min-w-[120px] rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]`}
                                onClick={handleStageChangeClick} // Вызвать handleStageChange
                                disabled={isStageChanging ? true : false}
                            >
                                {isStageChanging ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Изменить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {toEisSendPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleToEisSendPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#FFBF00"
                                    d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Отправка позиций в ЕИС
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Дата утверждения плана
                                    </label>
                                </div>
                                <div className="flex w-full space-x-5 ">
                                    <div className="flex flex-1 w-full ">
                                        <input
                                            type="date"
                                            className="org-input"
                                            name="approve_date"
                                            value={toEisSendInfo.approve_date}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'approve_date',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-1 w-full"></div>
                                    <div className="flex flex-1 w-full"></div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Обоснование внесения изменений
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="org-input org-input-placeholder"
                                        name="modification_description"
                                        value={
                                            toEisSendInfo.modification_description
                                        }
                                        onChange={(e) =>
                                            handleInputChange(
                                                'modification_description',
                                                e.target.value
                                            )
                                        }
                                        placeholder="Указать"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Дополнительные сведения
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="org-input org-input-placeholder"
                                        name="additional_info"
                                        value={toEisSendInfo.additional_info}
                                        onChange={(e) =>
                                            handleInputChange(
                                                'additional_info',
                                                e.target.value
                                            )
                                        }
                                        placeholder="Указать"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isToEisSending ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 min-w-[120px] rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleToEisSendPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 h-7 min-w-[120px] rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]`}
                                onClick={handleToEisSendClick} // Вызвать handleStageChange
                                disabled={isToEisSending ? true : false}
                            >
                                {isToEisSending ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Отправить в ЕИС'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {updateFromEisPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleUpdateFromEisPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[400px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row gap-2 items-center justify-left">
                            <h2 className="text-white">Обновление плана</h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <div className="flex flex-row gap-2 items-center justify-left">
                                <div className="flex flex-row w-5 h-5 items-center justify-center text-white">
                                    <svg
                                        id="info-circle"
                                        className="w-5 h-5"
                                        data-name="Layer 1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="#FFBF00"
                                            d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                        ></path>
                                    </svg>
                                </div>
                                <label className="text-[12.8px] text-white">
                                    {popupMessage}
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isUpdatingFromEis ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleUpdateFromEisPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${!isUpdatingFromEis ? 'bg-green-700 hover:bg-green-600' : 'bg-[#494849]'} `}
                                onClick={() =>
                                    handleForceUpdate(
                                        plan[0]?.registration_number
                                    )
                                } // Вызвать handleForceUpdate
                                disabled={isUpdatingFromEis ? true : false}
                            >
                                {isUpdatingFromEis ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Обновить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {takeFromEisPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleTakeFromEisPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[400px] max-h-[400px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Связывание плана</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-white">
                                Номер плана закупки в ЕИС
                            </label>
                            <input
                                type="text"
                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff8f]"
                                value={takePlanFromEisInfo.registration_number} // Привязка значения
                                onChange={(e) =>
                                    handleInputPlanNumberChange(
                                        'registration_number',
                                        e.target.value
                                    )
                                } // Обновление состояния
                                maxLength={10} // Ограничение на уровне HTML
                            />
                            {popupMessage != '' ? (
                                <span
                                    style={{
                                        color: '#C15C5C',
                                        fontSize: '12.8px',
                                    }}
                                >
                                    {popupMessage}
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            {isTakingFromEis ? (
                                ''
                            ) : (
                                <button
                                    className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-green-700 hover:bg-green-600"
                                    onClick={handleTakeFromEisPopup}
                                >
                                    Отмена
                                </button>
                            )}
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center ${isTakingFromEis ? 'bg-green-700' : 'bg-[#494849]'} bg-green-700`}
                                onClick={handleTakeFromEisClick} // Вызвать handleForceUpdate
                                disabled={isTakingFromEis ? true : false}
                            >
                                {isTakingFromEis ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Загрузить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {/* Контекстное меню */}
            {menuVisible && (
                <div
                    className="absolute flex flex-col bg-[#333033] shadow-lg rounded-lg p-1 z-50 text-[12.8px] items-center justify-start gap-1"
                    style={{
                        top: menuPosition.y,
                        left: menuPosition.x,
                    }}
                >
                    {stage == 'Редактирование' ? (
                        <>
                            <div
                                className={`flex flex-row w-full rounded px-1 py-1 items-center justify-start text-left gap-2 
                                    ${
                                        !selectedItems.length
                                            ? 'text-[#8A8A8A]'
                                            : 'text-white hover:bg-[#464447]'
                                    }`}
                            >
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                    id="copy"
                                >
                                    <path
                                        fill={
                                            !selectedItems.length
                                                ? '#FFFFFF44'
                                                : '#FFFFFF'
                                        }
                                        d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"
                                    ></path>
                                </svg>
                                <button
                                    className={`flex w-full items-center cursor-pointer`}
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    onClick={handleCopyPlanPositionDraftPopup}
                                    title="Копировать"
                                >
                                    Копировать
                                </button>
                            </div>
                            <div
                                className={`flex flex-row w-full rounded px-1 py-1 items-center justify-start text-left gap-2 
                                    ${
                                        !selectedItems.length
                                            ? 'text-[#8A8A8A]'
                                            : 'text-white hover:bg-[#464447]'
                                    }`}
                            >
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                    id="trash-alt"
                                >
                                    <path
                                        fill={
                                            !selectedItems.length
                                                ? '#FFFFFF44'
                                                : '#FFFFFF'
                                        }
                                        d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                    ></path>
                                </svg>
                                <button
                                    className={`flex w-full items-center cursor-pointer`}
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    onClick={handleDeletePlanPositionPopup}
                                    title="Удалить"
                                >
                                    Удалить
                                </button>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                    {stage == 'Подготовка к размещению' ? (
                        <>
                            <div
                                className={`flex flex-row w-full rounded px-1 py-1 items-center justify-start text-left gap-2 
                                    ${
                                        !selectedItems.length
                                            ? 'text-[#8A8A8A]'
                                            : 'text-white hover:bg-[#464447]'
                                    }`}
                            >
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                    id="copy"
                                >
                                    <path
                                        fill={
                                            !selectedItems.length
                                                ? '#FFFFFF44'
                                                : '#FFFFFF'
                                        }
                                        d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"
                                    ></path>
                                </svg>
                                <button
                                    className={`flex w-full items-center cursor-pointer`}
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    onClick={handleCopyPlanPositionDraftPopup}
                                    title="Копировать"
                                >
                                    Копировать
                                </button>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                    {stage == 'Отправка в ЕИС' ? (
                        <>
                            <div
                                className={`flex flex-row w-full rounded px-1 py-1 items-center justify-start text-left gap-2 
                                    ${
                                        !selectedItems.length
                                            ? 'text-[#8A8A8A]'
                                            : 'text-white hover:bg-[#464447]'
                                    }`}
                            >
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                    id="copy"
                                >
                                    <path
                                        fill={
                                            !selectedItems.length
                                                ? '#FFFFFF44'
                                                : '#FFFFFF'
                                        }
                                        d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"
                                    ></path>
                                </svg>
                                <button
                                    className={`flex w-full items-center cursor-pointer`}
                                    disabled={
                                        !selectedItems.length ? true : false
                                    }
                                    onClick={handleCopyPlanPositionDraftPopup}
                                    title="Копировать"
                                >
                                    Копировать
                                </button>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                    {stage == 'План закупки в ЕИС' ? (
                        isUserRoleName == 'specialist' ||
                        isUserRoleName == 'admin' ? (
                            <>
                                <div
                                    className={`flex flex-row w-full rounded px-1 py-1 items-center justify-start text-left gap-2 
                                        ${
                                            !selectedItems.length
                                                ? 'text-[#8A8A8A]'
                                                : 'text-white hover:bg-[#464447]'
                                        }`}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="copy"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"
                                        ></path>
                                    </svg>
                                    <button
                                        className={`flex w-full items-center cursor-pointer`}
                                        disabled={
                                            !selectedItems.length ? true : false
                                        }
                                        onClick={handleCopyPlanPositionPopup}
                                        title="Копировать"
                                    >
                                        Копировать
                                    </button>
                                </div>
                                <div
                                    className={`flex flex-row w-full rounded px-1 py-1 items-center justify-start text-left gap-2 
                                        ${
                                            changeStatusItems == 'A'
                                                ? 'text-[#8A8A8A]'
                                                : selectedItems.length != 1
                                                  ? 'text-[#8A8A8A]'
                                                  : 'text-white hover:bg-[#464447]'
                                        }`}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="edit"
                                    >
                                        <path
                                            fill={
                                                changeStatusItems == 'A'
                                                    ? '#FFFFFF44'
                                                    : selectedItems.length != 1
                                                      ? '#FFFFFF44'
                                                      : '#FFFFFF'
                                            }
                                            d="M21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4h6a1,1,0,0,0,0-2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM6,12.76V17a1,1,0,0,0,1,1h4.24a1,1,0,0,0,.71-.29l6.92-6.93h0L21.71,8a1,1,0,0,0,0-1.42L17.47,2.29a1,1,0,0,0-1.42,0L13.23,5.12h0L6.29,12.05A1,1,0,0,0,6,12.76ZM16.76,4.41l2.83,2.83L18.17,8.66,15.34,5.83ZM8,13.17l5.93-5.93,2.83,2.83L10.83,16H8Z"
                                        ></path>
                                    </svg>
                                    <button
                                        className={`flex w-full items-center cursor-pointer`}
                                        disabled={
                                            changeStatusItems == 'A'
                                                ? true
                                                : selectedItems.length != 1
                                                  ? true
                                                  : false
                                        }
                                        onClick={handleChangePlanPositionPopup}
                                        title="Создать изменение"
                                    >
                                        Создать изменение
                                    </button>
                                </div>
                                <div
                                    className={`flex flex-row w-full rounded px-1 py-1 items-center justify-start text-left gap-2 
                                        ${
                                            canAnnulate == 'y'
                                                ? !selectedItems.length
                                                    ? 'text-[#8A8A8A]'
                                                    : 'text-white hover:bg-[#464447]'
                                                : 'text-[#8A8A8A]'
                                        }`}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="copy"
                                    >
                                        <path
                                            fill={
                                                canAnnulate == 'y'
                                                    ? !selectedItems.length
                                                        ? '#FFFFFF44'
                                                        : '#FFFFFF'
                                                    : '#FFFFFF44'
                                            }
                                            d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29ZM19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                        ></path>
                                    </svg>
                                    <button
                                        className={`flex w-full items-center cursor-pointer`}
                                        disabled={
                                            canAnnulate == 'y'
                                                ? !selectedItems.length
                                                    ? true
                                                    : false
                                                : true
                                        }
                                        onClick={
                                            handleAnnulatePlanPositionPopup
                                        }
                                        title="Аннулировать"
                                    >
                                        Аннулировать
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className={`flex flex-row w-full rounded px-1 py-1 items-center justify-start text-left gap-2 
                                        ${
                                            !selectedItems.length
                                                ? 'text-[#8A8A8A]'
                                                : 'text-white hover:bg-[#464447]'
                                        }`}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="copy"
                                    >
                                        <path
                                            fill={
                                                !selectedItems.length
                                                    ? '#FFFFFF44'
                                                    : '#FFFFFF'
                                            }
                                            d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"
                                        ></path>
                                    </svg>
                                    <button
                                        className={`flex w-full items-center cursor-pointer`}
                                        disabled={
                                            !selectedItems.length ? true : false
                                        }
                                        onClick={handleCopyPlanPositionPopup}
                                        title="Копировать"
                                    >
                                        Копировать
                                    </button>
                                </div>
                                <div
                                    className={`flex flex-row w-full rounded px-1 py-1 items-center justify-start text-left gap-2 
                                        ${
                                            changeStatusItems == 'A'
                                                ? 'text-[#8A8A8A]'
                                                : selectedItems.length != 1
                                                  ? 'text-[#8A8A8A]'
                                                  : 'text-white hover:bg-[#464447]'
                                        }`}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="edit"
                                    >
                                        <path
                                            fill={
                                                changeStatusItems == 'A'
                                                    ? '#FFFFFF44'
                                                    : selectedItems.length != 1
                                                      ? '#FFFFFF44'
                                                      : '#FFFFFF'
                                            }
                                            d="M21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4h6a1,1,0,0,0,0-2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM6,12.76V17a1,1,0,0,0,1,1h4.24a1,1,0,0,0,.71-.29l6.92-6.93h0L21.71,8a1,1,0,0,0,0-1.42L17.47,2.29a1,1,0,0,0-1.42,0L13.23,5.12h0L6.29,12.05A1,1,0,0,0,6,12.76ZM16.76,4.41l2.83,2.83L18.17,8.66,15.34,5.83ZM8,13.17l5.93-5.93,2.83,2.83L10.83,16H8Z"
                                        ></path>
                                    </svg>
                                    <button
                                        className={`flex w-full items-center cursor-pointer`}
                                        disabled={
                                            changeStatusItems == 'A'
                                                ? true
                                                : selectedItems.length != 1
                                                  ? true
                                                  : false
                                        }
                                        onClick={handleChangePlanPositionPopup}
                                        title="Создать изменение"
                                    >
                                        Создать изменение
                                    </button>
                                </div>
                            </>
                        )
                    ) : (
                        ''
                    )}
                </div>
            )}
            {uploadMessage && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => setUploadMessage(null)}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-fit max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col text-sm text-white items-center justify-center gap-5">
                            <div className="flex flex-row text-sm text-white items-center justify-center gap-2">
                                <div
                                    className={`flex w-5 h-5 rounded-full items-center justify-center text-white ${
                                        uploadMessage ==
                                        'Данные успешно импортированы.'
                                            ? 'bg-[#187A33]'
                                            : 'bg-[#dc742f]'
                                    }`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="h-4 w-4"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d={
                                                uploadMessage ==
                                                'Данные успешно импортированы.'
                                                    ? 'M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z'
                                                    : 'M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z'
                                            }
                                        ></path>
                                    </svg>
                                </div>
                                {uploadMessage}
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-green-700 hover:bg-green-600"
                                    onClick={() => {
                                        handleImportPlanPositionPopup
                                        setUploadMessage(null)
                                    }}
                                >
                                    Ок
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlanPositionListPage
