import React, { useState, useEffect } from 'react'
import { Okato, Okpd2, Okved2 } from '../types'

type LayoutProps = {
    type: string
    posMSPStatus: boolean | null
    purchaseMethodCode: number | null
    purchaseMethodName: string | null
    isFirstSelected: boolean
    onSelected: (value: Okpd2 | Okved2 | Okato) => void
    onClose: () => void
}

const debounce = <T extends (...args: any[]) => void>(
    func: T,
    delay: number
): T => {
    let timeoutId: ReturnType<typeof setTimeout>
    return ((...args: Parameters<T>) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            func(...args)
        }, delay)
    }) as T
}

const PurchaseItemsPopup: React.FC<LayoutProps> = (props) => {
    const [query, setQuery] = useState('')
    const [items, setItems] = useState<Okpd2[] | Okved2[] | Okato[] | null>(
        null
    )

    const [purchaseSingle, setPurchaseSingle] = useState(false)
    const searchString = 'единствен'

    useEffect(() => {
        setPurchaseSingle(
            !!props.purchaseMethodName
                ?.toLowerCase()
                .includes(searchString.toLowerCase())
        )
    }, [props.purchaseMethodName])

    const performSearch = debounce(() => {
        console.log('props.type - ', props.type)
        console.log('props.purchaseMethodCode - ', props.purchaseMethodCode)
        console.log('purchaseSingle - ', purchaseSingle)
        console.log('props.isFirstSelected - ', props.isFirstSelected)
        console.log('props.posMSPStatus - ', props.posMSPStatus)

        if (query.length > 0) {
            fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/${props.type}/${
                        props.type == 'okpd2'
                            ? props.purchaseMethodCode
                                ? purchaseSingle
                                    ? props.isFirstSelected
                                        ? props.posMSPStatus
                                            ? 'fast_search_msp'
                                            : 'fast_search_not_msp'
                                        : 'fast_search'
                                    : props.posMSPStatus
                                      ? 'fast_search_msp'
                                      : 'fast_search_not_msp'
                                : props.posMSPStatus
                                  ? 'fast_search_msp'
                                  : props.isFirstSelected
                                    ? props.posMSPStatus
                                        ? 'fast_search_msp'
                                        : 'fast_search_not_msp'
                                    : 'fast_search'
                            : 'fast_search'
                    }?text_query=${encodeURIComponent(query)}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then((r) => {
                    if (r.ok) {
                        r.json().then((results) => {
                            setItems(results)
                        })
                    } else {
                        setItems(null)
                    }
                })
                .catch((e) => {
                    setItems(null)
                })
        } else {
            setItems(null)
        }
    }, 800)

    useEffect(() => {
        performSearch()
    }, [query])

    return (
        <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
            onClick={() => props.onClose()}
        >
            <div
                className="flex flex-col items-start justify-start p-5 rounded-lg bg-[#262427] w-[800px] gap-2"
                onClick={(e) => e.stopPropagation()}
            >
                <h2 className="text-white">
                    {(() => {
                        switch (props.type) {
                            case 'okpd2':
                                return 'Общероссийский классификатор продукции по видам экономической деятельности (ОКПД2)'
                            case 'okved2':
                                return 'Общероссийский классификатор видов экономической деятельности (ОКВЭД2)'
                            case 'okato':
                                return 'Общероссийский классификатор объектов административно-территориального деления (ОКАТО)'
                        }
                    })()}
                </h2>
                {props.type == 'okpd2' ? (
                    <>
                        <div className="flex flex-row w-full items-center justify-start gap-2 text-[12.8px] text-white">
                            <div className="flex flex-row w-5 h-5 items-center justify-center text-white">
                                <svg
                                    id="info-circle"
                                    className="w-5 h-5"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="#FFBF00"
                                        d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                    ></path>
                                </svg>
                            </div>
                            <div className="flex flex-row w-full">
                                {props.type == 'okpd2'
                                    ? props.purchaseMethodCode
                                        ? purchaseSingle
                                            ? props.isFirstSelected
                                                ? props.posMSPStatus
                                                    ? 'В данный момент вы можете работать только с кодами ОКПД2, входящими в перечень закупок у субъектов МСП'
                                                    : 'Вам доступны исключительно коды ОКПД2, не входящие в перечень закупок у субъектов МСП'
                                                : 'При выборе кода ОКПД2, относящегося к закупками у субъектов МСП, доступ к остальным кодам будет ограничен. В дальнейшем вы сможете выбирать только коды из перечня МСП. Учитывайте это при выборе.'
                                            : props.posMSPStatus
                                              ? 'В данный момент вы можете работать только с кодами ОКПД2, входящими в перечень закупок у субъектов МСП'
                                              : 'Вам доступны исключительно коды ОКПД2, не входящие в перечень закупок у субъектов МСП'
                                        : props.posMSPStatus
                                          ? 'В данный момент вы можете работать только с кодами ОКПД2, входящими в перечень закупок у субъектов МСП'
                                          : props.isFirstSelected
                                            ? props.posMSPStatus
                                                ? 'В данный момент вы можете работать только с кодами ОКПД2, входящими в перечень закупок у субъектов МСП'
                                                : 'Вам доступны исключительно коды ОКПД2, не входящие в перечень закупок у субъектов МСП'
                                            : 'При выборе кода ОКПД2, относящегося к закупками у субъектов МСП, доступ к остальным кодам будет ограничен. В дальнейшем вы сможете выбирать только коды из перечня МСП. Учитывайте это при выборе.'
                                    : 'При выборе кода ОКПД2, относящегося к закупками у субъектов МСП, доступ к остальным кодам будет ограничен. В дальнейшем вы сможете выбирать только коды из перечня МСП. Учитывайте это при выборе.'}
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                <div className="flex flex-col w-full mt-2 space-y-1">
                    <input
                        className="form-input flex flex-row mb-2 w-full"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Введите код или нименование"
                    />

                    <div style={{ height: '300px' }}>
                        {items !== null && items.length > 0 && (
                            <ul className="h-full overflow-y-auto">
                                {items.map((item, index) => (
                                    <li
                                        key={index}
                                        className="block p-2 hover:bg-zinc-700 cursor-pointer text-[12.8px] text-white border-b border-[#333033]"
                                        onClick={() => props.onSelected(item)}
                                    >
                                        {item.code}
                                        {' - '}
                                        {item.name}
                                        {' - '}
                                        {props.type == 'okpd2'
                                            ? item.is_msp
                                                ? 'МСП'
                                                : ''
                                            : ''}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {items !== null && items.length === 0 && (
                            <li className="flex p-1 rounded-md text-sm text-white items-center justify-center">
                                Ничего не найдено
                            </li>
                        )}
                    </div>
                </div>
                <div className="flex flex-row w-full mt-5 text-right justify-end">
                    <button
                        className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                        onClick={() => props.onClose()}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PurchaseItemsPopup
