import React from 'react'
import { NavLink } from 'react-router-dom'
import { TextLink } from '../../types'

import './SubMenuItem.css'

const SubMenuItem: React.FC<TextLink> = ({ link, text, access }) => {
    return (
        <li className="my-0.5">
            <NavLink
                to={link}
                className={({ isActive }) =>
                    'item ' + (isActive ? 'active' : '')
                }
            >
                {text}
            </NavLink>
        </li>
    )
}

export default SubMenuItem
