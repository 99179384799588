import React, { useEffect, useState } from 'react'
import Section from '../../../../../../components/Section'
import MoneyInput from '../../../../../../components/form/MoneyInput'
import { dateToString } from '../../../../../../utils/string'
import { LongTermVolumes } from '../../types'

type Props = {
    title: string
    yearlyPayments: LongTermVolumes
    onYearlyPaymentsChange: (year: number, value: number) => void
    currency: number
    currencyExchangeDate?: Date
    currencyExchangeRate?: number
}

const _HEAD_CLS_NAME =
    'py-4 bg-neutral-700/50 font-normal text-xs text-zinc-500'
const _CELL_CLS_NAME = 'p-1 bg-zinc-900/20'
const _INPUT_CLS_NAME = 'form-input w-full text-xs'

const PurchasePaymentBreakdown: React.FC<Props> = (props) => {
    const isForeignCurrency =
        props.currency !== 643 &&
        props.currencyExchangeDate &&
        props.currencyExchangeRate

    let currencyLabel = ''
    switch (props.currency) {
        case 643:
            currencyLabel = 'Российский рубль'
            break
        case 840:
            currencyLabel = 'Доллар США'
            break
        case 978:
            currencyLabel = 'Евро'
            break
        case 156:
            currencyLabel = 'Китайский юань'
            break
    }

    return (
        <Section title={props.title}>
            <div className="form-label">Планируемые платежи</div>
            {/* table */}
            <div className="flex flex-col w-full min-w-fit rounded">
                {/* name */}
                <div className="flex flex-row gap-1 h-16 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 rounded-t">
                    <div className="grid w-12 place-items-center text-center">
                        Год
                    </div>
                    <div className="grid w-24 flex-1 place-items-center text-center">
                        Сумма
                    </div>
                    {isForeignCurrency && (
                        <div className="grid w-24 flex-1 place-items-center text-center">
                            Сумма в рублевом эквиваленте
                        </div>
                    )}
                </div>

                {/* date */}
                <div className="flex flex-col overflow-y-auto dark:bg-[#242225] rounded-b">
                    {Array.from(props.yearlyPayments.details.items).map(
                        (payment, index) => {
                            const year = payment.year
                            const amount = payment.summ
                            return (
                                <div
                                    key={index}
                                    className="flex flex-row gap-1 p-1 text-white text-[11px]"
                                >
                                    <div
                                        key={year}
                                        className="grid w-12 place-items-center text-center"
                                    >
                                        {year}
                                    </div>
                                    <div className="flex-1">
                                        <MoneyInput
                                            className={
                                                _INPUT_CLS_NAME +
                                                ' bg-[#171717] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]'
                                            }
                                            value={amount}
                                            onChange={(newValue) =>
                                                props.onYearlyPaymentsChange(
                                                    year,
                                                    newValue
                                                )
                                            }
                                        />
                                    </div>

                                    {isForeignCurrency && (
                                        <div className="flex-1 ">
                                            <MoneyInput
                                                className={
                                                    _INPUT_CLS_NAME +
                                                    ' bg-[#1F1E1F] border border-[#2A282B]'
                                                }
                                                value={
                                                    amount *
                                                    (props.currencyExchangeRate as number)
                                                }
                                                disabled
                                            />
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    )}
                    <div className="flex flex-row gap-1 p-1 text-white text-[11px]">
                        <div className="grid w-12 place-items-center text-center">
                            Всего
                        </div>
                        <div className="flex-1">
                            <MoneyInput
                                className={
                                    _INPUT_CLS_NAME +
                                    ' bg-[#1F1E1F] border border-[#2A282B]'
                                }
                                value={props.yearlyPayments.volume}
                                disabled
                            />
                        </div>
                        {isForeignCurrency && (
                            <div className="flex-1">
                                <MoneyInput
                                    className={
                                        _INPUT_CLS_NAME +
                                        ' bg-[#1F1E1F] border border-[#2A282B]'
                                    }
                                    value={
                                        props.yearlyPayments.volume *
                                        (props.currencyExchangeRate as number)
                                    }
                                    disabled
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-2 form-label">Валюта договора</div>
            <input
                className="form-input"
                type="text"
                value={currencyLabel}
                disabled
            />
            {isForeignCurrency && (
                <div className="flex flex-row mt-2">
                    <div>
                        <div className="form-label">Дата курса валюты </div>
                        <input
                            className="form-input"
                            type="text"
                            value={dateToString(
                                props.currencyExchangeDate as Date
                            )}
                            disabled
                        />
                    </div>
                    <div className="ml-5">
                        <div className="form-label">Курс валюты</div>
                        <MoneyInput
                            value={props.currencyExchangeRate}
                            className=" form-input text-white bg-[#1F1E1F] border border-[#2A282B]"
                            disabled
                        />
                    </div>
                </div>
            )}
        </Section>
    )
}

export default PurchasePaymentBreakdown
