import React from 'react'
import { Navigate, Outlet, Link } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import logo from '../../images/logo.png'

const AuthLayout: React.FC = () => {
    const { user } = useAuth()
    if (user) {
        return <Navigate to="/hub" />
    }

    return (
        <div className="h-full flex flex-col">
            <header className="p-5">
                <Link
                    to="/"
                    className="flex flex-row justify-center items-center"
                    style={{ textDecoration: 'none' }}
                    title="На главную страницу"
                >
                    <img
                        width={40}
                        height={40}
                        src={logo}
                        alt="Логотип"
                        className="me-2"
                    />
                    <span className="text-white uppercase leading-none">
                        ТЕХНОЛОГИИ
                        <br />
                        ЗАКУПОК
                    </span>
                </Link>
            </header>
            <main className="flex grow justify-center items-center p-4">
                <div
                    className="w-full p-5 rounded-lg bg-neutral-800"
                    style={{ maxWidth: '500px' }}
                >
                    <Outlet />
                </div>
            </main>
        </div>
    )
}

export default AuthLayout
