import React from 'react'

const MenuIconItem: React.FC<{
    isActive: boolean
    onClick: () => void
    icon: string
}> = ({ isActive, onClick, icon }) => {
    return (
        <li>
            <span
                className={
                    'flex w-12 h-12 rounded-lg items-center p-1 cursor-pointer hover:bg-[#464447]' +
                    (isActive ? ' bg-[#464447]' : '')
                }
                onClick={onClick}
            >
                <div className="flex w-full aspect-square rounded-full bg-[#262427] items-center justify-center text-zinc-500 text-xs">
                    <svg
                        className="w-5 h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="estate"
                    >
                        <path
                            fill={`${isActive ? '#FFFFFF' : '#8A8A8A'}`}
                            d={icon}
                        ></path>
                    </svg>
                </div>
            </span>
        </li>
    )
}

export default MenuIconItem
