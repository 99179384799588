import React from 'react'
import { useAuth } from '../../hooks/auth'
import { Link } from 'react-router-dom'
import Footer from './components/Footer'

import logo from '../../images/logo.png'
import whatsapp from '../../images/whatsapp.png'
import telegram from '../../images/telegram.png'

const HomePage: React.FC = () => {
    const { user } = useAuth()

    const link = user ? (
        <Link to="hub" className="text-white">
            <div className="flex w-fit h-8 p-2 items-center justify-center rounded-full text-[12.8px] border-2 border-[#10EAB8] hover:bg-[#049664] hover:border-[#049664]">
                Вернуться в сервис
            </div>
        </Link>
    ) : (
        <Link to="auth" className="text-white">
            <div className="flex w-fit h-8 py-2 px-3 items-center justify-center rounded-full text-[12.8px] border-2 border-[#10EAB8] hover:bg-[#049664] hover:border-[#049664]">
                Вход в сервис
            </div>
        </Link>
    )

    return (
        <div className="flex flex-col grow h-full w-full pt-5 text-center gap-2 justify-center">
            <div className="flex flex-row w-full items-center justify-center">
                <img
                    width={40}
                    height={40}
                    src={logo}
                    alt="Логотип"
                    className="me-2"
                />
                <span className="text-white uppercase leading-none text-left">
                    ТЕХНОЛОГИИ
                    <br />
                    ЗАКУПОК
                </span>
            </div>

            <br />
            <div className="flex flex-col w-full items-center justify-center">
                {link}
            </div>
            <div className="flex flex-col w-full grow items-end justify-end">
                <Footer />
            </div>
        </div>
    )
}

export default HomePage
