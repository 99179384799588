import React, { useEffect, useState, useCallback } from 'react'
import PurchaseItemsTableRow from './PurchaseItemsTableRow'
import { Okato, Okpd2, Okved2, PlanPositionRow, Okei } from '../types'
import PurchaseItemsPopup from './PurchaseItemsPopup'

type LayoutProps = {
    planGuid: string
    posGuid: string
    posMSPStatus: boolean | null
    purchaseMethodCode: number | null
    purchaseMethodName: string | null
    showRegion: boolean
    isDraft: boolean
    onAreParticipantsSMEChange: (value: boolean) => void
}

const debounce = <T extends (...args: any[]) => void>(
    func: T,
    delay: number
): T => {
    let timeoutId: ReturnType<typeof setTimeout>
    return ((...args: Parameters<T>) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            func(...args)
        }, delay)
    }) as T
}

function calculateTotalCount(): number {
    const inputs = document.getElementsByName('purchase_item_count')
    let count = 0
    for (let i = 0; i < inputs.length; i++) {
        const value = (inputs[i] as HTMLInputElement).value
        if (value !== null && value !== '') {
            count += Number(value)
        }
    }
    return count
}

const getOkeiList = async (): Promise<Okei[]> => {
    return fetch(process.env.REACT_APP_API_URL + '/api/backend/v1/okei', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const PurchaseItemsTable: React.FC<LayoutProps> = ({
    planGuid,
    posGuid,
    posMSPStatus,
    isDraft,
    showRegion,
    purchaseMethodCode,
    purchaseMethodName,
    onAreParticipantsSMEChange,
}) => {
    const [isLoading, setLoading] = useState(true)
    const [rows, setRows] = useState<PlanPositionRow[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [popupType, setPopupType] = useState<[number, string] | null>(null)
    const [okeiList, setOkeiList] = useState<Okei[]>([])
    const [isFirstSelected, setFirstSelected] = useState(false)

    // load rows
    useEffect(() => {
        fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/plan_position_rows' +
                (isDraft ? '_draft' : '') +
                `/?plan_guid=${planGuid}&pos_guid=${posGuid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json() as Promise<PlanPositionRow[]>
                } else {
                    throw new Error('Failed to fetch plan position rows')
                }
            })
            .then((results) => {
                setRows(results)
                setTotalCount(
                    results.reduce((acc, row) => acc + Number(row.qty), 0)
                )
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const addNewRow = () => {
        setLoading(true)

        fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/plan_position_row_draft/?plan_guid=${planGuid}&pos_guid=${posGuid}`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json() as Promise<PlanPositionRow>
                } else {
                    throw new Error('Failed to create a new row')
                }
            })
            .then((newRow) => {
                setRows((prev) => [...prev, newRow])
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const removeRow = (guid: string) => {
        setLoading(true)

        // Получаем список строк перед удалением
        fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/plan_position_rows' +
                (isDraft ? '_draft' : '') +
                `/?plan_guid=${planGuid}&pos_guid=${posGuid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json() as Promise<PlanPositionRow[]>
                } else {
                    throw new Error('Failed to fetch plan position rows')
                }
            })
            .then((results) => {
                const remainingRows = results.filter((row) => row.guid !== guid)

                // Проверяем, остаются ли записи после удаления
                if (remainingRows.length == 0) {
                    setFirstSelected(false) // Если все записи удалены
                    onAreParticipantsSMEChange(false)
                }

                // Удаляем строку
                return fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/plan_position_row_draft/?plan_guid=${planGuid}&pos_guid=${posGuid}&guid=${guid}`,
                    {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                ).then((response) => {
                    if (response.ok) {
                        setRows((prev) =>
                            prev.filter((row) => row.guid !== guid)
                        )
                        setTotalCount(calculateTotalCount())
                    } else {
                        throw new Error('Failed to delete row')
                    }
                })
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const updateRowOnServer = useCallback(
        debounce((row: PlanPositionRow) => {
            fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_position_row_draft/?plan_guid=${planGuid}&pos_guid=${posGuid}&guid=${row.guid}`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(row),
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            `HTTP error! Status: ${response.status}`
                        )
                    }
                })
                .catch((error) => {
                    console.error('Error updating row:', error)
                })
        }, 300),
        []
    )

    const updateRowOnServerSMB = (row: PlanPositionRow) => {
        fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/plan_position_row_draft/?plan_guid=${planGuid}&pos_guid=${posGuid}&guid=${row.guid}`,
            {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(row),
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`)
                }
            })
            .catch((error) => {
                console.error('Error updating row:', error)
            })
    }

    const updateRowsForSmb = async (isPurchaseSmb: boolean | null) => {
        const updatedRowss = await Promise.all(
            rows.map(async (row) => {
                if (!row.okpd2_code) {
                    return row
                }

                try {
                    const response = await fetch(
                        process.env.REACT_APP_API_URL +
                            `/api/backend/v1/list_gws_smb/code_search?text_query=${encodeURIComponent(row.okpd2_code)}`,
                        {
                            method: 'GET',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    const data = await response.json()

                    console.log(row.okpd2_code)

                    const isMspCode = data && data.length > 0

                    if (
                        (isPurchaseSmb && !isMspCode) ||
                        (!isPurchaseSmb && isMspCode)
                    ) {
                        console.log(row.okpd2_name)

                        const updatedRow = {
                            ...row,
                            okpd2_code: null,
                            okpd2_name: null,
                        }
                        await updateRowOnServerSMB(updatedRow)
                        return updatedRow
                    }

                    return row
                } catch (error) {
                    console.error(
                        `Error checking OKPD2 for row ${row.guid}:`,
                        error
                    )
                    return row
                }
            })
        )

        setRows(updatedRowss)
    }

    useEffect(() => {
        if (rows.length > 1) {
            updateRowsForSmb(posMSPStatus)
        }
    }, [posMSPStatus])

    const scheduleRowUpdateOnServer = (
        guid: string,
        updates: Partial<PlanPositionRow>
    ) => {
        const updatedRow = rows.find((row) => row.guid === guid)
        if (updatedRow) {
            updateRowOnServer({ ...updatedRow, ...updates })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const okei = await getOkeiList()
            if (okei) {
                setOkeiList(okei)
            }
        }
        fetchData()
    }, [])

    // Функция проверки кода ОКПД2 первой строки
    const checkOkpd2FirstRow = async (okpd2Code: string) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/list_gws_smb/code_search?text_query=${encodeURIComponent(okpd2Code)}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            const data = await response.json()
            const isSME = data && data.length > 0
            onAreParticipantsSMEChange(isSME)
        } catch (error) {
            console.error('Error checking OKPD2 code:', error)
            onAreParticipantsSMEChange(false)
        }
    }

    const handleOkpd2Selection = (
        rowIndex: number,
        okpd2Code: string | null,
        okpd2Name: string | null
    ) => {
        setRows((prevRows) =>
            prevRows.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...row,
                        okpd2_code: okpd2Code,
                        okpd2_name: okpd2Name,
                    }
                }
                return row
            })
        )

        // Проверка только для первой строки (index === 0)
        if (okpd2Code) {
            checkOkpd2FirstRow(okpd2Code)
            setFirstSelected(true)
        }
    }

    return (
        <fieldset disabled={!isDraft || isLoading}>
            <div className="flex flex-col w-full min-w-fit bg-[#242225] rounded">
                {/* toolbar */}
                <div className="flex flex-row gap-1 place-items-center text-white p-2 h-12 bg-[#333033] rounded-t ">
                    <div className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                        <input type="checkbox" className="p-2" />
                    </div>

                    {isDraft && (
                        <>
                            {/* Добавить */}
                            <button
                                onClick={addNewRow}
                                className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]"
                                title="Добавить позицию ТРУ"
                            >
                                <svg
                                    className="plus-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="plus"
                                >
                                    <path
                                        fill="#FFFFFF"
                                        d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                    ></path>
                                </svg>
                            </button>
                            <button
                                onClick={() => {
                                    onAreParticipantsSMEChange(!posMSPStatus)
                                    setFirstSelected(false)
                                }}
                                className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]"
                                title="Очистить ОКПД2"
                            >
                                <svg
                                    className="plus-icon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="plus"
                                >
                                    <path
                                        fill="#FFFFFF"
                                        d="M14.71,12.29a1,1,0,0,0-1.42,0L12,13.59l-1.29-1.3a1,1,0,0,0-1.42,1.42L10.59,15l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,16.41l1.29,1.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,15l1.3-1.29A1,1,0,0,0,14.71,12.29ZM20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.1,0A1.1,1.1,0,0,0,13.06,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Z"
                                    ></path>
                                </svg>
                            </button>
                        </>
                    )}
                    <span className="grow"></span>

                    <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center border-r border-r-[#464447]">
                        Позиций <br />
                        {rows.length}
                    </div>
                    <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center">
                        Количество (объем) <br />
                        {totalCount}
                    </div>
                </div>
                {/* name */}
                <div className="flex flex-row gap-1 h-16 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                    <div className="grid w-8 place-items-center text-center"></div>
                    <div className="grid w-12 place-items-center text-center">
                        №
                    </div>
                    <div className="flex-1 grid place-items-center text-center min-w-72">
                        Наименование
                    </div>
                    <div
                        className="grid w-8 place-items-center text-center"
                        title="Невозможно определить количество"
                    >
                        <svg
                            className="w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 170 170"
                            id="uncount"
                        >
                            <path
                                fill="#8A8A8A"
                                d="M100 57c0,0 -1,1 -1,1 0,0 0,1 -1,1 0,0 0,1 0,1 0,0 0,0 0,1 0,0 0,0 0,1 0,0 0,0 0,0 0,1 0,2 1,2 1,2 2,3 4,4 1,0 2,1 3,1 1,0 2,0 3,-1 2,-1 3,-2 4,-4 0,-1 0,-2 0,-2 0,0 0,0 0,0 0,0 0,0 0,0 0,-2 -1,-4 -2,-5 -1,-1 -3,-2 -5,-2 -2,0 -4,1 -5,2l0 0zm-12 -26l-27 -17c-1,0 -1,-1 -2,-1l-1 0c0,0 -1,0 -1,0 0,0 -1,0 -1,0l-1 0c-1,0 -2,0 -2,1l-51 32 -1 0 -1 1 -1 0 0 0 0 1 0 1 0 0c0,0 0,0 0,1 0,0 0,0 0,1l0 60c0,0 0,0 0,0 0,2 1,5 3,6l51 32 0 0 1 0 1 0c1,0 1,0 2,0 1,0 1,0 2,0l1 0 1 0 0 0 51 -31c2,-1 3,-3 3,-6 0,0 0,0 0,0l0 -35c-2,-2 -10,-3 -13,0l0 30 -38 23 0 -44 29 -18c1,-3 -4,-10 -8,-11l-28 17 -38 -23 38 -24 23 14c4,1 10,-8 8,-11zm-38 99l-38 -23 0 -44 38 23 0 43zm54 -130c0,0 0,0 0,0 -7,0 -14,4 -18,10 -1,1 -1,2 -1,4 0,4 3,7 7,7 3,0 5,-1 6,-4 1,-2 3,-3 6,-3 0,0 0,0 0,0 4,0 7,3 7,7 0,4 -3,7 -7,7 -4,0 -7,3 -7,7l0 7c0,4 3,7 7,7 4,0 7,-3 7,-7l0 -1c8,-3 13,-11 13,-19 0,-11 -9,-20 -20,-20l0 0z"
                            />
                        </svg>
                    </div>
                    <div className="grid w-28 place-items-center text-center">
                        Количество (объем)
                    </div>
                    <div className="grid w-32 place-items-center text-center">
                        Единица измерения
                    </div>
                    <div className="grid w-28 place-items-center text-center">
                        Тип объекта закупки
                    </div>
                    <div className="grid w-28 place-items-center text-center">
                        ОКПД2
                    </div>
                    <div className="grid w-28 place-items-center text-center">
                        ОКВЭД2
                    </div>
                    {showRegion && (
                        <div className="grid w-44 place-items-center text-center">
                            Регион
                        </div>
                    )}
                    <div className="grid w-8 place-items-center text-center"></div>
                </div>
                {/* date */}
                <div className="flex flex-col overflow-y-auto gap-1 my-1">
                    {Object.values(rows).map((row, index) => (
                        <PurchaseItemsTableRow
                            key={index}
                            index={index}
                            positionRow={row}
                            showRegion={showRegion}
                            onNameChanged={(value: string) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                additional_info: value,
                                            }
                                        }
                                        return prevRow
                                    })
                                )
                                scheduleRowUpdateOnServer(row.guid, {
                                    additional_info: value,
                                })
                            }}
                            onImpossibleToDetermineAttrChanged={(
                                attr: boolean | null,
                                value_qty: number | null,
                                code_okei: string | null,
                                value_okei: string | null
                            ) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                impossible_to_determine_attr:
                                                    attr,
                                                qty: value_qty,
                                                okei_code: code_okei,
                                                okei_name: value_okei,
                                            }
                                        }
                                        return prevRow
                                    })
                                )
                                scheduleRowUpdateOnServer(row.guid, {
                                    impossible_to_determine_attr: attr,
                                    qty: value_qty,
                                    okei_code: code_okei,
                                    okei_name: value_okei,
                                })
                                console.log(
                                    'impossible_to_determine_attr - ' + attr
                                )
                            }}
                            onCountChanged={(value: number | null) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                qty: value,
                                            }
                                        }
                                        return prevRow
                                    })
                                )
                                setTotalCount(calculateTotalCount())
                                scheduleRowUpdateOnServer(row.guid, {
                                    qty: value,
                                })
                                console.log('qty - ' + value)
                            }}
                            onOkeiChanged={(
                                code: string | null,
                                value: string | null
                            ) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                okei_code: code,
                                                okei_name: value,
                                            }
                                        }
                                        return prevRow
                                    })
                                )
                                scheduleRowUpdateOnServer(row.guid, {
                                    okei_code: code,
                                    okei_name: value,
                                })
                                console.log('okei_code - ' + code)
                                console.log('okei_name - ' + value)
                            }}
                            onTypePurchaseChanged={(value: string | null) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                type_object_purchase: value,
                                            }
                                        }
                                        return prevRow
                                    })
                                )
                                scheduleRowUpdateOnServer(row.guid, {
                                    type_object_purchase: value,
                                })
                            }}
                            onDeleteClick={() => {
                                // setRows(rows.filter((_, idx) => idx !== index))
                                // setTotalCount(calculateTotalCount())
                                removeRow(row.guid)
                            }}
                            onDropdownSelected={(type: string) => {
                                setPopupType([index, type])
                            }}
                            okeiList={okeiList}
                        />
                    ))}
                </div>
                {/* buttons */}
                <div className="flex flex-row gap-2 h-12 text-white p-2 bg-[#333033] rounded-b items-center">
                    <button
                        className="flex flex-row gap-2 px-3 h-[25px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365]"
                        onClick={addNewRow}
                        title="Добавить строку"
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                            id="add"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                            ></path>
                        </svg>{' '}
                        Добавить строку
                    </button>

                    <button
                        className="flex flex-row gap-2 px-3 h-[25px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365]"
                        onClick={() => {
                            onAreParticipantsSMEChange(!posMSPStatus)
                            setFirstSelected(false)
                        }}
                        title="Добавить строку"
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 22 22"
                            id="add"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M14.71,12.29a1,1,0,0,0-1.42,0L12,13.59l-1.29-1.3a1,1,0,0,0-1.42,1.42L10.59,15l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,16.41l1.29,1.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,15l1.3-1.29A1,1,0,0,0,14.71,12.29ZM20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.1,0A1.1,1.1,0,0,0,13.06,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Z"
                            ></path>
                        </svg>{' '}
                        Очистка ОКПД2
                    </button>
                    <div className="flex flex-1 gap-1 text-[10px] text-white place-items-end text-center "></div>
                    <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center border-r border-r-[#464447]">
                        Позиций <br />
                        {rows.length}
                    </div>
                    <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center">
                        Количество (объем) <br />
                        {totalCount}
                    </div>
                </div>

                {popupType !== null && (
                    <PurchaseItemsPopup
                        type={popupType[1]}
                        posMSPStatus={posMSPStatus}
                        purchaseMethodCode={purchaseMethodCode}
                        purchaseMethodName={purchaseMethodName}
                        isFirstSelected={isFirstSelected}
                        onSelected={(value) => {
                            const rowIndex = popupType[0]
                            const type = popupType[1]
                            setRows((prevRows) => {
                                return prevRows.map((row, index) => {
                                    if (index === rowIndex) {
                                        return {
                                            ...row,
                                            okpd2_code:
                                                type === 'okpd2'
                                                    ? (value as Okpd2).code
                                                    : row.okpd2_code,
                                            okpd2_name:
                                                type === 'okpd2'
                                                    ? (value as Okpd2).name
                                                    : row.okpd2_name,
                                            okved2_code:
                                                type === 'okved2'
                                                    ? (value as Okved2).code
                                                    : row.okved2_code,
                                            okved2_name:
                                                type === 'okved2'
                                                    ? (value as Okved2).name
                                                    : row.okved2_name,
                                            okato:
                                                type === 'okato'
                                                    ? (value as Okato).code
                                                    : row.okato,
                                            region:
                                                type === 'okato'
                                                    ? (value as Okato).name
                                                    : row.region,
                                        }
                                    }
                                    return row
                                })
                            })

                            const a = value as Okpd2
                            if (type === 'okpd2') {
                                handleOkpd2Selection(rowIndex, a.code, a.name)
                            }

                            // close popup
                            setPopupType(null)

                            if (type === 'okpd2') {
                                const v = value as Okpd2
                                scheduleRowUpdateOnServer(rows[rowIndex].guid, {
                                    okpd2_code: v.code,
                                    okpd2_name: v.name,
                                })
                            } else if (type === 'okved2') {
                                const v = value as Okved2
                                scheduleRowUpdateOnServer(rows[rowIndex].guid, {
                                    okved2_code: v.code,
                                    okved2_name: v.name,
                                })
                            } else if (type === 'okato') {
                                const v = value as Okato
                                scheduleRowUpdateOnServer(rows[rowIndex].guid, {
                                    okato: v.code,
                                    region: v.name,
                                })
                            }
                        }}
                        onClose={() => setPopupType(null)}
                    />
                )}
            </div>
        </fieldset>
    )
}

export default PurchaseItemsTable
