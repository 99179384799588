import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/auth'
import { Navigate } from 'react-router-dom'
import ColumnChart from '../Hub/components/DashboardCharts/ColumnChart'
import LineChart from '../Hub/components/DashboardCharts/LineChart'
import PieChartPlanCount from './components/DashboardCharts/PieChartPlanCount'
import PieChartPlanSumm from './components/DashboardCharts/PieChartPlanSumm'
import PieChartPlanCountMSP from './components/DashboardCharts/PieChartPlanCountMSP'
import PieChartPlanSummMSP from './components/DashboardCharts/PieChartPlanSummMSP'
import PieChartPlanCountGWS from './components/DashboardCharts/PieChartPlanCountGWS'
import PieChartPlanSummGWS from './components/DashboardCharts/PieChartPlanSummGWS'
import PieChartPlanCountForm from './components/DashboardCharts/PieChartPlanCountForm'
import PieChartPlanSummType from './components/DashboardCharts/PieChartPlanSummType'
import TableChartFHD from './components/DashboardCharts/TableChartFHD'
import TableChartPurchaseMethod from './components/DashboardCharts/TableChartPurchaseMethod'
import TableChartPurchaseStruct from './components/DashboardCharts/TableChartPurchaseStruct'
import PositionsStageCount from './components/DashboardCharts/PositionsStageCount'

import Footer from '../Home/components/Footer'

import NumberFormat from './components/DashboardCharts/NumberFormat'

import { Plan } from './Plans/types'

type ApiResponse = {
    message: Plan[]
}

const HubPage: React.FC = () => {
    const [guid, setGuid] = useState<string>('')
    const [registrationNumber, setRegistrationNumber] = useState<number>(0)
    const [availableYears, setAvailableYears] = useState<number[]>([])
    const [selectedYear, setSelectedYear] = useState<string | undefined>(
        'Нет данных'
    )
    const [countPlan, setCountPlan] = useState('Нет данных')
    const [sumPlan, setSumPlan] = useState('Нет данных')
    const [sumMSPPlanPercent, setSumMSPPlanPercent] = useState('Нет данных')
    const [sumMSPPlan, setSumMSPPlan] = useState('Нет данных')
    const [isLoading, setLoading] = useState(false)

    const { user, logout } = useAuth()

    const fetchPlans = async (initialYear?: string) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + '/api/backend/v1/plans',
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`)
            }
            const data: ApiResponse = await response.json()
            const plans: Plan[] = data.message

            if (!plans || plans.length === 0) {
                setGuid('Нет данных')
                setAvailableYears([])
                setSelectedYear('Нет данных')
                return
            }

            // Извлечение уникальных годов из start_date
            const years = Array.from(
                new Set(
                    plans
                        .map((plan) => plan.start_date?.split('-')[0])
                        .filter(Boolean)
                )
            )
                .map(Number)
                .sort((a, b) => b - a)

            setAvailableYears(years)

            // Выбор года
            const currentYear = new Date().getFullYear()
            let defaultYear = initialYear

            if (!initialYear) {
                if (years.includes(currentYear)) {
                    defaultYear = currentYear.toString()
                } else if (years.includes(currentYear - 1)) {
                    defaultYear = (currentYear - 1).toString()
                } else {
                    defaultYear = 'Нет данных'
                }
            }

            setSelectedYear(defaultYear)

            // Найти план для выбранного года
            const planForYear = plans.find((plan) =>
                plan.start_date?.startsWith(defaultYear || '')
            )
            if (planForYear) {
                setGuid(planForYear.guid || 'Нет данных')
                setRegistrationNumber(planForYear.registration_number || 0)
            } else {
                setGuid('Нет данных')
                setRegistrationNumber(0)
            }
        } catch (error) {
            console.error('Error fetching plans:', error)
            setGuid('Нет данных')
            setAvailableYears([])
            setSelectedYear('Нет данных')
        }
    }

    const fetchCountPlan = async (guidPlan?: string) => {
        setLoading(true)

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_positions_count_without_draft/?plan_guid=${guidPlan}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                setCountPlan('Нет данных')
                setLoading(false)
                throw new Error(`Error: ${response.statusText}`)
            }

            const poses_count = await response.json()

            setCountPlan(poses_count.poses_count)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const fetchSumPlan = async (guidPlan?: string) => {
        setLoading(true)

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_positions_summ_piechartplansumm/?plan_guid=${guidPlan}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                setSumPlan('Нет данных')
                setLoading(false)
                throw new Error(`Error: ${response.statusText}`)
            }

            const poses_summ = await response.json()

            setSumPlan(poses_summ.poses_summ)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const fetchSumMSPPlan = async (guidPlan?: string) => {
        setLoading(true)

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_positions_count_piechartplansummsmb/?plan_guid=${guidPlan}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                setSumMSPPlan('Нет данных')
                setSumMSPPlanPercent('Нет данных')
                setLoading(false)
                throw new Error(`Error: ${response.statusText}`)
            }

            const poses_sum = await response.json()

            // Проверяем, что все необходимые поля существуют
            if (
                typeof poses_sum.poses_sum_smb === 'undefined' ||
                typeof poses_sum.poses_sum_not_smb === 'undefined'
            ) {
                setSumMSPPlan('Нет данных')
                setSumMSPPlanPercent('Нет данных')
                throw new Error('Response does not contain required fields')
            }

            // Проверяем деление на ноль
            if (poses_sum.poses_sum_smb === 0) {
                setSumMSPPlan('Нет данных')
                throw new Error('Division by zero error')
            }

            // Расчёт процента
            const calc =
                (poses_sum.poses_sum_smb * 100) /
                (poses_sum.poses_sum_smb + poses_sum.poses_sum_not_smb)

            setSumMSPPlan(poses_sum.poses_sum_smb)
            setSumMSPPlanPercent(calc.toFixed(2))
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPlans()
    }, [])

    useEffect(() => {
        if (guid != '') {
            fetchCountPlan(guid)
            fetchSumPlan(guid)
            fetchSumMSPPlan(guid)
        } else {
            setCountPlan('Нет данных')
            setSumPlan('Нет данных')
            setSumMSPPlan('Нет данных')
            setSumMSPPlanPercent('Нет данных')
        }
    }, [guid])

    const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const year = e.target.value
        setSelectedYear(year)
        fetchPlans(year) // Передаём выбранный год в fetchPlans
    }

    const handleLogout = () => {
        fetch(process.env.REACT_APP_API_URL + '/api/auth/v1/users/logout/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((r) => {
            if (r.ok) {
                logout()
            }
        })
    }

    if (!user) {
        return <Navigate to="/auth" />
    }

    return (
        <div className="flex flex-col w-full h-screen bg-[#181818]">
            <div className="flex flex-row w-full h-24 justify-center items-center bg-[#000000]">
                {/* <button
                    onClick={handleLogout}
                    className="text-[#fff] w-fit h-fit bg-slate-800 py-1 px-3 rounded absolute top-8 left-52"
                >
                    Выход
                </button> */}
                <PositionsStageCount plan_guid={guid || 'Нет данных'} />
            </div>

            <div className="flex flex-row w-full h-20 justify-center items-center bg-[#111111]">
                <div className="flex flex-row w-full h-full">
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5 border-r border-[#2A282B]">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            Период планирования
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            <select
                                className="form-select focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                {availableYears.length === 0 ? (
                                    <option value="Нет данных">
                                        Нет данных
                                    </option>
                                ) : (
                                    availableYears.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5 border-r border-[#2A282B]">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            План закупки
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            {registrationNumber == 0
                                ? 'Нет данных'
                                : registrationNumber}
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5 border-r border-[#2A282B]">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            Количество позиций
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            {isLoading ? (
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                            ) : countPlan == 'Нет данных' ? (
                                'Нет данных'
                            ) : (
                                countPlan
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5 border-r border-[#2A282B]">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            Запланировано закупок
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            {isLoading ? (
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                            ) : sumPlan == 'Нет данных' ? (
                                'Нет данных'
                            ) : (
                                <>
                                    <NumberFormat value={Number(sumPlan)} /> ₽
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col flex-1 justify-center items-start mx-3 my-5">
                        <div className="flex text-left text-[#8A8A8A] text-[12.8px]">
                            Запланировано закупок у МСП
                        </div>
                        <div className="flex text-left text-white text-[14px]">
                            {isLoading ? (
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                            ) : sumMSPPlanPercent == 'Нет данных' ? (
                                'Нет данных'
                            ) : (
                                <>
                                    <NumberFormat value={Number(sumMSPPlan)} />{' '}
                                    ₽{' ('}
                                    {sumMSPPlanPercent} {'%)'}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full h-[calc(100vh_-_176px)] justify-start items-center bg-[#181818] overflow-y-auto overflow-x-hidden">
                <div className="flex flex-col bg-[#181818] justify-center items-center space-y-5 p-5">
                    <div className="flex flex-row space-x-5">
                        <div className="flex flex-col w-[400px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanCount
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[400px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanSumm
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[400px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanCountMSP
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[400px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanSummMSP
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row space-x-5">
                        <div className="flex flex-col w-[400px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanCountGWS
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[400px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanSummGWS
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[400px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanSummType
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[400px] h-60 bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <PieChartPlanCountForm
                                    plan_guid={guid || 'Нет данных'}
                                    initialIndex={1}
                                    totalIndex={0}
                                />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row space-x-5">
                        <div className="flex flex-col w-[820px] h-[380px] bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <ColumnChart plan_guid={guid || 'Нет данных'} />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col w-[820px] h-[380px] bg-[#262427] rounded-lg p-3">
                            {guid ? (
                                <LineChart plan_guid={guid || 'Нет данных'} />
                            ) : (
                                <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                    <span>Загрузка</span>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* <div className="flex flex-row space-x-5">
                        <div className="flex flex-col w-[540px] h-[400px] bg-[#262427] rounded-lg p-2.5">
                            <TableChartFHD plan_guid={guid || 'Нет данных'} />
                        </div>

                        <div className="flex flex-col w-[540px] h-[400px] bg-[#262427] rounded-lg p-2.5">
                            <TableChartPurchaseMethod
                                plan_guid={guid || 'Нет данных'}
                            />
                        </div>

                        <div className="flex flex-col w-[540px] h-[400px] bg-[#262427] rounded-lg p-2.5">
                            <TableChartPurchaseStruct
                                plan_guid={guid || 'Нет данных'}
                            />
                        </div>
                    </div> */}
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default HubPage
