import React from 'react'
import './index.css'

type Props = {
    count: number | null
}

const ApprovalsTabs: React.FC<Props> = ({ count }) => {
    return (
        <div className="flex flex-row">
            <div className="w-5"></div>
            <div className={`cursor-pointer plan-position-tab-active`}>
                <div className="flex flex-row w-full h-full items-center justify-center gap-2">
                    Реестр согласований
                    {count ? (
                        <div className="flex w-fit px-2 rounded-full bg-[#333033] text-white items-center justify-center">
                            {count}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    )
}

export default ApprovalsTabs
