import React from 'react'
import ReactDOM from 'react-dom/client'
import {
    createHashRouter,
    defer,
    Navigate,
    RouterProvider,
} from 'react-router-dom'

import './index.css'
import reportWebVitals from './reportWebVitals'

import RootLayout from './pages/RootLayout'
import ErrorPage from './pages/ErrorPage'

import HomePage from './pages/Home'

import AuthLayout from './pages/Auth/AuthLayout'
import LoginForm from './pages/Auth/LoginForm'
import RestoreForm from './pages/Auth/RestoreForm'
import RegisterForm, { loadOrganization } from './pages/Auth/RegisterForm'
import SearchForm from './pages/Auth/SearchForm'
import ResetForm from './pages/Auth/ResetForm'

import { getUserData } from './services/auth'

import HubPage from './pages/Hub/HubPage'
import HubLayout from './pages/Hub/HubLayout'
import HubLayoutInner from './pages/Hub/HubLayoutInner'

import OrgHomePage, { loadOrganizationData } from './pages/Hub/Org/Home/Home'
import OrgListsPage from './pages/Hub/Org/Lists'
import OrgPurchaseMethodsPage, {
    loadPurchaseMethods,
} from './pages/Hub/Org/PurchaseMethods'
import OrgStructurePage, { loadStructure } from './pages/Hub/Org/Structure'
import OrgUsersPage, { loadUsers } from './pages/Hub/Org/Users'
import OrgRolesPage, { loadRole } from './pages/Hub/Org/Roles'

import PlanListPage, { loadPlanList } from './pages/Hub/Plans'
import PlanPositionListPage from './pages/Hub/Plans/Positions'
import PlanPositionEditPage, {
    loadPlanPosition,
} from './pages/Hub/Plans/Positions/Edit'
import OrgUserViewOrEditPage, {
    loadUser,
} from './pages/Hub/Org/Users/ViewOrEdit'
import OrgPurchaseMethodViewOrEdit, {
    loadPurchaseMethod,
} from './pages/Hub/Org/PurchaseMethods/ViewOrEdit'
import OrgListMspPage from './pages/Hub/Org/Lists/Msp'
import OrgListEpPage, { loadListEp } from './pages/Hub/Org/Lists/Ep'
import OrgListFxdPage, { loadListFxd } from './pages/Hub/Org/Lists/Fxd'
import OrgHandbookListPage from './pages/Hub/Org/Handbook'
import OrgHandbookOkved2Page from './pages/Hub/Org/Handbook/OKVED2'
import OrgHandbookOkpd2Page from './pages/Hub/Org/Handbook/OKPD2'

import ApprovalsPage from './pages/Hub/Approvals'
import Agreement from './pages/Info/Agreement'
import Info from './pages/Info/Info'

const router = createHashRouter([
    {
        path: '/',
        element: <RootLayout />,
        loader: () => defer({ userPromise: getUserData() }),
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: 'auth',
                element: <AuthLayout />,
                children: [
                    {
                        index: true,
                        element: <LoginForm />,
                    },
                    {
                        path: 'restore',
                        element: <RestoreForm />,
                    },
                    {
                        path: 'reset_password',
                        element: <ResetForm />,
                    },
                    {
                        path: 'search',
                        element: <SearchForm />,
                    },
                    {
                        path: 'register/:id',
                        loader: loadOrganization,
                        element: <RegisterForm />,
                    },
                ],
            },
            {
                path: 'hub',
                element: <HubLayout />,
                children: [
                    {
                        element: <HubLayoutInner />,
                        children: [
                            {
                                path: 'org/home',
                                element: <OrgHomePage />,
                                loader: loadOrganizationData,
                            },
                            {
                                path: 'org/users',
                                element: <OrgUsersPage />,
                                loader: loadUsers,
                            },
                            {
                                path: 'org/users/:guid',
                                element: <OrgUserViewOrEditPage />,
                                loader: loadUser,
                            },
                            {
                                path: 'org/users/:guid/edit',
                                element: <OrgUserViewOrEditPage />,
                                loader: loadUser,
                            },
                            {
                                path: 'org/structure',
                                element: <OrgStructurePage />,
                                loader: loadStructure,
                            },
                            {
                                path: 'org/roles',
                                element: <OrgRolesPage />,
                                loader: loadRole,
                            },
                            {
                                path: 'org/methods',
                                element: <OrgPurchaseMethodsPage />,
                                loader: loadPurchaseMethods,
                            },
                            {
                                path: 'org/methods/:guid',
                                element: <OrgPurchaseMethodViewOrEdit />,
                                loader: loadPurchaseMethod,
                            },
                            {
                                path: 'org/methods/:guid/edit',
                                element: <OrgPurchaseMethodViewOrEdit />,
                                loader: loadPurchaseMethod,
                            },
                            {
                                path: 'org/lists',
                                element: <OrgListsPage />,
                            },
                            {
                                path: 'org/lists/msp',
                                element: <OrgListMspPage />,
                            },
                            {
                                path: 'org/lists/ep',
                                element: <OrgListEpPage />,
                                loader: loadListEp,
                            },
                            {
                                path: 'org/lists/fxd',
                                element: <OrgListFxdPage />,
                                loader: loadListFxd,
                            },
                            {
                                path: 'org/handbook',
                                element: <OrgHandbookListPage />,
                            },
                            {
                                path: 'org/handbook/okpd2',
                                element: <OrgHandbookOkpd2Page />,
                            },
                            {
                                path: 'org/handbook/okved2',
                                element: <OrgHandbookOkved2Page />,
                            },
                            {
                                index: true,
                                element: <HubPage />,
                            },
                            {
                                path: 'plans',
                                loader: loadPlanList,
                                element: <PlanListPage />,
                            },
                            {
                                path: 'plans/:plan_guid/positions',
                                element: <PlanPositionListPage />,
                            },
                            {
                                path: 'home',
                                element: <HubPage />,
                            },
                            {
                                path: 'approvals/plans',
                                element: <ApprovalsPage />,
                            },
                        ],
                    },
                    {
                        path: 'plans/:plan_guid/positions/:action/:pos_guid?',
                        loader: loadPlanPosition,
                        element: <PlanPositionEditPage />,
                    },
                ],
            },
            {
                path: 'info',
                element: <Info />,
                children: [
                    {
                        path: 'agreement',
                        element: <Agreement />,
                    },
                ],
            },
        ],
    },
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
