import React, { useState, useEffect } from 'react'
import { PlanPositionRow, Okei } from '../types'

type LayoutProps = {
    index: number
    positionRow: PlanPositionRow
    showRegion: boolean
    onNameChanged: (value: string) => void
    onImpossibleToDetermineAttrChanged: (
        attr: boolean | null,
        value_qty: number | null,
        code_okei: string | null,
        value_okei: string | null
    ) => void
    onCountChanged: (value: number | null) => void
    onOkeiChanged: (code: string | null, value: string | null) => void
    onTypePurchaseChanged: (value: string) => void
    onDropdownSelected: (type: string) => void
    onDeleteClick: () => void
    okeiList: Okei[]
}

const PurchaseItemsTableRow: React.FC<LayoutProps> = ({
    index,
    positionRow,
    showRegion,
    onNameChanged,
    onImpossibleToDetermineAttrChanged,
    onCountChanged,
    onOkeiChanged,
    onTypePurchaseChanged,
    onDropdownSelected,
    onDeleteClick,
    okeiList,
}) => {
    return (
        <div className="flex flex-row gap-1 m-1 bg-[#242225] text-[11px] rounded items-start">
            <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                <div className="flex w-6 h-6 rounded-full items-center justify-center bg-[#242225] hover:bg-[#656365]">
                    <input type="checkbox" className="p-2" />
                </div>
            </div>

            <div className="flex flex-row h-8 w-12 items-center justify-center text-center text-white">
                {index + 1}
            </div>

            <div className="flex flex-1 text-left min-w-72">
                <input
                    className="flex h-8 min-h-8 w-full p-2 rounded-md border-0 bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    maxLength={2000}
                    value={positionRow.additional_info ?? ''}
                    onChange={(e) => onNameChanged(e.target.value)}
                />
            </div>

            <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                <div
                    className="flex w-6 h-6 rounded-full items-center justify-center bg-[#242225] hover:bg-[#656365]"
                    title="Невозможно определить количество"
                >
                    <input
                        type="checkbox"
                        className="p-2"
                        checked={
                            positionRow.impossible_to_determine_attr || false
                        }
                        onChange={(e) => {
                            onImpossibleToDetermineAttrChanged(
                                e.target.checked,
                                null,
                                null,
                                null
                            )

                            console.log('Значение chacked ' + e.target.checked)
                        }}
                    />
                </div>
            </div>

            <div className="flex flex-row h-8 w-28 items-center justify-center text-center">
                <input
                    min="0"
                    name="purchase_item_count"
                    className={`h-8 rounded-md text-primary text-center w-full pr-1  focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#181818] border-none text-white ${positionRow.impossible_to_determine_attr ? 'hidden' : ''}`}
                    style={{ colorScheme: 'dark' }}
                    value={positionRow.qty ?? ''}
                    onChange={(e) => onCountChanged(+e.target.value)}
                    disabled={positionRow.impossible_to_determine_attr || false}
                />
            </div>

            <div className="flex flex-row h-8 w-32 items-center justify-center text-center">
                <select
                    name="purchase_item_count_type"
                    className={`h-8 px-2 rounded-md w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#181818] border-none text-white ${positionRow.impossible_to_determine_attr ? 'hidden' : ''}`}
                    title={positionRow.okei_name ?? ''}
                    onChange={(e) => {
                        const selectedCode = e.target.value
                        const selectedOkei = okeiList.find(
                            (okei) => okei.code === selectedCode
                        )
                        onOkeiChanged(selectedCode, selectedOkei?.name || null)
                    }}
                    value={positionRow.okei_code ?? ''}
                    disabled={positionRow.impossible_to_determine_attr || false}
                >
                    <option className="text-[#8A8A8A]" value="">
                        Выбрать
                    </option>
                    {okeiList.map((okei, index_okei) => (
                        <option
                            key={index_okei}
                            value={okei.code}
                            title={`${okei.name} (${okei.symbol?.toLowerCase()})`}
                        >
                            {okei.name} ({okei.symbol?.toLowerCase()})
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex flex-row h-8 w-28 items-center justify-center text-center text-white">
                <select
                    name="purchase_item_type_purchase"
                    className="h-8 px-2 rounded-md bg-[#181818] w-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    value={positionRow.type_object_purchase ?? ''}
                    onChange={(e) => onTypePurchaseChanged(e.target.value)}
                >
                    <option className="text-[#8A8A8A]" value="">
                        Выбрать
                    </option>
                    <option value="G">Товар</option>
                    <option value="W">Работа</option>
                    <option value="S">Услуга</option>
                </select>
            </div>

            <div className="relative flex flex-row w-28 items-center justify-end">
                <input
                    type="text"
                    className="flex pl-2 pr-9 h-8 w-full rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    value={positionRow.okpd2_code ?? ''}
                    readOnly
                    title={`${positionRow.okpd2_code} - ${positionRow.okpd2_name}`}
                />

                <button
                    className="absolute z-10 flex w-6 h-6 mr-1 rounded-full items-center justify-center bg-[#181818] hover:bg-[#656365]"
                    onClick={() => onDropdownSelected('okpd2')}
                >
                    <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="bars"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                        ></path>
                    </svg>
                </button>
            </div>

            <div className="relative flex flex-row w-28 items-center justify-end">
                <input
                    type="text"
                    className="flex pl-2 pr-9 h-8 w-full rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                    value={positionRow.okved2_code ?? ''}
                    readOnly
                    title={`${positionRow.okved2_code} - ${positionRow.okved2_name}`}
                />

                <button
                    className="absolute z-10 flex w-6 h-6 mr-1 rounded-full items-center justify-center bg-[#181818] hover:bg-[#656365]"
                    onClick={() => onDropdownSelected('okved2')}
                >
                    <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="bars"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                        ></path>
                    </svg>
                </button>
            </div>

            {showRegion && (
                <div className="relative flex flex-row w-44 items-center justify-end">
                    <input
                        type="text"
                        className="flex pl-2 pr-9 h-8 w-full rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                        value={positionRow.region ?? ''}
                        readOnly
                        title={`${positionRow.okato} - ${positionRow.region}`}
                    />

                    <button
                        className="absolute z-10 flex w-6 h-6 mr-1 rounded-full items-center justify-center bg-[#181818] hover:bg-[#656365]"
                        onClick={() => onDropdownSelected('okato')}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="bars"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            )}

            <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                <button
                    onClick={(e) => onDeleteClick()}
                    className=" flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                >
                    <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="trash-alt"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default PurchaseItemsTableRow
