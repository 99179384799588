import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ListMsp } from '../types'
import LoadingIcon from '../../../../../components/LoadingIcon'

const formatDate = (dateString: string | null): string => {
    if (!dateString) return ''
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return ''
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
}

const OrgListMspPage: React.FC = () => {
    const navigate = useNavigate()
    const [itemsMSP, setItemsMSP] = useState<ListMsp[]>([])
    const [isLoading, setLoading] = useState<boolean>(true)
    const [page, setPage] = useState(1) // -1 - no more data

    const [publicationDate, setPublicationDate] = useState<string | null>(null)
    const [modificationDescription, setModificationDescription] =
        useState<string>('')
    const [validDateFrom, setValidDateFrom] = useState<string | null>(null)
    const [validDateTo, setValidDateTo] = useState<string | null>(null)

    const [selectedFile, setSelectedFile] = useState<File | null>(null) // Храним выбранный файл
    const [uploadMessage, setUploadMessage] = useState<string | null>(null) // Сообщение о результате загрузки

    const [isFiltering, setIsFiltering] = useState(false)
    const [filters, setFilters] = useState({
        mspCode: '',
        mspName: '',
    })

    const loadListMsp = (page_num = 1) => {
        setLoading(true)

        const pageSize = 100
        return fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/list_pg_gws_smb?&page=${page_num}&size=${pageSize}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(
                        'Failed to fetch plan positions (page: ' +
                            page_num +
                            ')'
                    )
                }
            })
            .catch((e) => {
                console.error(e)
                return []
            })
            .then((data) => {
                if (page_num == 1) {
                    setItemsMSP(data)
                } else {
                    setItemsMSP((prev) => [...prev, ...data])
                }
                setPage(data.length < pageSize ? -1 : page_num + 1)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handlerFilter = () => {
        setIsFiltering(!isFiltering)

        setFilters({
            mspCode: '',
            mspName: '',
        })
    }

    const handlerFilterClear = () => {
        setFilters({
            mspCode: '',
            mspName: '',
        })
    }

    // initial load & on tab change
    useEffect(() => {
        setPage(1)
        loadListMsp()
        setPublicationDate(itemsMSP[0]?.publication_date)
        setModificationDescription(itemsMSP[0]?.modification_description)
        setValidDateFrom(itemsMSP[0]?.valid_date_from)
        setValidDateTo(itemsMSP[0]?.valid_date_to)
    }, [])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null
        setSelectedFile(file)
    }

    const handleFileUpload = async () => {
        if (!selectedFile) {
            setUploadMessage('Пожалуйста, выберите файл.')
            return
        }

        const formData = new FormData()
        formData.append('file', selectedFile) // Имя должно совпадать с ожидаемым на сервере

        setUploadMessage(null) // Очистка предыдущего сообщения
        setLoading(true)

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    '/api/backend/v1/list_gws_smb/upload',
                {
                    method: 'POST',
                    credentials: 'include',
                    body: formData,
                    headers: {
                        Accept: 'application/json', // Заголовок Accept для ответа в JSON
                    },
                }
            )

            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(
                    errorData.detail?.[0]?.msg || 'Ошибка загрузки файла'
                )
            }

            const result = await response.json()
            setUploadMessage('Данные успешно импортированы.')
        } catch (error) {
            console.error(error)
            setUploadMessage((error as Error).message)
        } finally {
            setLoading(false)
            setSelectedFile(null)
            loadListMsp()
        }
    }

    return (
        <div className="flex flex-col text-white min-h-screen">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-full h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Перечень товаров, работ, услуг, закупки которых
                            осуществляются у субъектов малого и среднего
                            предпринимательства
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center h-[calc(100vh_-_120px)] p-5">
                <div className="flex flex-col w-[1260px] space-y-10">
                    {/* Общие сведения */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Общие сведения</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Дата размещения, Период действия */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-[200px] space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Дата размещения
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-[#8A8A8A] bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                            disabled
                                            value={
                                                publicationDate
                                                    ? formatDate(
                                                          publicationDate
                                                      )
                                                    : ''
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-[200px] space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Период действия
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-[#8A8A8A] bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            style={{ colorScheme: 'dark' }}
                                            disabled
                                            value={`с ${validDateFrom ? formatDate(validDateFrom) : ''} по ${validDateTo ? formatDate(validDateTo) : ''}`}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Причина изменения сведений */}
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Причина изменения сведений
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="w-full h-7 px-2 rounded-md border border-[#2A282B] text-sm text-[#8A8A8A] bg-[#1F1E1F] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                        style={{ colorScheme: 'dark' }}
                                        value={modificationDescription || ''}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col h-full bg-[#262427] rounded-lg p-5 space-y-5 overflow-y-auto">
                        {/* table */}
                        <div className="flex flex-col h-full bg-[#242225] rounded">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t place-items-center">
                                {/* Filter */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={handlerFilter}
                                    title={
                                        isFiltering
                                            ? 'Убрать фильтр'
                                            : 'Показать фильтр'
                                    }
                                >
                                    {isFiltering ? (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            id="filter-slash"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M19.22,6H12.66a1,1,0,0,0,0,2h6.56a.78.78,0,0,1,.78.78v.78H16.22a1,1,0,1,0,0,2h2.37l-.7.69a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l1.88-1.88a2.51,2.51,0,0,0,.54-.8h0l0-.1A2.59,2.59,0,0,0,22,9.82v-1A2.79,2.79,0,0,0,19.22,6ZM3.71,2.29A1,1,0,0,0,2.29,3.71L5.14,6.55A2.73,2.73,0,0,0,4,8.78v1a2.65,2.65,0,0,0,.24,1.1l0,.06a2.61,2.61,0,0,0,.54.81l5.41,5.4V21a1,1,0,0,0,.47.85,1,1,0,0,0,.53.15,1,1,0,0,0,.45-.11l3.56-1.78a1,1,0,0,0,.55-.89v-2h0l4.51,4.52a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42ZM6,8.78a.76.76,0,0,1,.5-.72L6.59,8,8.15,9.56H6Zm8.07,7.29a1,1,0,0,0-.29.71V18.6l-1.56.78v-2.6a1,1,0,0,0-.29-.71L7.41,11.56h2.74l4.22,4.22Z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="filter"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                            ></path>
                                        </svg>
                                    )}
                                </button>

                                <div className="flex flex-1 gap-1 text-[10px] text-white place-items-end text-center "></div>
                                <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center">
                                    Позиций <br />
                                    {`${itemsMSP.length}`}
                                </div>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                                <div className="flex w-10 items-center justify-center text-center">
                                    №
                                </div>
                                <div className="flex flex-col w-32 justify-center items-center text-center py-1">
                                    <span>Классификация</span>
                                    <span>по ОКПД2</span>
                                </div>
                                <div className="flex flex-1 justify-center items-center text-center">
                                    Наименование товара, работы, услуги
                                </div>
                                <div className="flex w-10 justify-center items-center text-center"></div>
                            </div>

                            {/* filter */}
                            {isFiltering ? (
                                <div className="flex flex-row gap-1 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1">
                                    <div className="flex flex-1 justify-center items-center text-center pl-3">
                                        <input
                                            className="struct-input-placeholder w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            placeholder="Поиск"
                                            value={filters.mspName}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    mspName: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex w-10 justify-center items-center text-center">
                                        <button
                                            className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                            onClick={handlerFilterClear}
                                            title="Очистить фильтр"
                                        >
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                                id="x"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M8 19a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3 1 1 0 0 0 0-2 5 5 0 0 0-5 5v8a5 5 0 0 0 5 5 1 1 0 0 0 0-2Zm7.71-3.29a1 1 0 0 0 0-1.42L13.41 12l2.3-2.29a1 1 0 0 0-1.42-1.42L12 10.59l-2.29-2.3a1 1 0 0 0-1.42 1.42l2.3 2.29-2.3 2.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l2.29-2.3 2.29 2.3a1 1 0 0 0 1.42 0ZM16 3a1 1 0 0 0 0 2 3 3 0 0 1 3 3v8a3 3 0 0 1-3 3 1 1 0 0 0 0 2 5 5 0 0 0 5-5V8a5 5 0 0 0-5-5Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {/* data */}
                            <div
                                className="flex flex-col overflow-y-auto gap-1 my-1 h-full"
                                onScroll={(e) => {
                                    const {
                                        scrollTop,
                                        scrollHeight,
                                        clientHeight,
                                    } = e.currentTarget

                                    if (
                                        page !== -1 && // no more data
                                        !isLoading &&
                                        scrollHeight -
                                            scrollTop -
                                            clientHeight <
                                            100
                                    ) {
                                        loadListMsp(page)
                                    }
                                }}
                            >
                                {/* Вывод ТРУ */}
                                <>
                                    {isLoading && page === 1 ? (
                                        <div className="flex w-full h-full items-center justify-center">
                                            <LoadingIcon />
                                        </div>
                                    ) : !itemsMSP.length ? (
                                        <div className="flex w-full h-12 items-center justify-center text-[#8A8A8A] text-[11px]">
                                            Нет данных
                                        </div>
                                    ) : (
                                        itemsMSP.map((list, index) => (
                                            <div
                                                className="flex flex-row gap-1 mx-1 py-2 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                                                key={index}
                                            >
                                                <div className="grid w-10 place-items-center items-center text-center text-[#8A8A8A]">
                                                    {index + 1}
                                                </div>
                                                <div className="grid w-32 place-items-center items-center px-2">
                                                    {list.okpd2_code}
                                                </div>
                                                <div className="grid flex-1 place-items-center justify-items-start text-left px-2 ">
                                                    {list.okpd2_name}
                                                </div>
                                                <div className="flex w-10 justify-center items-center text-center"></div>
                                            </div>
                                        ))
                                    )}
                                    {isLoading &&
                                        page > 1 &&
                                        itemsMSP.length > 0 && (
                                            <div className="flex w-full items-center justify-center">
                                                <LoadingIcon />
                                            </div>
                                        )}
                                </>
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b h-10 space-x-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* buttons */}
            <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end space-x-5 items-center ">
                <div className="flex flex-1 w-auto items-start">
                    {/* Форма загрузки файла */}
                    <div className="flex flex-row w-1/2 bg-[#262427] rounded-lg items-center">
                        <input
                            type="file"
                            accept=".html,.xls"
                            onChange={handleFileChange}
                            className="w-full text-sm text-gray-500 file:h-7 file:mr-4 file:py-1 file:px-5 file:rounded-lg file:border-0 file:text-[12.8px] file:bg-green-700 file:text-white hover:file:bg-[#16A34A] file:font-normal"
                        />
                        <button
                            onClick={handleFileUpload}
                            className="flex h-7 bg-[#187A33] w-[180px] rounded-lg text-[12.8px] justify-center items-center"
                            disabled={isLoading}
                        >
                            Импортировать
                        </button>
                    </div>
                </div>
                <div className="flex items-center space-x-5">
                    <button
                        className="flex h-7 bg-[#494849] w-[120px] rounded-lg text-[12.8px] justify-center items-center"
                        onClick={() => navigate('/hub/org/lists')}
                    >
                        Закрыть
                    </button>
                </div>
            </div>
            {uploadMessage && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => setUploadMessage(null)}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-fit max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col text-sm text-white items-center justify-center gap-5">
                            <div className="flex flex-row text-sm text-white items-center justify-center gap-2">
                                <div
                                    className={`flex w-5 h-5 rounded-full items-center justify-center text-white ${
                                        uploadMessage ==
                                        'Данные успешно импортированы.'
                                            ? 'bg-[#187A33]'
                                            : 'bg-[#dc742f]'
                                    }`}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        className="h-4 w-4"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d={
                                                uploadMessage ==
                                                'Данные успешно импортированы.'
                                                    ? 'M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z'
                                                    : 'M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z'
                                            }
                                        ></path>
                                    </svg>
                                </div>
                                {uploadMessage}
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-green-700 hover:bg-green-600"
                                    onClick={() => setUploadMessage(null)}
                                >
                                    Ок
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrgListMspPage
