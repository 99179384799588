import React from 'react'
import { Plan } from '../../types'

type Props = {
    positionCount: number | string
    sum: number | string
    mspSum: number
    percentMspSum: number
    plan?: Plan
    loadPlan: boolean
}

const PlanPositionsHeader: React.FC<Props> = ({
    positionCount,
    sum,
    mspSum,
    percentMspSum,
    plan,
    loadPlan,
}) => {
    return (
        <div className=" h-24 mx-6 flex flex-row gap-x-5 ">
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">Реестровый номер</div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        (plan?.registration_number ?? 'Нет данных')
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Период планирования
                </div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        (plan?.start_date.split('-')[0] ?? 'Нет данных')
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Количество позиций
                </div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        positionCount
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Запланировано закупок
                </div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        Number(sum).toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                        }) + ' ₽'
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Запланировано закупок у МСП
                </div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        Number(mspSum).toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                        }) + ` ₽ (${percentMspSum}%)`
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            {/* <div className="w-60 my-auto content-center ">
                <div className="text-xs text-[#8A8A8A] ">
                    Закупки исключаемые у МСП
                </div>
                <div className="text-xs text-white ">0,00 ₽</div>
            </div> */}
        </div>
    )
}

export default PlanPositionsHeader
