import React, { useState, useEffect } from 'react'
import Add from '../../../../components/Toolbar/Add'
import { useAuth } from '../../../../hooks/auth'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { User } from './types'
import { Structure } from '../Structure/types'
import { Role } from '../Roles/types'
import './style.css'

type ApiResponse = {
    message: User[]
}

export const loadUsers = async (): Promise<User[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organization/users',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .then((response: ApiResponse) => response.message)
        .catch((e) => {
            console.error(e)
            return []
        })
}

const loadStructure = async (): Promise<Structure[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organization_struct',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization_struct')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const loadRole = async (): Promise<Role> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organizations/roles/',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const OrgUsersPage: React.FC = () => {
    const { user } = useAuth()
    const usersLoad = useLoaderData() as User[]
    const [usersData, setUsersData] = useState<User[]>(usersLoad)
    const [userUpdate, setUserUpdate] = useState<User | null>(null)

    const navigate = useNavigate()

    const [structData, setStructData] = useState<Structure[]>([])
    const [roleData, setRoleData] = useState<Role[]>([])

    useEffect(() => {
        const loadData = async () => {
            const data = await loadStructure()
            setStructData(data)

            const dataRole = (await loadRole()) as unknown as { result: Role[] }
            const roleLoad = dataRole.result || [] // Извлекаем массив из `result`
            setRoleData(roleLoad)
        }
        loadData()
    }, [])

    const getNameStruct = (struct_guid: string) => {
        const nameSctruct = structData.find((s) => s.guid === struct_guid)
        return nameSctruct?.struct_short_name || '-'
    }

    const getNameRole = (role_guid: string) => {
        const nameRole = roleData.find((r) => r.guid === role_guid)
        return nameRole?.name || '-'
    }

    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Реестр пользователей
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-[1260px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col w-full min-w-fit">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t-lg">
                                <Add color={'#FFFFFF'} />
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-2">
                                <div className="flex w-10 items-center justify-center text-center">
                                    №
                                </div>
                                <div className="flex w-10 items-center justify-center text-center"></div>
                                <div className="flex flex-1 items-center justify-center text-center">
                                    Фамилия Имя Отчество
                                </div>
                                <div className="flex flex-1 items-center justify-center text-center">
                                    Должность
                                </div>
                                <div className="flex w-40 items-center justify-center text-center">
                                    Подразделение
                                </div>
                                <div className="flex w-40 items-center justify-center text-center">
                                    Email
                                </div>
                                <div className="flex w-40 items-center justify-center text-center">
                                    Роль
                                </div>
                                <div className="flex w-32 items-center justify-center text-center">
                                    Статус
                                </div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 h-dvh bg-[#1D1C1E]">
                                {/* Вывод пользователей */}

                                {usersData.map((u, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-row cursor-pointer gap-1 mx-1 px-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded items-center"
                                        onClick={() =>
                                            navigate(
                                                `/hub/org/users/${u.guid}/edit`
                                            )
                                        }
                                    >
                                        <div className="flex w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            {index + 1}
                                        </div>
                                        <div className="flex w-10 items-center justify-start text-center">
                                            <div className="text-white bg-[#B04E24] w-7 h-7 rounded-full mx-auto my-auto content-center">
                                                {u.last_name.slice(0, 1) +
                                                    u.first_name.slice(0, 1)}
                                            </div>
                                        </div>
                                        <div className="flex flex-1 items-center justify-start text-left px-2 py-2">
                                            {u.last_name +
                                                ' ' +
                                                u.first_name +
                                                ' ' +
                                                u.patronymic}
                                        </div>
                                        <div className="flex flex-1 items-center justify-center px-2 text-center">
                                            {u.position}
                                        </div>
                                        <div className="flex w-40 items-center justify-center px-2 text-center">
                                            {getNameStruct(
                                                u.struct_guid
                                                    ? u.struct_guid
                                                    : '-'
                                            )}
                                        </div>
                                        <div className="flex w-40 items-center justify-center px-2 text-center">
                                            {u.email}
                                        </div>
                                        <div className="flex w-40 items-center justify-center px-2 text-center">
                                            {getNameRole(
                                                u.role_guid ? u.role_guid : '-'
                                            )}
                                        </div>
                                        <div className="flex w-32 items-center justify-center px-2 rounded gap-1">
                                            {u.is_active ? (
                                                <div className="flex flex-row items-center justify-center gap-1">
                                                    <div className="flex w-5 h-5 rounded-full items-center justify-center text-white bg-[#187A33]">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="check"
                                                            className="h-4 w-4"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                    Активный
                                                </div>
                                            ) : (
                                                <div className="flex flex-row items-center justify-center gap-1">
                                                    <div className="flex w-5 h-5 rounded-full items-center justify-center text-white bg-[#B04E24]">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="check"
                                                            className="h-4 w-4"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                    Не активный
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2">
                                <button
                                    className="rounded-full px-4 h-6 text-[12.8px] bg-[#494849] hover:bg-[#656365]"
                                    onClick={() =>
                                        navigate('/hub/org/users/new/edit')
                                    }
                                >
                                    + Создать пользователя
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgUsersPage
