import React, { FormEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { jwtDecode } from 'jwt-decode'
import isEmail from 'validator/lib/isEmail'

type ApiResponse = {
    detail: string
    access_token: string
    u_role: string | null
}

const LoginForm: React.FC = () => {
    const { login } = useAuth()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [isValid, setValid] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        setLoading(true)

        fetch(process.env.REACT_APP_API_URL + '/api/auth/v1/users/login/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    // success
                    return response.json()
                } else {
                    throw new Error('Неверный почтовый адрес или пароль')
                }
            })
            .then((result: ApiResponse) => {
                login({
                    token: result.access_token,
                    object: jwtDecode(result.access_token),
                    u_role: null,
                })
            })
            .catch((serverError) => {
                setPassword('')
                setError(serverError.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setValid(isEmail(email) && password.length > 3)
    }, [email, password])

    return (
        <>
            <h5 className="mb-4 text-[18px] text-center text-white">
                Добро пожаловать!
            </h5>
            <form method="post" onSubmit={handleSubmit}>
                <fieldset
                    disabled={isLoading}
                    className="flex flex-col w-full items-center justify-center gap-3"
                >
                    <div className="flex flex-col w-full gap-1">
                        <label className="flex flex-row w-full text-[12.8px] text-[#8A8A8A] text-left">
                            Адрес электронной почты
                        </label>
                        <input
                            name="email"
                            type="email"
                            className="w-full h-7 px-2 rounded-md border-0 text-[14px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff]"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                            placeholder="E-mail"
                        />
                    </div>
                    <div className="flex flex-col w-full gap-1">
                        <label className="flex flex-row w-full text-[12.8px] text-[#8A8A8A] text-left">
                            Пароль
                        </label>
                        <input
                            name="password"
                            type="password"
                            className="w-full h-7 px-2 rounded-md border-0 text-[14px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff]"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required={true}
                            placeholder="Указать"
                        />
                        <p className="flex flex-row w-full text-left">
                            <Link
                                to={`restore`}
                                className="text-[12.8px] text-sky-600 hover:text-sky-400"
                            >
                                Забыли пароль?
                            </Link>
                        </p>
                    </div>
                    <button
                        className="flex w-full h-7 rounded-[10px] shadow-md text-[12.8px] text-white disabled:text-[#8A8A8A] disabled:bg-[#3D3B3F]  bg-[#187A33] hover:bg-[#2d934c] items-center justify-center"
                        disabled={!isValid}
                    >
                        Поехали!
                    </button>
                    <p className="w-full text-left text-[11px] text-[#8A8A8A]">
                        Нажимая «Поехали!», вы соглашаетесь на обработку
                        персональных данных, принимаете условия{' '}
                        <a
                            className="text-sky-600 hover:text-sky-400"
                            href="#/info/agreement"
                            target="_blank"
                        >
                            Политики конфиденциальности
                        </a>{' '}
                        ООО «Технологии Закупок»
                    </p>
                </fieldset>
            </form>
            {/* <p className="mb-0 text-zinc-500 text-sm">
                Организация отсутствует в системе?{' '}
                <Link to={`search`} className="text-sky-600 hover:text-sky-400">
                    Зарегистрировать
                </Link>
            </p> */}
            {error && (
                <p className="mb-0 mt-2 text-sm text-red-600" role="alert">
                    {error}
                </p>
            )}
        </>
    )
}

export default LoginForm
