import { jwtDecode } from 'jwt-decode'

export type User = {
    token: string
    object: any
    u_role: string | null
}

interface DecodedJwt {
    user_id: string
    org_id: string
    struct_id: string
    role_id: string
    login: string
    sub: string
    user_session: string
    exp: number // Время истечения токена
}

const shouldUpdateToken = (): boolean => {
    const refreshTime = localStorage.getItem('refresh_time')
    if (refreshTime == null) {
        return true
    }
    // more than a day
    return Date.now() - Number(refreshTime) > 86_400_000
}

export const getUserData = async (): Promise<User | null> => {
    console.log('Checking token...')

    try {
        // Проверяем валидность токена
        const checkTokenResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/auth/v1/check_jwt/`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )

        console.log('Token check result:', checkTokenResponse.ok)

        // Если токен недействителен, сразу возвращаем null
        if (!checkTokenResponse.ok) {
            return null
        }

        // Если токен валиден и его нужно обновить
        if (checkTokenResponse.ok && shouldUpdateToken()) {
            console.log('Updating token...')
            const refreshTokenResponse = await fetch(
                `${process.env.REACT_APP_API_URL}/api/auth/v1/users/token/refresh/`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (!refreshTokenResponse.ok) {
                throw new Error('Cannot update token')
            }

            const refreshTokenData = await refreshTokenResponse.json()
            console.log('Token updated')

            // Сохраняем обновленные данные токена
            localStorage.setItem('refresh_time', Date.now().toString())
            localStorage.setItem('access_token', refreshTokenData.access_token)
        }

        const accessToken = localStorage.getItem('access_token')

        if (!accessToken) {
            return null
        }

        const decodedJwt = jwtDecode<DecodedJwt>(accessToken)

        // Получаем имя роли
        const roleResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/backend/v1/organizations/roles_by_guid/${decodedJwt.role_id}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!roleResponse.ok) {
            throw new Error('Role not found')
        }

        const roleData = await roleResponse.json()
        const roleName = roleData.result[0]?.name || null

        // console.log('Role name:', roleName)

        // Возвращаем данные пользователя
        return {
            token: accessToken,
            object: decodedJwt,
            u_role: roleName,
        }
    } catch (error) {
        return null // Возвращаем null в случае ошибки
    }
}
