import React from 'react'

const MenuTextItem: React.FC<{
    text: string
    isActive: boolean
    onClick: () => void
}> = ({ text, isActive, onClick }) => {
    return (
        <li>
            <span
                className={
                    'flex w-12 h-12 rounded-lg items-center p-1 cursor-pointer hover:bg-[#464447]' +
                    (isActive ? ' bg-[#464447]' : '')
                }
                onClick={onClick}
            >
                <div
                    className={`flex w-full aspect-square rounded-full bg-[#262427] items-center justify-center text-[14px] font-semibold ${isActive ? ' text-white' : 'text-[#8A8A8A]'}`}
                >
                    {text}
                </div>
            </span>
        </li>
    )
}

export default MenuTextItem
