import React from 'react'
import { Outlet } from 'react-router-dom'

const Info: React.FC = () => {
    return (
        <div className="h-full flex flex-row">
            <main className="flex flex-1 flex-col">
                <Outlet />
            </main>
        </div>
    )
}

export default Info
