import React from 'react'

type Props = {
    role: string
    stage: string
    isSelect: boolean
    isSendPlan: boolean
    isUpdatingFromEis: boolean
    actions: Actions
    // status: string
    // multiSelect: boolean
}

type Actions = {
    add: () => void
    ready_to_sent: () => void
    return_to_edit: () => void
    ready_to_eis: () => void
    return_to_ready: () => void
    sent_to_eis: () => void
    update: () => void
    copy: () => void
    copy_draft: () => void
    delete: () => void
    import_from_file: () => void
    print: () => void
}

type ButtonConfig = {
    id: string
    label: string
    onClick: () => void
    visibleCondition: boolean // Функция, проверяющая видимость
    className?: string // Стили для кнопки
    iconPathD: string
    iconFill: string
    disabled: boolean
    isLoading: boolean
}

const TableStageToolbarDownButtons: React.FC<Props> = ({
    role,
    stage,
    isSelect,
    isSendPlan,
    isUpdatingFromEis,
    actions,
    // status,
    // multiSelect,
}) => {
    const isAdmin = role === 'admin'
    const isSpecialist = role === 'specialist'
    const isCurator = role === 'curator'
    const isInitiator = role === 'initiator'

    const isEditStage = stage === 'Редактирование'
    const isRaedyStage = stage === 'Подготовка к размещению'
    const isSentToEisStage = stage === 'Отправка в ЕИС'
    const isPurchasePlanStage = stage === 'План закупки в ЕИС'

    // const isEditStatus = status === 'Черновик'
    // const isReadyStatus = status === 'Готово'
    // const isStandStatus = status === 'Подготовка'
    // const isReadyToEisStatus = status === 'Готово к отправке'
    // const isSentToEisStatus = status === 'Отправлено в ЕИС'
    // const isPublishedStatus = status === 'Размещена'

    const buttons: ButtonConfig[] = [
        {
            id: 'add',
            label: 'Создать',
            onClick: actions.add,
            visibleCondition: isEditStage || isPurchasePlanStage,
            className:
                'flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#187A33] hover:bg-[#1D9A40]',
            iconFill: `#FFFFFF`,
            iconPathD:
                'M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'ready_to_sent',
            label: 'Направить на подготовку к размещению',
            onClick: actions.ready_to_sent,
            visibleCondition:
                (isAdmin || isSpecialist || isCurator) && isEditStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs ${isSelect ? 'bg-[#187A33] text-white hover:bg-[#1D9A40]' : 'bg-[#494849] text-[#FFFFFF44]'}`,
            iconFill: `${isSelect ? '#FFFFFF' : '#FFFFFF44'}`,
            iconPathD:
                'M17.71,11.29l-4-4a1,1,0,1,0-1.42,1.42L14.59,11H3a1,1,0,0,0,0,2H14.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76A1,1,0,0,0,17.71,11.29ZM21,4a1,1,0,0,0-1,1V19a1,1,0,0,0,2,0V5A1,1,0,0,0,21,4Z',
            disabled: !isSelect,
            isLoading: false,
        },
        {
            id: 'return_to_edit',
            label: 'Вернуть',
            onClick: actions.return_to_edit,
            visibleCondition: (isAdmin || isSpecialist) && isRaedyStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] ${isSelect ? 'text-white hover:bg-[#656365]' : 'text-[#FFFFFF44]'}`,
            iconFill: `${isSelect ? '#FFFFFF' : '#FFFFFF44'}`,
            iconPathD:
                'M21,11H9.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H21a1,1,0,0,0,0-2ZM3,3A1,1,0,0,0,2,4V20a1,1,0,0,0,2,0V4A1,1,0,0,0,3,3Z',
            disabled: !isSelect,
            isLoading: false,
        },
        {
            id: 'ready_to_eis',
            label: 'Подготовить к отправке в ЕИС',
            onClick: actions.ready_to_eis,
            visibleCondition: (isAdmin || isSpecialist) && isRaedyStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs ${isSelect ? 'bg-[#187A33] text-white hover:bg-[#1D9A40]' : 'bg-[#494849] text-[#FFFFFF44]'}`,
            iconFill: `${isSelect ? '#FFFFFF' : '#FFFFFF44'}`,
            iconPathD:
                'M17.71,11.29l-4-4a1,1,0,1,0-1.42,1.42L14.59,11H3a1,1,0,0,0,0,2H14.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76A1,1,0,0,0,17.71,11.29ZM21,4a1,1,0,0,0-1,1V19a1,1,0,0,0,2,0V5A1,1,0,0,0,21,4Z',
            disabled: !isSelect,
            isLoading: false,
        },
        {
            id: 'return_to_ready',
            label: 'Вернуть',
            onClick: actions.return_to_ready,
            visibleCondition: (isAdmin || isSpecialist) && isSentToEisStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] ${isSendPlan ? 'text-[#FFFFFF44]' : isSelect ? 'text-white hover:bg-[#656365]' : 'text-[#FFFFFF44] bg-[#494849]'}`,
            iconFill: `${isSendPlan ? '#FFFFFF44' : isSelect ? '#FFFFFF' : '#FFFFFF44'}`,
            iconPathD:
                'M21,11H9.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H21a1,1,0,0,0,0-2ZM3,3A1,1,0,0,0,2,4V20a1,1,0,0,0,2,0V4A1,1,0,0,0,3,3Z',
            disabled: isSendPlan,
            isLoading: false,
        },
        {
            id: 'sent_to_eis',
            label: 'Отправить в ЕИС',
            onClick: actions.sent_to_eis,
            visibleCondition: (isAdmin || isSpecialist) && isSentToEisStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs ${!isSendPlan ? 'bg-[#187A33] text-white hover:bg-[#1D9A40]' : 'bg-[#494849] text-[#FFFFFF44]'}`,
            iconFill: `${!isSendPlan ? '#FFFFFF' : '#FFFFFF44'}`,
            iconPathD:
                'M20.34,9.32l-14-7a3,3,0,0,0-4.08,3.9l2.4,5.37h0a1.06,1.06,0,0,1,0,.82l-2.4,5.37A3,3,0,0,0,5,22a3.14,3.14,0,0,0,1.35-.32l14-7a3,3,0,0,0,0-5.36Zm-.89,3.57-14,7a1,1,0,0,1-1.35-1.3l2.39-5.37A2,2,0,0,0,6.57,13h6.89a1,1,0,0,0,0-2H6.57a2,2,0,0,0-.08-.22L4.1,5.41a1,1,0,0,1,1.35-1.3l14,7a1,1,0,0,1,0,1.78Z',
            disabled: isSendPlan,
            isLoading: false,
        },
        {
            id: 'update',
            label: 'Обновить план из ЕИС',
            onClick: actions.update,
            visibleCondition: (isAdmin || isSpecialist) && isPurchasePlanStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#187A33] hover:bg-[#1D9A40]`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z',
            disabled: false,
            isLoading: isUpdatingFromEis,
        },
        {
            id: 'copy',
            label: 'Копировать',
            onClick: actions.copy,
            visibleCondition: isPurchasePlanStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] ${isSelect ? 'text-white hover:bg-[#656365]' : 'text-[#FFFFFF44]'}`,
            iconFill: `${isSelect ? '#FFFFFF' : '#FFFFFF44'}`,
            iconPathD:
                'M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z',
            disabled: !isSelect,
            isLoading: false,
        },
        {
            id: 'copy_draft',
            label: 'Копировать',
            onClick: actions.copy_draft,
            visibleCondition: !isPurchasePlanStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] ${isSelect ? 'text-white hover:bg-[#656365]' : 'text-[#FFFFFF44]'}`,
            iconFill: `${isSelect ? '#FFFFFF' : '#FFFFFF44'}`,
            iconPathD:
                'M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z',
            disabled: !isSelect,
            isLoading: false,
        },
        {
            id: 'delete',
            label: 'Удалить',
            onClick: actions.delete,
            visibleCondition: isEditStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] ${isSelect ? 'text-white hover:bg-[#701F1F]' : 'text-[#FFFFFF44]'}`,
            iconFill: `${isSelect ? '#FFFFFF' : '#FFFFFF44'}`,
            iconPathD:
                'M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z',
            disabled: !isSelect,
            isLoading: false,
        },
        {
            id: 'import_from_file',
            label: 'Загрузить из файла Excel',
            onClick: actions.import_from_file,
            visibleCondition: isEditStage,
            className:
                'flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365]',
            iconFill: `#FFFFFF`,
            iconPathD:
                'M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Z',
            disabled: false,
            isLoading: false,
        },
    ]

    return (
        <>
            {buttons
                .filter((button) => button.visibleCondition) // Оставляем только видимые кнопки
                .map((button, index) => (
                    <button
                        key={index}
                        className={button.className}
                        onClick={button.onClick}
                        title={button.label}
                        disabled={button.disabled}
                    >
                        {button.isLoading ? (
                            <svg
                                className="animate-spin h-4 w-4 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                            </svg>
                        ) : (
                            <svg
                                className="w-3 h-3"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 22 22"
                                id={button.id}
                            >
                                <path
                                    fill={button.iconFill}
                                    d={button.iconPathD}
                                ></path>
                            </svg>
                        )}
                        {button.label}
                    </button>
                ))}
        </>
    )
}

export default TableStageToolbarDownButtons
