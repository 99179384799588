import React, { useEffect, useState } from 'react'
import Section from '../../../../../../components/Section'
import { Structure } from '../../../../Org/Structure/types'
import { ListFxd, ListEp } from '../../../../Org/Lists/types'

type Props = {
    planGuid: string
    posGuid: string
    posStatus: string
    structShortName: string | null
    structShortGuid: string | null
    onStructChange: (value: { structName: string; structGuid: string }) => void
    expensItemsCode: string | null
    onExpensItemsChange: (value: string) => void
    typeObjectPurchasePosition: string | null
    onTypeObjectPurchasePositionChange: (value: string) => void
    proposedMethodName: string | null
    onProposedMethodChange: (value: string) => void
    basisSingleSupplier: string | null
    onBasisSingleSupplierChange: (value: string) => void
    annotationPurchase: string | null
    onAnnotationPurchaseChange: (value: string) => void
}

const PurchaseBasicInfo: React.FC<Props> = (props) => {
    const [structureList, setStructureList] = useState<Structure[]>([])
    const [fxdList, setFxdList] = useState<ListFxd[]>([])
    const [singleSupplierPurchaseList, setSingleSupplierPurchaseList] =
        useState<ListEp[]>([])

    const updatePosition = async (updatedField: Record<string, any>) => {
        if (props.posStatus === 'P') {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        '/api/backend/v1/plan_positions/' +
                        encodeURIComponent(props.posGuid) +
                        '?plan_guid=' +
                        encodeURIComponent(props.planGuid),
                    {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(updatedField),
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка обновления данных в базе')
                }
            } catch (error) {
                console.error(error)
            }
        } else {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        '/api/backend/v1/plan_position_draft/?plan_guid=' +
                        encodeURIComponent(props.planGuid) +
                        '&guid=' +
                        encodeURIComponent(props.posGuid),
                    {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(updatedField),
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка обновления данных в базе')
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    const handleStructChange = (value: string) => {
        const selectedStruct = structureList.find(
            (struct) => struct.guid === value
        )
        if (selectedStruct) {
            props.onStructChange({
                structGuid: selectedStruct.guid,
                structName: selectedStruct.struct_name,
            })
            updatePosition({
                struct_guid: selectedStruct.guid,
                struct_name: selectedStruct.struct_name,
            })
            console.log(selectedStruct.guid)
            console.log(selectedStruct.struct_name)
        }
    }

    const handleExpensItemsChange = (value: string) => {
        props.onExpensItemsChange(value)
        updatePosition({ expense_items_name: value })
    }

    const handleTypeObjectChange = (value: string) => {
        props.onTypeObjectPurchasePositionChange(value)
        updatePosition({ type_object_purchase_position: value })
    }

    const handleProposedMethodChange = (value: string) => {
        props.onProposedMethodChange(value)
        updatePosition({ proposed_method_name: value })
    }

    const handleBasisSingleSupplierChange = (value: string) => {
        props.onBasisSingleSupplierChange(value)
        updatePosition({ single_purchase_reason_name: value })
    }

    const handleAnnotationChange = (value: string) => {
        props.onAnnotationPurchaseChange(value)
        updatePosition({ annotation: value })
    }

    const getStructureList = async (): Promise<Structure[]> => {
        return fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/organization_struct',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка запроса на способы закупок')
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getFxdList = async (): Promise<ListFxd[]> => {
        return fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/list_expense_items',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка запроса на способы закупок')
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const getSingleSupplierPurchase = async (): Promise<ListEp[]> => {
        return fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/list_single_supplier_purchase',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка запроса на способы закупок')
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    useEffect(() => {
        const fetchStructure = async () => {
            const structs = await getStructureList()
            if (structs) {
                setStructureList(structs) // Обновляем список структур
            }
            const fxds = await getFxdList()
            if (fxds) {
                setFxdList(fxds) // Обновляем список фхд
            }
            const single = await getSingleSupplierPurchase()
            if (single) {
                setSingleSupplierPurchaseList(single) // Обновляем список ед. поставщик
            }
        }
        fetchStructure()
    }, [])

    return (
        <Section title="Общая информация">
            <div className="flex flex-col w-[1220px]">
                <div className="flex flex-row w-full gap-x-5">
                    <div className="flex flex-col flex-1">
                        <span className="mt-2 mb-1 text-sm text-zinc-500">
                            Подразделение
                        </span>
                        <select
                            className="form-select  focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                            value={props?.structShortGuid || ''}
                            onChange={(e) => handleStructChange(e.target.value)}
                        >
                            <option disabled value="">
                                Выбрать
                            </option>
                            {structureList?.length > 0 ? (
                                structureList.map((struct, index) => (
                                    <option key={index} value={struct.guid}>
                                        {struct.struct_name}
                                    </option>
                                ))
                            ) : (
                                <option disabled>Данные отсутствуют</option>
                            )}
                        </select>
                    </div>
                    {/* <div className="flex flex-col flex-1">
                        <span className="mt-2 mb-1 text-sm text-zinc-500">
                            Статья расходов
                        </span>
                        <select
                            className="form-select  focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                            value={props?.expensItemsCode || ''}
                            onChange={(e) =>
                                handleExpensItemsChange(e.target.value)
                            }
                        >
                            <option disabled value="">
                                Выбрать
                            </option>
                            {fxdList?.length > 0 ? (
                                fxdList.map((fxd, index) => (
                                    <option
                                        key={index}
                                        value={fxd.expense_items_code}
                                    >
                                        {fxd.expense_items_code +
                                            '. ' +
                                            fxd.expense_items_name}
                                    </option>
                                ))
                            ) : (
                                <option disabled>Данные отсутствуют</option>
                            )}
                        </select>
                    </div> */}

                    <div className="flex flex-col flex-1">
                        <span className="mt-2 mb-1 text-sm text-zinc-500">
                            Тип объекта закупки
                        </span>
                        <select
                            className="form-select  focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                            value={props?.typeObjectPurchasePosition || ''}
                            onChange={(e) =>
                                handleTypeObjectChange(e.target.value)
                            }
                        >
                            <option disabled value="">
                                Выбрать
                            </option>
                            <option value="G">Товар</option>
                            <option value="W">Работа</option>
                            <option value="S">Услуга</option>
                        </select>
                    </div>
                </div>

                <div className="flex flex-col flex-1">
                    <span className="mt-2 mb-1 text-sm text-zinc-500">
                        Предлагаемый способ закупки
                    </span>
                    <select
                        className="form-select  focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                        value={props?.proposedMethodName || ''}
                        onChange={(e) =>
                            handleProposedMethodChange(e.target.value)
                        }
                    >
                        <option disabled value="">
                            Выбрать способ закупки
                        </option>
                        <option value="Закупка у единственного поставщика (исполнителя, подрядчика)">
                            Закупка у единственного поставщика (исполнителя,
                            подрядчика)
                        </option>
                        <option value="Способ закупки в соответствии с планом закупки, либо документацией о закупке">
                            Способ закупки в соответствии с планом закупки, либо
                            документацией о закупке
                        </option>
                    </select>
                </div>

                {props?.proposedMethodName?.includes('единствен') && (
                    <>
                        <div className="mt-2 form-label">
                            Основание для проведения закупки у единственного
                            поставщика (исполнителя, подрядчика)
                        </div>
                        <select
                            className="w-full form-select text-sm text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis"
                            value={props.basisSingleSupplier || ''}
                            onChange={(e) =>
                                handleBasisSingleSupplierChange(e.target.value)
                            }
                        >
                            <option
                                disabled
                                value=""
                                className="text-[#8A8A8A]"
                            >
                                Выбрать способ закупки
                            </option>
                            {singleSupplierPurchaseList?.length > 0 ? (
                                singleSupplierPurchaseList.map(
                                    (smethod, index) => (
                                        <option
                                            key={index}
                                            value={
                                                smethod.single_supplier_purchase_name
                                            }
                                        >
                                            {smethod.single_supplier_purchase_paragraph +
                                                '. ' +
                                                smethod.single_supplier_purchase_name}
                                        </option>
                                    )
                                )
                            ) : (
                                <option disabled>Данные отсутствуют</option>
                            )}
                        </select>
                    </>
                )}

                <label className="mt-2 mb-1 text-sm text-zinc-500">
                    Примечание
                </label>
                <textarea
                    className="form-textarea "
                    maxLength={2000}
                    rows={3}
                    value={props?.annotationPurchase || ''}
                    onChange={(e) => handleAnnotationChange(e.target.value)}
                />
            </div>
        </Section>
    )
}

export default PurchaseBasicInfo
