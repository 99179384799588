import React, { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'

type ApiResponse = {
    detail: string
    access_token: string
}

const RestoreForm: React.FC = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [error, setError] = useState<string | null>(null)
    const [isValid, setValid] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        setLoading(true)

        fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/user/request_reset_password`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    base_url: `${process.env.REACT_APP_API_URL}/#/auth`,
                }),
            }
        )
            .then((response) => {
                if (response.ok) {
                    // success
                    setSuccess(true)
                    console.log('User reset successfully')
                } else {
                    throw new Error('Произошла ошибка. Попробуйте еще раз.')
                }
            })
            .catch((serverError) => {
                setError(serverError.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setValid(isEmail(email))
    }, [email])

    return (
        <>
            <h5 className="flex flex-row mb-4 text-[18px] text-center text-white items-center justify-start gap-3">
                {success ? (
                    <>
                        <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="times-circle"
                        >
                            <path
                                fill="#187A33"
                                d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                            ></path>
                        </svg>
                        Проверьте почту
                    </>
                ) : (
                    <>
                        <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="times-circle"
                        >
                            <path
                                fill="#FFBF00"
                                d="M12,14a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,14Zm0-1.5a1,1,0,0,0,1-1v-3a1,1,0,0,0-2,0v3A1,1,0,0,0,12,12.5ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"
                            ></path>
                        </svg>
                        Восстановление пароля
                    </>
                )}
            </h5>
            <form method="post" onSubmit={handleSubmit}>
                {success ? (
                    <div className="flex flex-col w-full gap-3 items-center justify-end">
                        <div className="flex flex-row w-full gap-2">
                            <label className="text-[14px] text-[#ffffff]">
                                Ссылка для восстановления пароля отправлена на
                                почту{' '}
                                <span style={{ textDecoration: 'underline' }}>
                                    {email}
                                </span>
                                , пожалуйста, проверьте папки «Входящие» или
                                «Спам».
                            </label>
                        </div>
                        <div className="flex flex-row w-full items-center justify-end gap-2">
                            <button
                                className="w-fit h-7 min-w-[120px] rounded-[10px] shadow-md text-[12.8px] text-white disabled:text-[#8A8A8A] bg-[#1D9A40] disabled:bg-[#1D9A40]"
                                onClick={() => navigate(`/auth`)}
                            >
                                Ок
                            </button>
                        </div>
                    </div>
                ) : (
                    <fieldset disabled={isLoading}>
                        <div className="flex flex-col w-full gap-3 items-center justify-end">
                            <div className="flex flex-row w-full gap-2">
                                <label className="text-[12.8px] text-[#ffffff]">
                                    Укажите почту, с которой вы
                                    зарегистрировались и мы вышлем туда ссылку
                                    для восстановления пароля.
                                </label>
                            </div>
                            <div className="flex flex-col gap-1 w-full items-center justify-start">
                                <label className="flex flex-row w-full text-[12.8px] text-[#8A8A8A] text-left">
                                    Адрес электронной почты
                                </label>
                                <input
                                    name="email"
                                    type="email"
                                    className="w-full h-7 px-2 rounded-md border-0 text-[14px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff]"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required={true}
                                    placeholder="E-mail"
                                />
                            </div>
                            <div className="flex flex-row w-full items-center justify-end gap-2">
                                <button
                                    className="w-fit h-7 min-w-[120px] rounded-[10px] shadow-md text-[12.8px] text-white disabled:text-[#8A8A8A] bg-[#494849] hover:bg-[#656365] items-center justify-start"
                                    onClick={() => navigate(`/auth`)}
                                >
                                    Отмена
                                </button>
                                <button
                                    className="w-fit h-7 min-w-[120px] rounded-[10px] shadow-md text-[12.8px] text-white disabled:text-[#8A8A8A] bg-[#187A33] disabled:bg-[#494849] hover:bg-[#1D9A40] items-center justify-start"
                                    disabled={!isValid}
                                >
                                    Восстановить
                                </button>
                            </div>
                        </div>
                    </fieldset>
                )}
            </form>
            {error && (
                <p className="mt-2 mb-0 text-sm text-red-600" role="alert">
                    {error}
                </p>
            )}
        </>
    )
}

export default RestoreForm
