import React from 'react'
import { Navigate, Outlet, Link } from 'react-router-dom'
import logo from '../../images/logo.png'

const Agreement: React.FC = () => {
    return (
        <div className="h-full flex flex-col">
            <header className="p-5">
                <Link
                    to="/"
                    className="flex flex-row justify-center items-center"
                    style={{ textDecoration: 'none' }}
                    title="На главную страницу"
                >
                    <img
                        width={40}
                        height={40}
                        src={logo}
                        alt="Логотип"
                        className="me-2"
                    />
                    <span className="text-white uppercase leading-none">
                        ТЕХНОЛОГИИ
                        <br />
                        ЗАКУПОК
                    </span>
                </Link>
            </header>
            <main className="flex grow items-start justify-center p-4">
                <div className="flex flex-col w-[1000px] h-fit p-10 rounded-lg bg-neutral-800 text-white gap-3">
                    <div className="flex flex-row w-full items-center justify-end font-semibold">
                        Редакция от 13.01.2024 г.
                    </div>
                    <div className="flex flex-row w-full items-center justify-center font-semibold">
                        Политика конфиденциальности ООО «Технологии Закупок»
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            1. Общие положения
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                1.1. Предметом регулирования настоящей Политики
                                конфиденциальности (далее – «Политика») являются
                                отношения между Обществом с ограниченной
                                ответственностью «Технологии закупок» (ОГРН
                                1211400010491, 677000, Российская Федерация,
                                Республика Саха (Якутия), г. Якутск, пр. Ленина,
                                1, офис 625), далее «Компания», которое является
                                правообладателем сайтов, сервисов, программ
                                и/или иных продуктов (далее – «Сервисы», а в
                                отдельности – «Сервис»), расположенных в сети
                                Интернет по адресу https://plan223.ru / (далее –
                                «Сайт»), а также на иных администрируемых
                                Компанией доменах, и Вами (физическим лицом
                                пользователем), далее «Пользователь», по поводу
                                обработки информации о Пользователе, включая
                                персональные данные, в процессе использования
                                таким лицом Сервисов Компании (далее –
                                «Информация»). Настоящая Политика размещена по
                                адресу: https://plan223.ru/info/agreement
                            </p>
                            <p>
                                {' '}
                                1.2. При регистрации, каждом доступе и/или
                                фактическом использовании любого из Сервисов,
                                Пользователь соглашается с условиями настоящей
                                Политики и дает согласие на обработку Компанией
                                своих персональных и иных данных, то есть
                                совершение, в том числе, следующих действий:
                                обработку (включая сбор, систематизацию,
                                накопление, хранение, уточнение (обновление,
                                изменение), использование, обезличивание,
                                блокирование, уничтожение персональных данных),
                                а также на передачу такой информации третьим
                                лицам, в случаях, установленных
                                законодательством.
                            </p>
                            <p>
                                {' '}
                                1.3. Настоящая Политика конфиденциальности
                                применяется при регистрации, каждом доступе
                                и/или фактическом использовании любого Сервиса
                                Компании, как существующего на данный момент,
                                так и того, который появится в будущем, условия
                                использования которого ссылаются на
                                Пользовательское соглашение.
                            </p>
                            <p>
                                {' '}
                                1.4. Согласие на обработку Информации о
                                Пользователе, в том числе персональных данных,
                                выражается в форме совершения Пользователем
                                определенных действий в том числе:
                            </p>
                            <p> 1) регистрация на сайте www.plan223.ru.</p>
                            <p>
                                {' '}
                                2) продолжения использования Сервисов Компании;
                            </p>
                            <p>
                                {' '}
                                3) принятия условий договора-оферты,
                                лицензионного договора, правил пользования
                                Сервисов Компании;
                            </p>
                            <p>
                                {' '}
                                4) проставления отметок, чек-боксов, заполнения
                                соответствующих полей в формах, бланках;
                            </p>
                            <p>
                                {' '}
                                5) поддержания электронной переписки, в которой
                                говорится об обработке;
                            </p>
                            <p>
                                {' '}
                                6) устного озвучивания согласия как
                                непосредственно, так и с использованием средств
                                голосовой связи;
                            </p>
                            <p>
                                {' '}
                                7) иных действий, совершаемых субъектом, по
                                которым можно судить о его волеизъявлении.
                            </p>
                            <p>
                                {' '}
                                1.5. Перед тем как начать использование Сервисов
                                Компании Пользователь обязан ознакомиться с
                                настоящей Политикой.
                            </p>
                            <p>
                                {' '}
                                1.6. В случаях обработки персональных данных,
                                полученных не от Пользователя напрямую, а от
                                других лиц на основании договора или поручения
                                на обработку, обязанность получения согласия
                                Пользователя может быть возложена на лицо, от
                                которого получены персональные данные.
                            </p>
                            <p>
                                {' '}
                                1.7. В случае отказа Пользователя от
                                предоставления в необходимом и достаточном
                                объеме его персональных данных, Оператор не
                                сможет осуществить необходимые действия для
                                достижения соответствующих обработке целей. В
                                таком случае может быть не завершена регистрация
                                Пользователя в программном продукте, услуги по
                                договору могут быть не оказаны, работы не
                                выполнены, товары не поставлены.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            2. Основания обработки персональных данных
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                {' '}
                                2.1. Обработка персональных данных
                                осуществляется Компанией на законной и
                                справедливой основе, основными правовыми
                                основаниями для обработки являются:
                            </p>
                            <p> 2.1.1. Конституция Российской Федерации.</p>
                            <p> 2.1.2. Трудовой кодекс Российской Федерации.</p>
                            <p>
                                {' '}
                                2.1.3. Гражданский кодекс Российской Федерации.
                            </p>
                            <p>
                                {' '}
                                2.1.4. Налоговый кодекс Российской Федерации.
                            </p>
                            <p>
                                {' '}
                                2.1.5. Федеральный закон от 06.04.2011 № 63-ФЗ
                                «Об электронной подписи».
                            </p>
                            <p>
                                {' '}
                                2.1.6. Федеральный закон от 07.07.2003 № 126-ФЗ
                                «О связи».
                            </p>
                            <p>
                                {' '}
                                2.1.7. Федеральный закон от 27.07.2006 № 149-ФЗ
                                «Об информации, информационных технологиях и о
                                защите информации».
                            </p>
                            <p>
                                {' '}
                                2.1.8. Федеральный закон от 18.07.2011 N 223-ФЗ
                                «О закупках товаров, работ, услуг отдельными
                                видами юридических лиц».
                            </p>
                            <p>
                                {' '}
                                2.1.9. Федеральный закон от 05.04.2013 N 44-ФЗ
                                «О контрактной системе в сфере закупок товаров,
                                работ, услуг для обеспечения государственных и
                                муниципальных нужд».
                            </p>
                            <p>
                                {' '}
                                2.1.10. Федеральный закон от 27.07.2006 № 152-ФЗ
                                «О персональных данных».
                            </p>
                            <p> 2.1.11. Договоры и соглашения Обществ.</p>
                            <p>
                                {' '}
                                2.1.12. Соглашение между Пользователем и
                                Компанией.
                            </p>
                            <p>
                                {' '}
                                2.1.13. Согласия субъектов персональных данных.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            3. Принципы обработки Информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                3.1. Обработка Информации о Пользователях
                                осуществляется Компанией в соответствии со
                                следующими принципами:
                            </p>
                            <p>
                                3.1.1. законности целей и способов обработки
                                Информации;
                            </p>
                            <p>3.1.2. добросовестности Компании;</p>
                            <p>
                                3.1.3. соответствия целей обработки Информации
                                целям, заранее определенным и заявленным при ее
                                сборе, а также полномочиям Компании;
                            </p>
                            <p>
                                3.1.4. соответствия объема и характера
                                обрабатываемой Информации, способов ее обработки
                                целям обработки Информации;
                            </p>
                            <p>
                                3.1.5. недопустимости объединения созданных для
                                несовместимых между собой целей баз данных,
                                содержащих Информацию Пользователей.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            4. Состав Информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                4.1. Обрабатываемая Компанией Информация,
                                включает в себя:
                            </p>
                            <p>
                                4.1.1. Учетные данные, под которым понимаются:
                            </p>
                            <p>
                                а) данные о Пользователе, предоставляемые
                                Пользователем для создания учетной записи в
                                процессе регистрации в Сервисе. Данные, которые
                                Пользователь указывает при заполнении
                                регистрационной формы: имя, фамилия, отчество,
                                дата рождения, пол, контактный телефон,
                                электронная почта (e-mail).
                            </p>
                            <p>
                                б) дополнительная информация, заполняемая
                                Пользователем при редактировании своей учетной
                                записи в процессе использования Сервиса;
                            </p>
                            <p>
                                в) данные, дополнительно предоставляемые
                                Пользователем по запросу Компании в целях
                                исполнения Компанией обязательств перед
                                Пользователем, вытекающих из соглашения о
                                предоставлении соответствующего Сервиса;
                            </p>
                            <p>
                                4.1.2. Иные данные, необходимые для
                                функционирования определенного Сервиса, а
                                именно:
                            </p>
                            <p>
                                а) данные о технических средствах (устройствах),
                                технологическом взаимодействии с Сервисом (в
                                т.ч. IP-адрес хоста, вид операционной системы
                                Пользователя, тип браузера, географическое
                                положение, поставщик услуг Интернета);
                            </p>
                            <p>
                                б) информация, автоматически получаемая при
                                доступе к Сервису с использованием cookies;
                            </p>
                            <p>
                                в) информация, полученная в результате действий
                                Пользователя в Сервисе;
                            </p>
                            <p>
                                г) информация, полученная в результате действий
                                других Пользователей в Сервисе (если применимо);
                            </p>
                            <p>
                                д) обобщенная аналитическая информация об
                                использовании интернет-сервисов.
                            </p>
                            <p>
                                4.2. Обрабатываемые Компанией Учетные и Иные
                                данные в объеме, необходимом и достаточном для
                                их отнесения в соответствии с действующим
                                законодательством Российской Федерации к
                                персональным данным, обрабатываются в Компании
                                как персональные данные на условиях настоящей
                                Политики.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            5. Цель обработки Информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                5.1. Обработка персональных данных необходима
                                для исполнения договора, стороной которого либо
                                выгодоприобретателем или поручителем по которому
                                является субъект персональных данных, а также
                                для заключения договора по инициативе субъекта
                                персональных данных или договора, по которому
                                субъект персональных данных будет являться
                                выгодоприобретателем или поручителем.
                            </p>
                            <p>
                                5.2. Компания осуществляет обработку Учетных
                                данных и Иных данных Пользователей Сервисов в
                                целях исполнения заключенных с Пользователями
                                соглашений по использованию соответствующих
                                Сервисов.
                            </p>
                            <p>
                                5.3. Компания не проверяет предоставленные
                                Пользователем Учетные данные и не может судить
                                об их достоверности, а также о том, обладает ли
                                Пользователь достаточной правоспособностью для
                                предоставления Учётных данных.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            6. Обработка электронных пользовательских данных,
                            включая cookies
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                6.1. Компания может собирать электронные
                                пользовательские данные - cookies на своих
                                Сервисах автоматически, без необходимости
                                участия пользователя и совершения им каких-либо
                                действий по отправке данных.
                            </p>
                            <p>
                                6.2. Достоверность собранных таким способом
                                электронных данных не проверяется, информация
                                обрабатываются «как есть» в том виде, как она
                                поступила с клиентского устройства.
                            </p>
                            <p>
                                6.3. Пользователям сайтов Компании могут
                                показываться всплывающие уведомления о сборе и
                                обработке данных cookies и кнопками принятия
                                условий обработки либо закрытия всплывающего
                                уведомления.
                            </p>
                            <p>
                                6.4. Такие уведомления означают, что при
                                посещении и использовании Сервисов Компании в
                                браузер на устройстве пользователя может
                                сохраняться информация (например, данные
                                cookies), позволяющая в дальнейшем
                                идентифицировать пользователя или устройство,
                                запомнить сеанс работы или сохранить некоторые
                                настройки и предпочтения пользователя,
                                специфичные для этих конкретных сайтов. Такая
                                информация после сохранения в браузер и до
                                истечения установленного срока действия или
                                удаления с устройства будет отправляться при
                                каждом последующем запросе на сайт, от имени
                                которого они были сохранены, вместе с этим
                                запросом для обработки на стороне Компании.
                            </p>
                            <p>
                                6.5. Обработка данных cookies необходима
                                Компании для корректной работы сайтов, в
                                частности, их функций, относящихся к доступу
                                зарегистрированных пользователей программных
                                продуктов, услуг, работ и ресурсов Компании,
                                персонализации пользователей, повышения
                                эффективности и удобства работы с сайтами, а
                                также иных целей, предусмотренных Политикой.
                            </p>
                            <p>
                                6.6. Кроме обработки данных cookies,
                                установленных самими сайтами Компании,
                                Пользователю могут устанавливаться cookies,
                                относящиеся к сайтам сторонних организаций,
                                например, в случаях, когда на сайтах Оператора
                                используются сторонние компоненты и программное
                                обеспечение. Обработка таких cookies
                                регулируется политиками соответствующих сайтов,
                                к которым они относятся, и может изменяться без
                                уведомления пользователей сайтов Оператора. К
                                таким случаям может относиться размещение на
                                сайтах:
                            </p>
                            <p>
                                1) счетчиков посещений, аналитических и
                                статистических сервисов, таких как
                                Яндекс.Метрика или иных сервисов для сбора
                                статистики посещаемости общедоступных страниц
                                сайтов;
                            </p>
                            <p>
                                2) виджетов вспомогательных сервисов для сбора
                                обратной связи, организации чатов и иных видов
                                коммуникаций с пользователями;
                            </p>
                            <p>
                                3) систем контекстной рекламы, баннерных и иных
                                маркетинговых сетей;
                            </p>
                            <p>
                                4) кнопок авторизации на сайтах с помощью
                                учетных записей в социальных сетях;
                            </p>
                            <p>
                                5) иных сторонних компонентов, используемых
                                Оператором на своих сайтах.
                            </p>
                            <p>
                                6.7. Принятие пользователем условий обработки
                                cookies или закрытие всплывающего уведомления в
                                соответствии с Политикой расценивается как
                                согласие на обработку данных cookies на сайтах
                                Оператора.
                            </p>
                            <p>
                                6.8. В случае если пользователь не согласен с
                                обработкой cookies, он должен принять на себя
                                риск, что в таком случае функции и возможности
                                сайта могут быть не доступны в полном объеме, а
                                затем следовать по одному из следующих
                                вариантов:
                            </p>
                            <p>
                                1) произвести самостоятельную настройку своего
                                браузера в соответствии с документацией или
                                справкой к нему таким образом, чтобы он на
                                постоянной основе не разрешал принимать и
                                отправлять данные cookies для любых сайтов либо
                                для конкретного сайта ООО «Технологии закупок»
                                или сайта стороннего компонента;
                            </p>
                            <p>
                                2) переключиться в специальный режим «инкогнито»
                                браузера для использования сайтом cookies до
                                закрытия окна браузера или до переключения
                                обратно в обычный режим;
                            </p>
                            <p>
                                3) покинуть сайт во избежание дальнейшей
                                обработки cookies.
                            </p>
                            <p>
                                6.9. Пользователь может самостоятельно через
                                встроенные в браузеры средства работы с данными
                                cookies управлять сохраненными данными, в том
                                числе, удалять или просматривать сведения об
                                установленных сайтами cookies, включая:
                            </p>
                            <p>
                                1) адреса сайтов и пути на них, куда будут
                                отправляться cookies;
                            </p>
                            <p>
                                2) названия и значения параметров, хранящихся в
                                cookies;
                            </p>
                            <p>3) сроки действия cookies.</p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            7. Сбор Информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                7.1. Сбор Учетных данных Пользователя
                                осуществляется при его регистрации в
                                соответствующем Сервисе путем заполнения
                                Пользователем регистрационной формы, а также в
                                дальнейшем при редактировании Пользователем
                                ранее предоставленной информации либо при
                                дополнении по своей инициативе Учетных данных
                                (если применимо) с помощью инструментария
                                соответствующего Сервиса.
                            </p>
                            <p>
                                7.2. Сбор Иных данных осуществляется Компанией
                                самостоятельно в процессе использования
                                Пользователем Сервиса. В ряде случаев сбор Иных
                                данных Пользователя начинается с момента
                                получения Пользователем доступа к Сервису
                                (например, при загрузке интернет-страницы или
                                запуске приложения) до момента его регистрации в
                                соответствующем Сервисе.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            8. Обработка и Передача Информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                8.1. Учетные и Иные данные Пользователей не
                                передаются каким-либо третьим лицам, за
                                исключением случаев, прямо предусмотренных
                                настоящей Политикой, а также применимым
                                законодательством.
                            </p>
                            <p>
                                8.2. Компания может передавать Учетные и Иные
                                данные Пользователей третьим лицам, с
                                соблюдением целей и при наличии оснований,
                                указанных в настоящей Политике. К таким третьим
                                лицам могут относиться:
                            </p>
                            <p>
                                8.2.1. лица, которые имеют законные основания
                                обрабатывать Учетные и Иные данные, например,
                                если передача Учетных и Иных данных таким лицам
                                осуществляется с согласия Пользователя, в том
                                числе если Учетные и Иные данные необходимы для
                                предоставления Пользователю соответствующего
                                сервиса или выполнения определенного соглашения
                                или договора, заключенного с Пользователем, в
                                частности, операторы сотовой связи и/или
                                технологические партнеры, действующие между
                                Компанией и операторами сотовой связи, во
                                исполнение Пользовательского соглашения,
                                заключенного между Компанией и Пользователем. К
                                этим лицам также относятся партнеры и
                                аффилированные лица Компании, обработка данных
                                которыми предусмотрена условиями настоящей
                                Политики;
                            </p>
                            <p>
                                8.2.2. третьи лица, в отношении которых
                                произведена уступка прав или обязанностей, или
                                новация по соответствующему соглашению;
                            </p>
                            <p>
                                8.2.3. любой орган государственной власти или
                                местного самоуправления, которому Компания
                                обязана предоставлять информацию в соответствии
                                с применимым законодательством по
                                соответствующему запросу.
                            </p>
                            <p>
                                8.3. В целях предоставления качественных,
                                многофункциональных, удобных в использовании и
                                ориентированных на персональные потребности и
                                интересы Пользователей Сервисов, Компания
                                развивает, совершенствует, оптимизирует текущий
                                и внедряет новый функционал Сервисов, в том
                                числе с участием партнеров и/или аффилированных
                                лиц. Принимая во внимание вышеизложенное, а
                                также учитывая соблюдение Компанией целей
                                обработки, указанных в п. 5 настоящей Политики,
                                Пользователь соглашается и поручает Компании
                                осуществлять следующие действия:
                            </p>
                            <p>
                                8.3.1. обработку, включая сбор, запись,
                                систематизацию, накопление, хранение, уточнение
                                (обновление, изменение), сопоставление,
                                извлечение, использование, блокирование,
                                удаление/уничтожение Учетных и Иных данных
                                Пользователя;
                            </p>
                            <p>
                                8.3.2. передачу партнерам и аффилированным лицам
                                Учетных и Иных данных, и их обработку партнерами
                                и аффилированными лицами, на основании поручения
                                от Компании (указанными в настоящем пункте
                                способами), а также совместную обработку
                                партнерами и аффилированными лицами Учетных и
                                Иных данных с данными, имеющихся в распоряжении
                                партнеров и аффилированных лиц;
                            </p>
                            <p>
                                8.3.3. сбор (получения) от партнеров и/или
                                аффилированных лиц результатов обработки Учетных
                                и Иных данных совместно с данными, находящимися
                                в распоряжении партнеров и аффилированных лиц, в
                                том числе в виде целочисленных и/или текстовых
                                значений и идентификаторов.
                            </p>
                            <p>
                                8.4. Компания может способствовать деятельности
                                по исследованию рынка товаров и услуг, в том
                                числе оценке спроса и предложений, продвижению
                                товаров, анализу эффективности проведенных
                                информационных, рекламных и маркетинговых
                                кампаний путем формирования обезличенной
                                аналитической информации на основе данных об
                                использовании Сервисов.
                            </p>
                            <p>
                                8.5. Пользователь соглашается на обработку
                                собираемых Сервисом(ами) данных, в объеме,
                                перечисленном в политике конфиденциальности
                                Сервиса(ов), который(ые) использует
                                Пользователь, совместно с данными о нем,
                                полученными от партнеров Компании и обеспеченных
                                партнерами Компании законными основаниями для
                                такой обработки, в объеме, указанном в Политике
                                конфиденциальности сервиса(ов) партнера
                                Компании, который(ые) использует Пользователь, в
                                целях улучшения качества предоставляемых
                                Сервиса(ов), а именно: для удовлетворение
                                интересов Пользователя путем показа релевантной
                                интересам Пользователя информации, формирования
                                обобщенной статистической/ аналитической
                                информации на основе использования Сервиса(ов) и
                                сервиса(ов) Партнеров.
                            </p>
                            <p>
                                8.6. Не допускается извлечение и использование
                                Учетных и Иных данных Пользователей, в том числе
                                в коммерческих целях, без разрешения Компании.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            9. Хранение Информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                9.1. Учетные и Иные данные пользователей
                                хранятся на территории Российской Федерации, при
                                этом хранение осуществляется исключительно на
                                электронных носителях, а обработка - с
                                использованием автоматизированных систем, за
                                исключением случаев, когда неавтоматизированная
                                обработка Учетных и Иных данных необходима в
                                связи с исполнением требований применимого
                                законодательства.
                            </p>
                            <p>
                                9.2. Учетные и Иные данные хранятся до
                                достижения целей их обработки.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            10. Сроки хранения Информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                10. Учетные и Иные данные хранятся Компанией в
                                течение срока действия соглашения между
                                Пользователем и Компанией об использовании
                                соответствующего Сервиса, а после прекращения
                                действия такого соглашения – в течение срока,
                                необходимого и установленного действующим
                                законодательством Российской Федерации.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            11. Прекращение обработки Информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                11. При достижении целей обработки Информации
                                Компания прекращает обработку Учетных и Иных
                                данных одним из способов, предусмотренных
                                Федеральным законом «О персональных данных».
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            12. Права и обязанности Пользователей при обработке
                            их Информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>12.1. Пользователи вправе:</p>
                            <p>
                                12.1.1. получать бесплатный доступ к информации
                                о себе посредством просмотра Учетной записи
                                Пользователя в соответствующем Сервисе;
                            </p>
                            <p>
                                12.1.2. с помощью инструментария Сервиса
                                установить в отношении информации о себе
                                желаемый уровень конфиденциальности (условия
                                доступа к информации) с учетом функционала
                                соответствующего Сервиса (если применимо);
                            </p>
                            <p>
                                12.1.3. самостоятельно вносить изменения и
                                исправления в информацию о себе путем
                                редактирования информации в Учетной записи
                                Пользователя, при условии, что такие изменения и
                                исправления содержат актуальную и достоверную
                                информацию;
                            </p>
                            <p>
                                12.1.4. удалять информацию о себе путем
                                редактирования информации в Учетной записи
                                соответствующего Сервиса (если применимо); при
                                этом удаление Пользователем определенной
                                информации о себе из Учетной записи Пользователя
                                в некоторых Сервисах может привести к
                                невозможности предоставления Пользователю
                                доступа к этим Сервисам.
                            </p>
                            <p>
                                12.1.5. требовать от Компании уточнения
                                информации о Пользователе, ее блокирования или
                                уничтожения в случае, если такая информация
                                являются неполной, устаревшей, недостоверной,
                                необоснованно полученной или не является
                                необходимой для заявленной цели обработки и если
                                функционал Сервиса не позволяет Пользователю
                                самостоятельно удалить такую информацию;
                            </p>
                            <p>
                                12.1.6. на основании запроса получать от
                                Компании информацию, касающуюся обработки
                                информации о Пользователе.
                            </p>
                            <p>
                                12.1.7. отказаться от обработки персональных
                                данных путем направления Компании в порядке,
                                предусмотренном в разделе 15 настоящей Политики,
                                соответствующего запроса.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            13. Меры по защите информации
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                13.1. Компания принимает технические и
                                организационно-правовые меры в целях обеспечения
                                защиты информации о Пользователях от
                                неправомерного или случайного доступа к ним,
                                уничтожения, изменения, блокирования,
                                копирования, распространения, а также от иных
                                неправомерных действий.
                            </p>
                            <p>
                                13.2. Технические меры безопасности реализованы
                                Компанией с учетом требований применимого
                                законодательства, современного уровня техники,
                                характера обрабатываемой информации и рисков,
                                связанных с ее обработкой.
                            </p>
                            <p>
                                13.3. Информация обрабатывается преимущественно
                                автоматически без доступа к ней сотрудников
                                и/или подрядчиков Компании. В случае если такой
                                доступ предоставляется сотрудникам или
                                подрядчикам Компании, то только в объеме,
                                необходимом для выполнения такими лицами своих
                                служебных обязанностей или обязанностей по
                                договору с Компанией, при этом на таких лиц
                                возлагается обязанность по соблюдению требований
                                безопасности при осуществлении доступа к
                                Информации. Для защиты и обеспечения
                                конфиденциальности данных все
                                сотрудники/подрядчики соблюдают внутренние
                                правила и процедуры в отношении обработки
                                информации. Указанные лица также соблюдают все
                                технические и организационные меры безопасности,
                                предусмотренные применимым законодательством и
                                необходимые для защиты информации о
                                Пользователях.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            14. Ограничение ответственности Компании
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                14.1. Компания не несет ответственности за
                                разглашение и распространение информации о
                                Пользователе другими Пользователями Сервисов или
                                другими пользователями сети Интернет в случае,
                                если такие лица получили доступ к указанной
                                информации в соответствии с выбранным
                                Пользователем настройками уровня
                                конфиденциальности соответствующего Сервиса,
                                либо в случае нарушения Пользователем
                                сохранности его логина и/или пароля или иных
                                необходимых для авторизации данных.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            15. Обращения Пользователей
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                15.1. Сведения об обрабатываемых Компанией
                                данных, в том числе персональных данных
                                Пользователя, в связи с использованием им
                                соответствующего Сервиса предоставляются
                                Пользователю или его представителю при обращении
                                (запросе).
                            </p>
                            <p>
                                15.2. Запросы направляются в письменной форме по
                                адресу места нахождения Компании или в иной
                                форме, предусмотренной действующим
                                законодательством Российской Федерации.
                            </p>
                            <p>
                                15.3. Пользователь вправе отозвать согласие на
                                обработку своих персональных данных путем
                                направления Компании письменного заявления по
                                месту нахождения Компании в соответствии с
                                требованиями действующего законодательства.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-start justify-start">
                        <div className="flex flex-row w-full items-center justify-center font-semibold">
                            16. Изменения и дополнения настоящей Политики
                        </div>
                        <div className="flex flex-col w-full items-start justify-stretch indent-7 text-justify">
                            <p>
                                16.1. Настоящая Политика может быть изменена
                                Компанией с уведомлением Пользователя, в том
                                числе путем размещения новой редакции изменяемой
                                политики на Сайте. Изменения в Политике,
                                внесенные Компанией, вступают в силу в день,
                                следующий за днем её размещения на Сайте новой
                                редакции Политики. Пользователь обязуется
                                самостоятельно проверять настоящую Политику и
                                Политики отдельных Сервисов на предмет внесенных
                                изменений. Неосуществление Пользователем
                                действий по ознакомлению не может служить
                                основанием для неисполнения Пользователем своих
                                обязательств и несоблюдения Пользователем
                                ограничений, установленных настоящим
                                Соглашением.
                            </p>
                            <p>
                                16.2. Пользователь вправе отказаться от принятия
                                изменений и дополнений в настоящую Политику
                                и/или политики отдельных Сервисов, что означает
                                отказ Пользователя от использования всех и/или
                                отдельных Сервисов Компании и всех
                                предоставленных ему ранее прав.
                            </p>
                            <p>
                                16.3. Настоящая Политика регулируется и
                                толкуется в соответствии с законодательством
                                Российской Федерации. Вопросы, не
                                урегулированные настоящей Политикой, подлежат
                                разрешению в соответствии с законодательством
                                Российской Федерации.
                            </p>
                            <p>
                                16.4. Политика действует бессрочно после
                                утверждения и до ее замены новой версией.
                                Компания имеет право вносить изменения в
                                Политику без уведомления любых лиц. Политика
                                пересматривается для поддержания в актуальном
                                состоянии и актуализируется по мере
                                необходимости.
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Agreement
