import React, { useState } from 'react'
import './index.css'
import { PlanPositionStage } from '../../types'

type Props = {
    stage: PlanPositionStage
    edit: number | null
    ready: number | null
    sent: number | null
    onTabChange: (stage: PlanPositionStage) => void
    onTabClick: (selectAllFlag: boolean) => void
}

const PlanPositionsTabs: React.FC<Props> = ({
    stage,
    edit,
    ready,
    sent,
    onTabClick,
    onTabChange,
}) => {
    return (
        <div className="flex flex-row ">
            <div className="w-5"></div>
            {Object.values(PlanPositionStage).map((item, index) => {
                return (
                    <div
                        key={index}
                        className={`cursor-pointer ${
                            stage == item
                                ? 'plan-position-tab-active'
                                : 'plan-position-tab-inactive'
                        }`}
                        onClick={() => {
                            onTabChange(item)
                            onTabClick(false)
                        }}
                    >
                        <div className="flex flex-row w-full h-full items-center justify-center gap-2">
                            {item}
                            <div className="flex w-fit px-2 rounded-full bg-[#333033] text-white items-center justify-center">
                                {index == 0
                                    ? edit
                                        ? edit
                                        : ''
                                    : index == 1
                                      ? ready
                                          ? ready
                                          : ''
                                      : index == 2
                                        ? sent
                                            ? sent
                                            : ''
                                        : ''}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default PlanPositionsTabs
