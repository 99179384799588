import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { PlanPosition } from './types'
import { useAuth } from '../../../hooks/auth'

import './style.css'

import ApprovalsTabs from './components/Tabs/index'
import ApprovalsTableRow from './components/TableRow/index'
import LoadingIcon from '../../../components/LoadingIcon'

const ApprovalsPage: React.FC = () => {
    const navigate = useNavigate()

    const { user } = useAuth()

    const [isLoading, setLoading] = useState<boolean>(true)
    const [items, setItems] = useState<PlanPosition[]>([])

    const loadPlanPositionsOnApproval = () => {
        setLoading(true)

        fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/plan_positions_with_approvals',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(async (response) => {
                if (response.ok) {
                    const data = await response.json()
                    setItems(data)
                    return data.result // Достаём массив из result
                } else {
                    throw new Error('Ошибка запроса на роли')
                }
            })
            .catch((e) => {
                console.error('Ошибка:', e)
                return [] // Возвращаем пустой массив в случае ошибки
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        loadPlanPositionsOnApproval()
    }, [])

    return (
        <div className="h-full flex flex-col bg-[#181818]">
            <div className="bg-[#111111]">
                <div className="w-auto h-24 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-24 my-auto content-center ">
                        <p className="text-xs text-zinc-500">Подразделение</p>
                        <p className="text-xs text-white">Управление закупок</p>
                    </div>
                </div>
                <div className="bg-[#111111]">
                    <ApprovalsTabs count={items.length} />
                </div>
            </div>

            {/* // body */}
            <div className="plan-positions flex p-5 h-[calc(100vh_-_96px_-_28px)]">
                {/* table */}
                <div
                    id="printable-content"
                    className="flex flex-col w-full bg-[#1D1C1E] rounded"
                >
                    {/* toolbar */}
                    <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t"></div>
                    {/* name */}

                    <div className="flex flex-row gap-1 h-24 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                        <div className="grid w-12 place-items-center text-center">
                            №
                        </div>
                        <div className="grid w-12 place-items-center text-center">
                            Номер позиции в ЕИС
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Стадия
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Статус
                        </div>
                        <div className="flex-1 grid place-items-center text-center min-w-44">
                            Предмет договора
                        </div>
                        <div className="grid w-28 place-items-center text-center">
                            Начальная (максимальная) цена договора
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Дата (период) размещения извещения
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Срок исполнения договора
                        </div>
                        <div className="grid w-44 place-items-center text-center">
                            Способ закупки
                        </div>
                        <div className="grid w-24 place-items-center text-center">
                            Статус в плане закупки
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Тип объекта закупки
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Подразделение
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Статья
                        </div>
                        <div className="grid w-20 place-items-center text-center">
                            Редакция
                        </div>
                        <div className="grid w-28 place-items-center text-center">
                            Номер в системе
                        </div>
                        <div className="grid w-20 place-items-center text-center pr-5">
                            Дата последнего изменения
                        </div>
                    </div>

                    {/* data */}
                    <div className="flex flex-col overflow-y-auto gap-1 my-1 h-dvh">
                        {/* Вывод позиций */}
                        <>
                            {isLoading ? (
                                <div className="flex w-full h-full items-center justify-center">
                                    <LoadingIcon />
                                </div>
                            ) : !items.length ? (
                                <div className="flex w-full h-12 items-center justify-center text-[#8A8A8A] text-[11px]">
                                    Нет данных
                                </div>
                            ) : (
                                items.map((item, index) => (
                                    <ApprovalsTableRow
                                        key={index}
                                        keyindex={index + 1}
                                        planPosition={item}
                                        onDoubleClick={(pos_guid) => {
                                            navigate(
                                                `/hub/plans/${item.plan_guid}/positions/approve/${pos_guid}`
                                            )
                                        }}
                                    />
                                ))
                            )}
                        </>
                    </div>

                    {/* buttons */}
                    <div className="flex flex-row gap-3 text-white p-2 px-[22px] bg-[#333033] rounded-b h-10"></div>
                </div>
            </div>
        </div>
    )
}

export default ApprovalsPage
