import React, { useState } from 'react'
import { useNavigate, useLoaderData } from 'react-router-dom'
import { ListFxd } from '../types'

export const loadListFxd = async (): Promise<ListFxd> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/list_expense_items',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const getCurrentDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Добавляем 1, так как месяцы начинаются с 0
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

const OrgListFxdPage: React.FC = () => {
    const navigate = useNavigate()
    const listFxdLoad = useLoaderData() as ListFxd[]
    const [listFxdData, setListFxdData] = useState<ListFxd[]>(listFxdLoad)
    const [listFxdUpdate, setListFxdUpdate] = useState<ListFxd | null>(null)

    const [selectedListFxdGuid, setSelectedListFxdGuid] = useState('')

    const [popupAddListFxd, setPopupAddListFxd] = useState(false)
    const [popupDeleteListFxd, setPopupDeleteListFxd] = useState(false)
    const [isAdding, setIsAdding] = useState(false) // Состояние для анимации загрузки
    const [isDeleting, setIsDeleting] = useState(false) // Состояние для анимации загрузки
    const [isFiltering, setIsFiltering] = useState(false)
    const [filters, setFilters] = useState({
        expenseCode: '',
        expenseName: '',
        expenseApproveDate: '',
    })

    const handlerFilter = () => {
        setIsFiltering(!isFiltering)

        setFilters({
            expenseCode: '',
            expenseName: '',
            expenseApproveDate: '',
        })
    }

    const handlerFilterClear = () => {
        setFilters({
            expenseCode: '',
            expenseName: '',
            expenseApproveDate: '',
        })
    }

    // Функция фильтрации данных
    const getFilteredData = () => {
        return listFxdData.filter((list) => {
            const codeMatches = list.expense_items_code
                .toLowerCase()
                .includes(filters.expenseCode.toLowerCase())
            const nameMatches = list.expense_items_name
                .toLowerCase()
                .includes(filters.expenseName.toLowerCase())
            const dateMatches = filters.expenseApproveDate
                ? list.approve_date === filters.expenseApproveDate
                : true
            return codeMatches && nameMatches && dateMatches
        })
    }

    const handleInputChange = (
        fieldName: keyof ListFxd,
        value: string | null
    ) => {
        value ? value : ''
        setListFxdUpdate(
            (prev) =>
                ({
                    ...prev,
                    [fieldName]: value,
                }) as ListFxd
        )
    }

    const handlePopupAddListFxd = (list_guid: string) => {
        setPopupAddListFxd(!popupAddListFxd)
        if (list_guid != '') {
            setSelectedListFxdGuid(list_guid)
            const selectList = listFxdData.find((l) => l.guid === list_guid)
            setListFxdUpdate(selectList || null)
        } else {
            setSelectedListFxdGuid('')
            setListFxdUpdate(null)
            setListFxdUpdate(
                (prev) =>
                    ({
                        ...prev,
                        ['approve_date']: getCurrentDate(),
                    }) as unknown as ListFxd
            )
            setListFxdUpdate(
                (prev) =>
                    ({
                        ...prev,
                        ['expense_items_code']: '',
                    }) as unknown as ListFxd
            )
            setListFxdUpdate(
                (prev) =>
                    ({
                        ...prev,
                        ['expense_items_name']: '',
                    }) as unknown as ListFxd
            )
        }
    }

    const handleAddListFxd = async () => {
        setIsAdding(true) // Включаем анимацию загрузки
        if (selectedListFxdGuid !== '') {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/list_expense_items/${selectedListFxdGuid}`,
                    {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(listFxdUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при изменении пункта перечня')
                }

                console.log('Пункт перечня успешно изменен')
                setPopupAddListFxd(false)
            } catch (error) {
                console.error('Ошибка при изменении пункта перечня:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
            }
        } else {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/list_expense_items`,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(listFxdUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при добавлении пункта перечня')
                }

                console.log('Пункт перечня успешно добавлен')
                setPopupAddListFxd(false)
            } catch (error) {
                console.error('Ошибка при добавлении пункта перечня:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
            }
        }
        const data = (await loadListFxd()) as unknown as ListFxd[]
        setListFxdData(data)
    }

    const handlePopupDeleteListFxd = (list_guid: string) => {
        setPopupDeleteListFxd(!popupDeleteListFxd)
        setSelectedListFxdGuid(list_guid)
    }

    const handleDeleteListFxd = async () => {
        setIsDeleting(true) // Включаем анимацию загрузки

        if (!selectedListFxdGuid) return

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/list_expense_items/${selectedListFxdGuid}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при удалении пункта перечня')
            }
            setPopupDeleteListFxd(false)
            setListFxdData((prevStruct) =>
                prevStruct.filter((list) => list.guid !== selectedListFxdGuid)
            )
            setSelectedListFxdGuid('')
            console.log('Пункт перечня успешно удален')
        } catch (error) {
            console.error('Ошибка удаления пункта перечня:', error)
        } finally {
            setIsDeleting(false) // Отключаем анимацию загрузки
        }
    }

    return (
        <div className="flex flex-col text-white min-h-screen">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-full h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Коды и статьи финансово-хозяйственной деятельности
                            (ФХД)
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center h-[calc(100vh_-_120px)] p-5">
                <div className="flex flex-col h-full w-[1260px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col h-full w-full">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t-lg items-center">
                                {/* Add */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={() => handlePopupAddListFxd('')}
                                    title="Добавить пункт"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>
                                </button>

                                {/* Filter */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={handlerFilter}
                                    title={
                                        isFiltering
                                            ? 'Убрать фильтр'
                                            : 'Показать фильтр'
                                    }
                                >
                                    {isFiltering ? (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            id="filter-slash"
                                        >
                                            <path
                                                id="svg_1"
                                                d="m19,2l-14,0a3,3 0 0 0 -3,3l0,1.17a3,3 0 0 0 0.25,1.2l0,0.06a2.81,2.81 0 0 0 0.59,0.86l6.16,6.12l0,6.59a1,1 0 0 0 0.47,0.85a1,1 0 0 0 0.53,0.15a1,1 0 0 0 0.45,-0.11l4,-2a1,1 0 0 0 0.55,-0.89l0,-4.59l6.12,-6.12a2.81,2.81 0 0 0 0.59,-0.86l0,-0.06a3,3 0 0 0 0.29,-1.2l0,-1.17a3,3 0 0 0 -3,-3zm-5.71,11.29a1,1 0 0 0 -0.29,0.71l0,4.38l-2,1l0,-5.38a1,1 0 0 0 -0.29,-0.71l-5.3,-5.29l13.18,0l-5.3,5.29zm6.71,-7.29l-16,0l0,-1a1,1 0 0 1 1,-1l14,0a1,1 0 0 1 1,1l0,1z"
                                                fill="#FFFFFF"
                                            />
                                            <path
                                                id="svg_2"
                                                d="m10.35653,-2.20952c0,-0.54338 0.45662,-1 1,-1l0.14286,0c0.54338,0 1,0.45662 1,1l0,25.07412c0,0.54338 -0.45662,1 -1,1l-0.14286,0c-0.54338,0 -1,-0.45662 -1,-1l0,-25.07412z"
                                                transform="rotate(-43.639, 11.428, 10.3275)"
                                                opacity="undefined"
                                                fill="#ff0000"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="filter"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                            ></path>
                                        </svg>
                                    )}
                                </button>
                                <div className="flex flex-1 gap-1 text-[10px] text-white place-items-end text-center "></div>
                                <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center">
                                    Позиций <br />
                                    {`${listFxdData.length}`}
                                </div>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-1 py-2">
                                <div className="flex w-7 justify-center items-center text-center">
                                    №
                                </div>
                                <div className="flex w-28 justify-center items-center text-center">
                                    Статьи
                                </div>
                                <div className="flex flex-1 justify-center items-center text-center">
                                    Наименования финансово-хозяйственной
                                    деятельности (ФХД)
                                </div>
                                <div className="flex w-28 justify-center items-center text-center">
                                    Дата изменения
                                </div>
                                <div className="flex w-10 justify-center items-center text-center"></div>
                            </div>

                            {/* filter */}
                            {isFiltering ? (
                                <div className="flex flex-row gap-1 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1">
                                    <div className="flex w-10 items-center justify-center text-center"></div>
                                    <div className="flex flex-col w-28 justify-center items-center text-center">
                                        <input
                                            className="struct-input-placeholder w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            placeholder="Поиск"
                                            value={filters.expenseCode}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    expenseCode: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-1 justify-center items-center text-center">
                                        <input
                                            className="struct-input-placeholder w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            placeholder="Поиск"
                                            value={filters.expenseName}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    expenseName: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-col w-28 justify-center items-center text-center">
                                        <input
                                            type="date"
                                            className={`w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] ${filters.expenseApproveDate ? 'text-white' : 'text-[#8A8A8A]'}`}
                                            placeholder="дд.мм.гггг"
                                            value={filters.expenseApproveDate}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    expenseApproveDate:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex w-10 items-center justify-center text-center">
                                        <button
                                            className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                            onClick={handlerFilterClear}
                                            title="Очистить фильтр"
                                        >
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                                id="x"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M8 19a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3 1 1 0 0 0 0-2 5 5 0 0 0-5 5v8a5 5 0 0 0 5 5 1 1 0 0 0 0-2Zm7.71-3.29a1 1 0 0 0 0-1.42L13.41 12l2.3-2.29a1 1 0 0 0-1.42-1.42L12 10.59l-2.29-2.3a1 1 0 0 0-1.42 1.42l2.3 2.29-2.3 2.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l2.29-2.3 2.29 2.3a1 1 0 0 0 1.42 0ZM16 3a1 1 0 0 0 0 2 3 3 0 0 1 3 3v8a3 3 0 0 1-3 3 1 1 0 0 0 0 2 5 5 0 0 0 5-5V8a5 5 0 0 0-5-5Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 h-full bg-[#1D1C1E]">
                                {/* Вывод ТРУ */}

                                {getFilteredData().map((list, index) => (
                                    <div
                                        className="flex flex-row cursor-pointer gap-1 mx-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                                        key={index}
                                    >
                                        <div
                                            className="flex flex-row w-full gap-1 py-2"
                                            onClick={() =>
                                                handlePopupAddListFxd(list.guid)
                                            }
                                        >
                                            <div className="flex w-7 items-center justify-center text-center text-[#8A8A8A]">
                                                {index + 1}
                                            </div>
                                            <div className="flex w-28 items-center justify-center px-2">
                                                {list.expense_items_code
                                                    ? list.expense_items_code
                                                    : '-'}
                                            </div>
                                            <div className="flex flex-1 items-center justify-left text-left px-2">
                                                {list.expense_items_name
                                                    ? list.expense_items_name
                                                    : '-'}
                                            </div>
                                            <div className="flex w-28 items-center justify-center px-2 text-[#8A8A8A]">
                                                {list.approve_date
                                                    ?.slice(0, 10)
                                                    .split('-')
                                                    .reverse()
                                                    .join('-')
                                                    .replace(/[\-]/g, '.')}
                                            </div>
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            <button
                                                className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]"
                                                onClick={() =>
                                                    handlePopupDeleteListFxd(
                                                        list.guid
                                                    )
                                                }
                                                title="Удалить"
                                            >
                                                <svg
                                                    className="w-3 h-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    id="trash-alt"
                                                >
                                                    <path
                                                        fill="#FFFFFF"
                                                        d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2">
                                <button
                                    className="button flex flex-row justify-start items-start"
                                    onClick={() => handlePopupAddListFxd('')}
                                    title="Добавить пункт"
                                >
                                    <svg
                                        className="plus-icon flex h-4 w-4 justify-start items-start mr-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>{' '}
                                    Добавить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* buttons */}
            <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end space-x-5 items-center ">
                <div className="flex items-center space-x-5">
                    <button
                        className="flex h-7 bg-[#494849] w-[120px] rounded-lg text-[12.8px] justify-center items-center hover:bg-[#656365]"
                        onClick={() => navigate('/hub/org/lists')}
                    >
                        Закрыть
                    </button>
                </div>
            </div>

            {popupAddListFxd && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => handlePopupAddListFxd('')}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2>
                            {selectedListFxdGuid ? 'Изменить' : 'Добавить'}{' '}
                            статью
                        </h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Статья
                            </label>
                            <input
                                type="text"
                                className="w-[200px] h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                value={listFxdUpdate?.expense_items_code ?? ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        'expense_items_code',
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Наименования статьи
                            </label>
                            <textarea
                                className="w-full h-44 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                value={listFxdUpdate?.expense_items_name ?? ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        'expense_items_name',
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Дата изменения
                            </label>
                            <input
                                type="date"
                                className={`w-[200px] px-2 py-1 rounded-md border-0 text-sm bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] ${listFxdUpdate?.approve_date ? 'text-white' : 'text-[#8A8A8A]'}`}
                                placeholder="дд.мм.гггг"
                                value={listFxdUpdate?.approve_date ?? ''}
                                style={{
                                    colorScheme: 'dark',
                                }}
                                onChange={(e) =>
                                    handleInputChange(
                                        'approve_date',
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-end mt-5 text-right space-x-2">
                            <button
                                className="flex h-7 w-[120px] rounded-full text-xs text-white bg-[#494849] hover:bg-[#656365] items-center justify-center"
                                onClick={() => handlePopupAddListFxd('')}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex h-7 bg-[#187A33] hover:bg-green-600 w-[120px] rounded-full text-[12.8px] justify-center items-center`}
                                onClick={handleAddListFxd}
                            >
                                {isAdding ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : selectedListFxdGuid ? (
                                    'Изменить'
                                ) : (
                                    'Добавить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {popupDeleteListFxd && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[400px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Удаление статьи</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Вы точно хотите удалить статью?
                            </label>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            <button
                                className="px-4 py-1 rounded-full shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                onClick={() => handlePopupDeleteListFxd('')}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#701F1F]`}
                                onClick={handleDeleteListFxd}
                            >
                                {isDeleting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Удалить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrgListFxdPage
